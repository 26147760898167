import './moncss.css';
import React,  {Component} from 'react';
import { Button} from 'react-bootstrap';
import { isBrowser} from "react-device-detect";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Bounce from 'react-reveal/Bounce';
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");


let newWindow = "";

class Abort extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      nom:'',
      prenoms:'',
      mail:'',
      idtype:0,
      typeuser:'',
      iduser : 0,
      tariftype: '',
      montant:0.00,
      lng:'',
      datapaiement:props.match.params.datapaiement,
    };
        
  }
    
    componentDidMount() {

      var bytes  = CryptoJS.AES.decrypt(this.state.datapaiement.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
      var originaldata = bytes.toString(CryptoJS.enc.Utf8);
      this.setState({
        lng:originaldata,
      })  

      {/*
      var userdata  = originaldata.split('-');

      this.setState({
        lng:userdata[0],
        montant:userdata[1],
      })  
      */}

      if(originaldata== 'FR'){

        document.title = "Annulé";
    
      }else{
    
        document.title = "Cancel";
    
      }
 

    };

    componentWillMount() {
      
              
    };

    validation(){

      window.close();

      window.opener = null;
      window.open("http://localhost:3000/Sucess/" + this.state.datapaiement, "_self");
      window.close();
      alert('ato')

    
    }
   
    
  render() {

          return (

              <div style={{paddingTop:'100px'}} className="datacenter">

                <p style={{fontSize:isBrowser? '2rem' : '1.5rem'}}>{this.state.lng ==="FR" ? 'Votre paiement a été annulé.' : 'Your payment has been cancelled.'}</p>
                
                <Bounce>
                <span><HighlightOffIcon style={{color:'#dc3545', fontSize:100}}/></span>
                </Bounce>
                
                
                {/*<Button className="btn" style={{ marginTop:'10px', width:'150px'}} onClick={() => this.validation()} >Ok</Button>*/}
              </div>
             
          );
        }
    
}

export default Abort;

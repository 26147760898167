
import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactPaginate from 'react-paginate';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Card from 'react-bootstrap/Card'
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

var listdata=[];


class Newversion extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        listjargon: [],
        modalupdate: false,
        modaldelete: false,
        idjargon:0,
        wordjargon:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        listjargoninit: [],
        filterdata:'',
        offset: 0,
        listjargonpage: [],
        perPage: 5,
        currentPage: 0,
        loadingdata: false,
      };

  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

    const myDecodedToken = decodeToken(tokenuser);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(tokenuser);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    if(this.state.lng == 'FR'){

      document.title = "Nouvelle version";

    }else{

      document.title = "New version";

    }

  }


showupdateversion(){

  this.setState({
    modalupdate: true
  })

}

updateversion(){

 
  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  fetch(this.state.wbslink + `newversion`,{                  
        
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      if(JSON.stringify(data).replace(/\"/g, "") === 'SUCCESS'){

        this.setState({ errorform: false, errormessage: '',loadershow: false, modalupdate:false  });

      }
      
    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });    

}

    
  render() {

          return (

                <div>

                  {this.state.loadingdata == true?

                    null
                  
                    :

                    <div className="App" style={{marginTop:'20%'}}>
                  
                    {this.state.tokenvalue === '' ?
                              
  
                              <Redirect push to="/Login"/>
                                
  
                              :
  
                              null
  
                      }
                          <div className='d-flex justify-content-center' >
                            <p style={{fontSize:"30px",fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Une nouvelle fonctionnalité a été implémentée sur la plateforme.' : 'A new feature has been implemented on the platform.'}</p>
                          </div>
    
                          <div className='d-flex justify-content-center'>
  
                              {this.state.errorform == true ? 
  
                              <Alert severity="error">
                                <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                                {this.state.errormessage}
                              </Alert>
  
                              :
  
                              null
  
                              }
  
                          </div>
  
                        <div className='d-flex justify-content-center'>
       
                          <button style={{margin:'20px'}}onClick={() => this.showupdateversion()} className="btn btn-success">{this.state.lng ==="FR" ? 'Valider' : 'Validate'}</button> 
                                                               
                        </div> 
  
                    
                    {this.state.modalupdate === true ?
  
                      <Modal
                      
                      show={this.state.modalupdate}
                      onHide={ () => this.setState({modalupdate:false})}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >
                        <Modal.Body>
                          <div className="d-flex flex-column justify-content-center">
    
                              <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Voulez-vous valider la mise à jour?' : 'Do you want to validate the update?'}</p>
                                    
                              <div className='d-flex flex-row justify-content-center'>
                                          
                                    <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                    <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updateversion()} variant="success">{this.state.lng ==="FR" ? 'Valider' : 'Validate'}</Button>
    
                              </div>
    
                          </div>          
                        
                      </Modal.Body>
                      </Modal>
    
                      :
    
                      null
  
                    }

                    </div>

                  }

                </div>
                  
          );
        }
    
}

export default Newversion;

import './moncss.css';
import React,  {Component} from 'react';
import { Button} from 'react-bootstrap';
import { isBrowser} from "react-device-detect";
import { isExpired, decodeToken }from "react-jwt";
import Card from 'react-bootstrap/Card'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Loader from "react-js-loader";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

let newWindow = "";

var errocode = 0;

class Activate extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      nom:'',
      prenoms:'',
      mail:'',
      idtype:0,
      typeuser:'',
      iduser : 0,
      tariftype: '',
      montant:0.00,
      lng:'',
      datatoken:props.match.params.datatoken,
      errorform:false,
      errorformpassword:false,
      errormessage:'',
      motdepassenew:'',
      motdepasserenew:'',
      activateaccount: false,
      loadershow:false,
    };
        
  }
    
    componentDidMount() {

      if(this.state.lng== 'FR'){

        document.title = "Activation";
    
      }else{
    
        document.title = "Activation";
    
      }
 

    };

    componentWillMount() {

      const myDecodedToken = decodeToken(this.state.datatoken);
    
      if(myDecodedToken == null){
        this.setState({ 
          tokenvalue: '',
          connected:false,
          admin: '',
          manager: '',
          user: '',
          lng:'',
        })
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(this.state.datatoken);
  
      if(isMyTokenExpired == true){
        this.setState({ 
          tokenvalue: '',
          connected:false,
          admin: '',
          manager: '',
          user: '',
          lng:'',
        })
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

      const lnguser = JSON.stringify(myDecodedToken.language).replace(/\"/g, "");
      this.setState({
        lng: lnguser,
      })      
              
    };

    validation(){

      const myDecodedToken = decodeToken(this.state.datatoken);
    
      if(myDecodedToken == null){
        this.setState({ 
          tokenvalue: '',
          connected:false,
          admin: '',
          manager: '',
          user: '',
          lng:'',
        })
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(this.state.datatoken);
  
      if(isMyTokenExpired == true){
        this.setState({ 
          tokenvalue: '',
          connected:false,
          admin: '',
          manager: '',
          user: '',
          lng:'',
        })
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

      const lnguser = JSON.stringify(myDecodedToken.language).replace(/\"/g, "");
      this.setState({
        lng: lnguser,
      })


    if (this.state.motdepassenew == "") {

      if(lnguser === "FR"){
        this.setState({ errorformpassword: true, errormessage: 'Mot de passe requis. Veuillez saisir votre mot de passe.' });
      }else{
        this.setState({ errorformpassword: true, errormessage: 'Password is null. Please enter a password.' });
      }
            
      return;

    }

    if (this.state.motdepassenew.length >1 && this.state.motdepassenew.length <8) {

      if(lnguser === "FR"){
        this.setState({ errorformpassword: true, errormessage: 'Mot de passe manquant. Veuillez saisir au moins 8 caractères.' });
      }else{
        this.setState({ errorformpassword: true, errormessage: 'Password is invalid. Please enter minimum 8 characters.' });
      }
            
      return;

    }

    if (this.state.motdepassenew != this.state.motdepasserenew) {

      if(this.state.lng === 'FR'){

        this.setState({ errorformpassword: true, errormessage: 'Veuillez saisir le même mot de passe.' });
        return
      
      }
    
      if(this.state.lng === 'EN'){
    
        this.setState({ errorformpassword: true, errormessage: 'Please write same password.' });
        return
      
      }

    }

    this.setState({ loadershow: true, textloading:'Activation'});

      // Create an object of formData 
      const formData = new FormData(); 

      formData.append("token",this.state.datatoken);
      formData.append("newpassword",this.state.motdepassenew);

      fetch(`https://api.unsaidapps.com/activeuser`,{                  
            
        mode: 'cors',     
        method:'post',
        body: formData

        })
  
        .then(res => {
  
          errocode = res.status;
    
          if (!res.ok) {
              
              return res.json().then(json => { throw json; });
    
          }
    
          return res.json();
    
        })
        .then(data => {
          
          this.setState({
            lng: JSON.stringify(data.langue).replace(/"/g, ""),
          })

          this.setState({
            activateaccount: true
          })
  
          {/*
          if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){
            document.location.href = "/Buycredit";
          }
          */}
  
      })
  
      .catch(err => {
        
        this.setState({ loadershow: false, textloading:''});

        if(errocode == 401 || errocode == 0){
          
          sessionStorage.removeItem("tokenunsaid");

          document.location.href = "/login";
  
          return
  
        }
  
        alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))
  
        this.setState({
          loadershow: false
        })
  
      });  
    
    }

  loginuser(){

    document.location.href = "/login";
  
    return;

  }
   
    
  render() {

          return (

              <div style={{paddingTop:'100px'}} className="datacenter">

                    {this.state.activateaccount === false?

                      <div>

                        <div className="col-sm">
                        
                        <div className="d-flex justify-content-center">
                          <Card style={{ width: '100%', marginTop:"30px", marginBottom:"50px" }}>
                            
                            <Card.Body>
                              <Card.Title style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Veuillez créer votre mot de passe' : 'Please create your password'}</Card.Title>
                              <Card.Text style={{marginTop:"30px"}}>
  
                              <label style={{marginLeft:isBrowser? '10px':'0px'}}>{this.state.lng ==="FR" ? 'Nouveau mot de passe' : 'New password'}</label>
  
                              <div className='d-flex flex-row'>
                            
                                <input type={this.state.showpassword == "text" ? "text" : "password"} className="form-control" value={this.state.motdepassenew} onChange={(e) => this.setState({ motdepassenew: e.target.value})} /> 
    
                                {this.state.showpassword == "password" ? <span onClick={() => this.setState({ showpassword: "text"})} style={{marginLeft:"10px"}}size={25}><VisibilityIcon/></span> : <span onClick={() => this.setState({ showpassword: "password"})} size={25}><VisibilityOffIcon/></span>}
                              
                              </div>
  
                              <label style={{marginLeft:isBrowser?'10px':'0px',marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Resaisir le nouveau mot de passe' : 'Re-enter New password'}</label>
  
                              <div className='d-flex flex-row'>
                            
                                <input type={this.state.showpassword == "text" ? "text" : "password"} className="form-control" value={this.state.motdepasserenew} onChange={(e) => this.setState({ motdepasserenew: e.target.value})} /> 
                                  
                                {this.state.showpassword == "password" ? <span onClick={() => this.setState({ showpassword: "text"})} style={{marginLeft:"10px"}}size={25}><VisibilityIcon/></span> : <span onClick={() => this.setState({ showpassword: "password"})} size={25}><VisibilityOffIcon/></span>}
  
                              </div>
  
                              {this.state.errorformpassword == true ? 
  
                                <Alert severity="error">
                                  <AlertTitle>Erreur</AlertTitle>
                                  {this.state.errormessage}
                                </Alert>
  
                                :
  
                                null
  
                              }
  
                              </Card.Text>

                                {this.state.loadershow === true ?

                                  <Loader type="spinner-cub" bgColor={'#0275d8'} title={this.state.textloading} color={'#0275d8'} size={100} />
                                    
                                  :

                                  null

                                }

                                <div className="d-flex justify-content-center">
                                  <Button className="btn" style={{ marginTop:'10px', width:'150px'}} onClick={() => this.validation()} >{this.state.lng ==="FR" ? 'Activer le compte' : 'Activate my account'}</Button>
                                </div>
  
                            </Card.Body>
                          </Card>
                          </div>

                        </div>


                      </div>

                      :

                      <div>

                        <p style={{fontSize:isBrowser? '2rem' : '1.5rem'}}>{this.state.lng ==="FR" ? 'Votre compte a été activé avec succès.' : 'Your account has been successfully activated.'}</p>
                        {/*<p style={{fontSize:isBrowser? '2rem' : '1.5rem'}}>{this.state.lng ==="FR" ? 'Vous allez recevoir un mail contenant votre identifiant.' : 'You will receive an email containing your identifier.'} </p>*/}
                        <div className="success-checkmark">
                          <div className="check-icon">
                            <span className="icon-line line-tip"></span>
                            <span className="icon-line line-long"></span>
                            <div className="icon-circle"></div>
                            <div className="icon-fix"></div>
                          </div>
                        </div>
                        <Button className="btn" style={{ marginTop:'10px', width:'150px'}} onClick={() => this.loginuser()} >Ok</Button>


                      </div>
                    
                    }

                      
                {/*
                <p style={{fontSize:isBrowser? '2rem' : '1.5rem'}}>{this.state.lng ==="FR" ? 'Votre compte a été activé avec succès.' : 'Your account has been successfully activated.'}</p>
                <p style={{fontSize:isBrowser? '2rem' : '1.5rem'}}>{this.state.lng ==="FR" ? 'Vous allez recevoir un mail contenant votre identifiant.' : 'You will receive an email containing your identifier.'} </p>
                <div className="success-checkmark">
                  <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                  </div>
                </div>
                <Button className="btn" style={{ marginTop:'10px', width:'150px'}} onClick={() => this.validation()} >Ok</Button>
                */}
              </div>
             
          );
        }
    
}

export default Activate;

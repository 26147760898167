import './moncss.css';
import React,  {Component} from 'react';
import { Nav,Navbar,Button,Form,Container,Row,Col} from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import Card from 'react-bootstrap/Card'
import logo from './logo.png';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import HouseIcon from '@material-ui/icons/House';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple, red } from '@material-ui/core/colors';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './Login';
import { LeakRemoveTwoTone } from '@material-ui/icons';

const typeuser = [
  {
    label: "",
    value: "0",
  },
  {
    label: "Administrateur",
    value: "1",
  },
  {
    label: "Manager",
    value: "2",
  },
  {
    label: "Utilisateur",
    value: "3",
  },

];

const societedata = [
  {
    label: "",
    value: "0",
  },
  {
    label: "UNSAID",
    value: "1",
  },
  {
    label: "ADENNES",
    value: "2",
  },
];

class Inscription extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
  
      selectedFile: null,
      dureesound:'',
      selectedOption: null,
      listsound: [],
      persons: [],
      speakers: [],
      listsociete: [],
      listcentre: [],
      listtypeuser: [],
      speakers: [],
      selectedValue: "",
      diarizer: [],
      usertype:0,
      usertypecenter:0,
      nom: '',
      prenoms: '',
      mail: '',
      motdepasse: '',
      societe: 0,
      societeuser:0,
      center: 0,
      centeruser:0,
      telephone: '',
      poste: '',
      errorform: false,
      errormessage:'',
      signin : false,
      tokenuser:[],
      isModalthematique: false,
      isModalspeaker: false,
      speaker1: '',
      speaker2: '',
      idaudioselect: 0,
      thematique:'',
      show_input:'text',
      wbslink:'',
      lng:'FR',

    };

    this.selectusertype = this.selectusertype.bind(this);
    this.selectsociety = this.selectsociety.bind(this);
    this.selectsocietycenter = this.selectsocietycenter.bind(this);
        
  }
    
  async componentDidMount() {  
    
    let idtype = parseInt(sessionStorage.getItem('typeuser'));

    if(idtype>2){

      const responsetypeuser = await fetch(this.state.wbslink + 'listtypeusersociete.php');
      const datatypeuser = await responsetypeuser.json();
      this.setState({ listtypeuser: datatypeuser})

      let idsc = sessionStorage.getItem('idsociety');

      const response = await fetch(this.state.wbslink + 'societycenter/listcenterbysociety.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idsoc:idsc
        })});

      const datacenter = await response.json();

      if(JSON.stringify(datacenter).replace(/"/g, "") !== '0'){
        this.setState({ listcentre: datacenter})
      }

      const responsesocietecentre = await fetch(this.state.wbslink + 'listsocietycenter.php');
      const datasocietecentre = await responsesocietecentre.json();
      this.setState({ listcentre: datasocietecentre})
  
      }else{
        
        const responsetypeuser = await fetch(this.state.wbslink + 'listtypeuser.php');
        const datatypeuser = await responsetypeuser.json();
        this.setState({ listtypeuser: datatypeuser})

        const responsesociete = await fetch(this.state.wbslink + 'listsociete.php');
        const datasociete = await responsesociete.json();
        this.setState({ listsociete: datasociete})

        const responsesocietecentre = await fetch(this.state.wbslink + 'societycenter/listsocietycenter.php');
        const datasocietecentre = await responsesocietecentre.json();
        this.setState({ listcentre: datasocietecentre})

      }

    let lnguser = sessionStorage.getItem('langueuser');

    if(lnguser === "FR"){
      document.title = "Inscription";
    }else{
      document.title = "Sign up";
    }

}

componentWillMount() {

  let wbslinkload = sessionStorage.getItem('wbs');

  let lnguser = sessionStorage.getItem('langueuser');

  let idtype = parseInt(sessionStorage.getItem('typeuser'));

  let idsoc = sessionStorage.getItem('idsociety');

  this.setState({
    wbslink:wbslinkload,
    lng:lnguser,
    usertype: idtype,
    societe:idsoc,
  })

}

    inscription () {

      let idtype = parseInt(sessionStorage.getItem('typeuser'));

      let idsocusercenter = 0;

      let lnguser = sessionStorage.getItem('langueuser');

      if (parseInt(this.state.usertypecenter)  == 0) {

        if(lnguser === "FR"){
          this.setState({ errorform: true, errormessage: 'Type d\'utilisateur vide. Veuillez seléctionner un type d\'utilisateur.' });
        }else{
          this.setState({ errorform: true, errormessage: 'User type is null. Please select one.' });
        }

        return;
        
    }

    if (parseInt(this.state.centeruser)  == 0 && idtype > 2) {

      if(lnguser === "FR"){
        this.setState({ errorform: true, errormessage: 'Site vide. Veuillez seléctionner le site de l\'utilisateur.' });
      }else{
        this.setState({ errorform: true, errormessage: 'Site is null. Please select one.' });
      }

        return;
        
    }

    if (idtype > 2) {

      let idsoc = sessionStorage.getItem('idsociety');

      idsocusercenter = idsoc;
      
    }

    if (idtype == 2) {

      

      if (parseInt(this.state.societe)  == 0 && idtype == 2) {

        if(lnguser === "FR"){
          this.setState({ errorform: true, errormessage: 'Société vide. Veuillez seléctionner la société correspondant.' });
        }else{
          this.setState({ errorform: true, errormessage: 'Society is null. Please select one.' });
        }
  
        return;
          
      }

      idsocusercenter = this.state.societe;
      
    }


        if (this.state.mail == "" ) {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.mail)) {

              if(lnguser === "FR"){
                this.setState({ errorform: true, errormessage: 'Email invalide. Veuillez saisir une adresse e-mail valide s\'il vous plait.' });
              }else{
                this.setState({ errorform: true, errormessage: 'Email invalide. Please enter a valid mail.' });
              }
                
              return;

            }
            
        }
        
        if (this.state.motdepasse == "") {

          if(lnguser === "FR"){
            this.setState({ errorform: true, errormessage: 'Mot de passe requis. Veuillez saisir votre mot de passe.' });
          }else{
            this.setState({ errorform: true, errormessage: 'Password is null. Please enter a password.' });
          }
                
          return;

        }

        if (this.state.motdepasse.length >1 && this.state.motdepasse.length <8) {

          if(lnguser === "FR"){
            this.setState({ errorform: true, errormessage: 'Mot de passe manquant. Veuillez saisir au moins 8 caractères.' });
          }else{
            this.setState({ errorform: true, errormessage: 'Password is invalid. Please enter minimum 8 characters.' });
          }
                
          return;

        }

        if (this.state.nom == "") {

          if(lnguser === "FR"){
            this.setState({ errorform: true, errormessage: 'Nom manquant. Veuillez saisir le noùm de l\'utilisateur.' });
          }else{
            this.setState({ errorform: true, errormessage: 'Name user is missing. Please enter name of user.' });
          }
                   
          return;

        }

          fetch(this.state.wbslink + `inscription.php`,{                  
      
                    method:'post',
                    headers: {'Content-Type':'application/x-www-form-urlencoded'},
                    body: queryString.stringify({
                      typeutilisateur : this.state.usertypecenter,
                      nomutilisateur: this.state.nom,
                      prenomsutilisateur: this.state.prenoms,
                      mailutilisateur:this.state.mail,
                      motdepasseutilisateur:this.state.motdepasse,
                      idsocieteutilisateur:idsocusercenter,
                      telephoneutilisateur:this.state.telephone,
                      posteutilisateur:this.state.poste,
                      idcentre:this.state.centeruser
                    })
      
            })
      
            .then(response => response.json())
            .then(data => {

              if(JSON.stringify(data).replace(/\"/g, "") === 'Mailerror'){
                this.setState({ errorform: true, errormessage: 'L\'adresse mail a été déjà utilisé, veuillez fournir un nouveau adresse mail.' });

                return;
              }

              if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){
                this.setState({ errorform: true, errormessage: 'Maintenance du site en cours, veuillez réessayer plus tard.' });
                return;
              }

              this.setState({
                tokenuser: data,
                errorform: false,
                errormessage:'',
                usertype:0,
                nom: '',
                prenoms: '',
                mail: '',
                motdepasse: '',
                societe: 0,
                telephone: '',
                poste: '',
                signin : true,
                centeruser:0,
                societeuser:0,
              });              
      
            })
            .catch(error => {
                  this.setState({ tokenuser: error});
            });  
  
  }; 

  selectusertype(e) {
    this.setState({ usertypecenter: e.target.value });
  }

  selectsociety(e) {
    this.setState({ societe: e.target.value });
  }

  selectsocietycenter(e) {
    this.setState({ centeruser: e.target.value });
  }
   
  render() {

          return (
              
                <div style={{marginTop:'90px'}}>
                    <p style={{textAlign:'center', color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em'}}>{this.state.lng ==="FR" ? 'Créer un utilisateur' : 'Create user'}</p>
                  <div className="d-flex flex-column" style={{marginTop:'-20px'}}>
                
                  <Container>
                    <Row>
                      <Col>
                      <div className="form-group" style={{margin:'5px'}}>
                          <label htmlFor="name">{this.state.lng ==="FR" ? 'Vous êtes : *' : 'You are : *'}</label>
                          <div>
                          <div>
                            <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.usertypecenter} onChange={this.selectusertype}>
                              {this.state.listtypeuser.map((typ) => (
                                <option value={typ.idtypeutilisateur}>{typ.nomtypeutilisateur}</option>
                              ))}
                            </Form.Select>
                          </div>
                          </div>

                          
                        </div>

                        

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Nom' : 'Last name'}</label>
                          <input type="text" className="form-control" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value})} />
                        </div>

                       

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Prénom(s)' : 'First name'}</label>
                          <input type="text" className="form-control" value={this.state.prenoms} onChange={(e) => this.setState({ prenoms: e.target.value})} />
                        </div>

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>Email *</label>
                          <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                        </div>

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Mot de passe *' : 'Password *'}</label>
                          <input type="password" className="form-control" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                        </div>

                        <div className="form-group" style={{margin:'5px'}}>
                          <label >{this.state.lng ==="FR" ? 'Sites' : 'Sites'}</label>
                          <div>
                            <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.centeruser} onChange={this.selectsocietycenter}>
                              {this.state.listcentre.map((soc) => (
                                <option value={soc.idsocietycenter}>{soc.name}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                        
                        {parseInt(this.state.usertype) === 2 ?

                          <div className="form-group" style={{margin:'5px'}}>
                          <label >{this.state.lng ==="FR" ? 'Société' : 'Society'}</label>
                          <div>
                            <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.societe} onChange={this.selectsociety}>
                              {this.state.listsociete.map((soc) => (
                                <option value={soc.idsociete}>{soc.nomsociete}</option>
                              ))}
                            </Form.Select>
                          </div>
                          </div>
                      
                          :

                          null

                        }
                        

                        {/*<div className="form-group" style={{margin:'5px'}}>
                          <label>Société</label>
                          <input type="email" className="form-control" value={this.state.societe} onChange={(e) => this.setState({ societe: e.target.value})} />
                          </div>*/}
                        

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Téléphone' : 'Phone number'}</label>
                          <input type="text" className="form-control" value={this.state.telephone} onChange={(e) => this.setState({ telephone: e.target.value})} />
                        </div>
                        

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Poste' : 'Job'}</label>
                          <input type="email" className="form-control" value={this.state.poste} onChange={(e) => this.setState({ poste: e.target.value})} />
                        </div>

                        {this.state.errorform == true ? 
                        
                          <Alert severity="error">
                            <AlertTitle>Erreur</AlertTitle>
                            {this.state.errormessage}
                          </Alert>

                          

                          :

                          null

                        }

                        {this.state.signin == true ?
                            
                        
                            <div >
                              <a className="d-flex justify-content-center" style={{marginTop:"15px", color:"#2A83E8", textDecoration:"none", textAlign:"center"}}>{this.state.lng ==="FR" ? 'L\'utilisateur a été créé.' : 'The user has been created.'}</a>
                            </div>
                            

                          :

                          null

                        }
                       
                        <div className="d-flex justify-content-center">
                          <Button className="btn" style={{ marginTop:'10px', width:'150px'}} onClick={() => this.inscription()} >{this.state.lng ==="FR" ? 'Inscription' : 'Sign up'}</Button>
                        </div>
                      </Col>
                      <Col>
                        <BrowserView>
                          <img src={process.env.PUBLIC_URL + './images/profil.png'} style={{width:"90%", height:"90%", marginTop:0}}/>
                        </BrowserView>
                      </Col>
                    </Row>
                  </Container>

                  </div>

                </div>

          );

        }
    
}

export default Inscription;

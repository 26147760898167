import React,  {Alert,Component} from 'react';
import {useEffect,useRef} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ForumIcon from '@mui/icons-material/Forum';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BarChartIcon from '@mui/icons-material/BarChart';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple,white, red, blue } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CancelIcon from '@mui/icons-material/Cancel';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import './moncss.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col,Container} from 'react-bootstrap';
import ReactAudioPlayer from 'react-audio-player';
import {  BarChart, Bar,LineChart, Line,Label, LabelList, PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from "recharts";
import ReactToPrint from 'react-to-print';
import Loader from "react-js-loader";
import logo from './logotransparent.png';
import DatePicker, { registerLocale, setDefaultLocale,getDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import ReactPaginate from 'react-paginate';
import Card from 'react-bootstrap/Card'
import { BrowserView, MobileView, isBrowser, isMobile,isIE, isSafari} from 'react-device-detect';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import parse from 'html-react-parser';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'


var listdata=[];

var dateselectdu = new Date();
var dateselectau = new Date();

const data = [
  { name: 'Positive', value: 644 },
  { name: 'Neutral', value: 254 },
  { name: 'Negative', value: 103 },
];

const dataville = [
  { name: 'Paris', positif: 23, negatif:37, neutre:40 },
  { name: 'Casablanca', positif: 27, negatif:53, neutre:20 },
  { name: 'Bordeaux', positif: 53, negatif:37, neutre:10 },
  { name: 'Lille', positif: 70, negatif:30, neutre:0 },
];

const COLORS = ['#198754', '#333333', '#dc3545'];

const COLORSCHART = ['#00934c', '#e89900', '#e40001'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

class Globalsentimentville extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        selectedFile: null,
        soundfilename:null,
        dureesound:'',
        selectedOption: null,
        listsound: [],
        listlng: [],
        persons: [],
        speakers: [],
        speaker1: [],
        speaker1sentiment: [],
        speaker2: [],
        speaker2sentiment: [],
        speakernegatif: 0.00,
        speakerneutre: 0.00,
        speakerpositif: 0.00,
        sentimentglobal:[],
        nombrepositif :0,
        nombreneutre :0,
        nombrenegatif :0,
        selectedValue: "",
        diarizer: [],
        nom: '',
        prenoms: '',
        mail: '',
        tokenuser:[],
        isModalthematique: false,
        isModalspeaker: false,
        isModalupdate: false,
        isModaladd: false,
        idaudioselect: 0,
        thematique:'',
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        username: '',
        soundlink:'',
        soundname:'',
        soundlng:'',
        speaker1name:'',
        speaker2name:'',
        loadershow: true,
        textloading:'',
        lng: '',
        isModaldetails: false,
        datedu : '',
        dateau: '',
        wbslink:'',
        listsoundinit: [],
        filterdata:'',
        offset: 0,
        listsoundpage: [],
        perPage: 5,
        currentPage: 0,

      };

      this.selectlng = this.selectlng.bind(this);
      this.handlePageClick = this.handlePageClick.bind(this);
        
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listsoundpage)
    });

};

  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listsound:slice
  })

  window.scrollTo(0, 0)

  }

  loadpage(slice){
    this.setState({
      listsound:slice
      
    })
    
  }

  filterlist(e){

  
    this.setState({filterdata: e})
  
    if(e.lenght == 0){
      this.setState({listsound: this.state.listsoundinit})
      return;
    }
  
    const filteredItems = this.state.listsoundinit.filter((soundlist) => {
      return (soundlist.nomaudio).toLowerCase().includes(e.toLowerCase()) || (soundlist.comment).toLowerCase().includes(e.toLowerCase()) || (soundlist.langue).toLowerCase().includes(e.toLowerCase()) || (soundlist.speaker1).toLowerCase().includes(e.toLowerCase()) || (soundlist.speaker2).toLowerCase().includes(e.toLowerCase()) || (soundlist.summarization).toLowerCase().includes(e.toLowerCase()) || (soundlist.sentimentglobale).toLowerCase().includes(e.toLowerCase()) || (soundlist.texttranscript).toLowerCase().includes(e.toLowerCase());
    });
  
    
    if(filteredItems.length>0){
  
      this.setState({listsound: filteredItems})
  
      var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            this.setState({
                pageCount: Math.ceil(filteredItems.length / this.state.perPage),
                listsound:slice,
                listsoundpage:filteredItems,
            })
  
    }else{
  
      this.setState({listsound: [], listsoundpage:[]})
  
    }
   
    
  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    var datevalue = new Date();
    dateselectdu = new Date(datevalue);

    var moisdu = '';

    if(parseInt(datevalue.getMonth())<9){
      moisdu = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
    }else{
      moisdu = (parseInt(datevalue.getMonth()) + 1)
    }

    var jourdu = '';
    if(parseInt(datevalue.getDate())<9){
      jourdu = '0' + (parseInt(datevalue.getDate()))
    }else{
      jourdu = (parseInt(datevalue.getDate()))
    }
    var datedu = datevalue.getFullYear()  + '-' + moisdu + '-' + jourdu ;
    
    this.setState({ datedu: datedu})

    dateselectau = new Date(datevalue);

    var moisau = '';

    if(parseInt(datevalue.getMonth())<9){
      moisau = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
    }else{
      moisau = (parseInt(datevalue.getMonth()) + 1)
    }

    var jourau = '';
    if(parseInt(datevalue.getDate())<9){
      jourau = '0' + (parseInt(datevalue.getDate()))
    }else{
      jourau = (parseInt(datevalue.getDate()))
    }
    var dateau = datevalue.getFullYear()  + '-' + moisau + '-' + jourau ;
    
    this.setState({ dateau: dateau})

    let nomusercache = sessionStorage.getItem('fullnameuser');
    let tokenuser = sessionStorage.getItem('tokenunsaid');
    let lnguser = sessionStorage.getItem('langueuser');

    if(lnguser === "FR"){

      registerLocale('fr', fr);

      this.setState({
        textloading:'Chargement en cours ...',
      
      });
    }else{

      registerLocale('en', en);

      this.setState({

        textloading:'Loading data ...',
      
      });
    }

    if(tokenuser === '' || tokenuser === 'Undefined'){
      this.setState({ tokenexist: false})
      return
    }else{

      this.setState({ tokenvalue: tokenuser,username: nomusercache, lng:lnguser})

      this.reloaddata();
      
    }

    

  }

  reloaddata () {

    if(this.state.lng === "FR"){
      document.title = "Sentiment global par centre";
    }else{
      document.title = "Global sentiment per center";
    }

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
  
  async componentDidMount() { 

    let idsc = parseInt(sessionStorage.getItem('idsociety'));
    let idtype = parseInt(sessionStorage.getItem('typeuser'));
    let iduserselect = parseInt(sessionStorage.getItem('iduser'));
    let lnguser = sessionStorage.getItem('langueuser');
    
      if(idsc>1 && idtype==3){

        const response = await fetch(this.state.wbslink + 'audio/listaudioid.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
          this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              listsoundinit :data,
              listsound:slice
          })

          
          this.refreshsentiment(data)

        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc>1 && idtype==4){

        const response = await fetch(this.state.wbslink + 'audio/listaudioid.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();
          
        if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
          
          this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              listsoundinit :data,
              listsound:slice
          })

          this.refreshsentiment(data)
        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc==1 && idtype==4){

        const response = await fetch(this.state.wbslink + 'audio/listaudioiduser.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idusers:iduserselect
          })});

        const data = await response.json();

        if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
          
          this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              listsoundinit :data,
              listsound:slice
          })

          this.refreshsentiment(data)
        }else{
          this.setState({ loadershow: false})
        }

      }
      
      if(idtype==2){

        const response = await fetch(this.state.wbslink + 'audio/listaudio.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
          
          this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              listsoundinit :data,
              listsound:slice
          })

          this.refreshsentiment(data)
        }else{
          this.setState({ loadershow: false})
        }
      
      }
    
    if(lnguser === "FR"){
      const responselng = await fetch(this.state.wbslink + 'listlanguefr.php');
      const datatypelng = await responselng.json();
      this.setState({ listlng: datatypelng, loadershow: false})
      document.title = "Sentiment global par center";
    }else{
      const responselng = await fetch(this.state.wbslink + 'listlangueen.php');
      const datatypelng = await responselng.json();
      this.setState({ listlng: datatypelng, loadershow: false})
      document.title = "Global sentiment per center";
    }
     
    this.loaderdesactive();
    //alert(this.state.speaker2sentiment.length)

    //this.sentimentspeaker(dataspeaker);

}

async refreshlistsound() { 

    let idsc = parseInt(sessionStorage.getItem('idsociety'));
    let idtype = parseInt(sessionStorage.getItem('typeuser'));
    let iduserselect = parseInt(sessionStorage.getItem('iduser'));
    
    if(idsc>1 && idtype==3){

      const response = await fetch(this.state.wbslink + 'audio/listaudioid.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idsoc:idsc
        })});

      const data = await response.json();

      if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
        
        listdata.length = 0;
        this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

        data.forEach((item)=>{
          listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
        })
      
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listsoundinit :data,
            listsound:slice
        })

        this.refreshsentiment(data)
      }else{
        this.setState({ loadershow: false})
      }

    }

    if(idsc>1 && idtype==4){

      const response = await fetch(this.state.wbslink + 'audio/listaudioid.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idsoc:idsc
        })});

      const data = await response.json();

      if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
        
        listdata.length = 0;
        this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

        data.forEach((item)=>{
          listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
        })
      
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listsoundinit :data,
            listsound:slice
        })

        this.refreshsentiment(data)
      }else{
        this.setState({ loadershow: false})
      }

    }

    if(idsc==1 && idtype==4){

      const response = await fetch(this.state.wbslink + 'audio/listaudioiduser.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idusers:iduserselect
        })});

      const data = await response.json();

      if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
       
        listdata.length = 0;
        this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

        data.forEach((item)=>{
          listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
        })
      
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listsoundinit :data,
            listsound:slice
        })

        this.refreshsentiment(data)
      }else{
        this.setState({ loadershow: false})
      }

    }
    
    if(idtype==2){

      const response = await fetch(this.state.wbslink + 'audio/listaudio.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idsoc:idsc
        })});

      const data = await response.json();

      if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
        
        listdata.length = 0;
        this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

        data.forEach((item)=>{
          listdata.push({ idaudio:item.idaudio,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
        })
      
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listsoundinit :data,
            listsound:slice
        })

        this.refreshsentiment(data)
      }else{
        this.setState({ loadershow: false})
      }
     
    }

    if(this.state.lng === "FR"){
      document.title = "Sentiment global par center";
      this.setState({ loadershow: false})
    }else{
      document.title = "Global sentiment per center";
      this.setState({ loadershow: false})
    }



    this.loaderdesactive();

}

    handleChange = selectedOption => {
      this.setState({ selectedOption });
      //alert(`Option selected:`, selectedOption);
    };

    onFileChange = event => { 

      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
      if(event.target.files[0] !== null){
        this.setState({
          loadershow: false,
          soundfilename : event.target.files[0].name,
        });
      }

    }; 
     
    // On file upload (click the upload button) 
    onFileUpload = () => { 

      if(this.state.selectedFile === null  && this.state.lng === "FR"){

          this.setState({
            loadershow: true,
            textloading:'Veuillez séléctionner un fichier audio.'
          });
        
        return;
      
      }

      if(this.state.selectedFile === null && this.state.lng !== "FR"){

        this.setState({
          loadershow: true,
          textloading:'Please select an audio file.'
        });
      
      return;
    
    }

      if(this.state.soundlng ==='' && this.state.lng === "FR"){

          this.setState({
            loadershow: true,
            textloading:'Veuillez séléctionner la language de l\'audio.'
          });
        
        return;

      }

      if(this.state.soundlng ==='' && this.state.lng !== "FR"){

          this.setState({
            loadershow: true,
            textloading:'Please select audio language.'
          });
        
        return;

      }

      if(this.state.lng === "FR"){
        this.setState({
          loadershow: true,
          textloading:'Chargement du fichier en cours ...'
        });
      }else{
        this.setState({
          loadershow: true,
          textloading:'Loading data file ...'
        });
      }

      let iduserupload = sessionStorage.getItem('iduser');
      let idusersociety = sessionStorage.getItem('idsociety');

      if(idusersociety === '' || idusersociety === 'undefined'){
        idusersociety = 0;
      }

      // Create an object of formData 
      const formData = new FormData(); 
     
      // Update the formData object 
      formData.append( 
        "sound", 
        this.state.selectedFile, 
        this.state.selectedFile.name,
      ); 

      formData.append("iduser",iduserupload);
      formData.append("idsoc",idusersociety);
      formData.append("lngsound",this.state.soundlng);
     
      // Details of the uploaded file 
     // console.log(this.state.selectedFile); 

     
      // Request made to the backend api 
      // Send formData object 
      axios.post(this.state.wbslink + "file.php", formData)
      .then(res => {
    
        //alert(JSON.stringify(res.data));
        this.setState({ listsound : res.data});

        this.loaderdesactive();

    })

  }

  loaderdesactive(){

    this.setState({ loadershow: false, selectedFile:'', soundlng:'', isModaladd:false});

    if(this.state.lng === "FR"){
      document.title = "Sentiment global par center";
    }else{
      document.title = "Global sentiment per center";
    }
    
  }

  async refreshlist() {
    
      let idsc = parseInt(sessionStorage.getItem('idsociety'));
      let idtype = parseInt(sessionStorage.getItem('typeuser'));
      let iduserselect = parseInt(sessionStorage.getItem('iduser'));
      
      if(idsc>1 && idtype==3){

        const response = await fetch(this.state.wbslink + 'audio/listaudioid.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(data.length>0){
          this.setState({ listsound: data, loadershow: false})
          this.refreshsentiment(data)
        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc>1 && idtype==4){

        const response = await fetch(this.state.wbslink + 'audio/listaudioid.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(data.length>0){
          this.setState({ listsound: data, loadershow: false})
          this.refreshsentiment(data)
        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc==1 && idtype==4){

        const response = await fetch(this.state.wbslink + 'audio/listaudioiduser.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idusers:iduserselect
          })});

        const data = await response.json();

        if(data.length>0){
          this.setState({ listsound: data, loadershow: false})
          this.refreshsentiment(data)
        }else{
          this.setState({ loadershow: false})
        }

      }
      
      if(idtype==2){

        const response = await fetch(this.state.wbslink + 'audio/listaudio.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(data.length>0){
          this.setState({ listsound: data, loadershow: false})
          this.refreshsentiment(data)
        }else{
          this.setState({ loadershow: false})
        }
      
      }
      
    }; 

    fileData = () => { 
      
      if (this.state.selectedFile) { 

    var file = this.state.selectedFile;
    
    var reader = new FileReader();

    reader.onload = function (event) {

        var audioContext = new (window.AudioContext || window.webkitAudioContext)();

        audioContext.decodeAudioData(event.target.result, function(buffer) {
            var duration = parseInt(buffer.duration);

            var h = Math.floor(duration / 3600);
            var m = Math.floor(duration % 3600 / 60);
            var s = Math.floor(duration % 3600 % 60);
        
            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            var dureetotal = "La durée de l'audio est de " + hDisplay + mDisplay + sDisplay + " secondes.";
            //this.setState({ dureesound: dureetotal });
            
            //alert(dureetotal);

        });
    };

    reader.onerror = function (event) {
        console.error("An error ocurred reading the file: ", event);
    };

    reader.readAsArrayBuffer(file);
          
        return ( 
          <div> 
            <p>Nom du fichier: {this.state.selectedFile.name}</p> 
          </div> 
        ); 
      } else { 
        return ( 
          <div> 
            <br /> 
            <h4>Veuillez choisir un fichier audio.</h4> 
          </div> 
        ); 
      } 
    }; 

    audiosend (nomaudio,nomsound,timesound,lgnsound) {
 
      let minuterestant = sessionStorage.getItem('creditrestant'); 
      let iduserdiarizeur = sessionStorage.getItem('iduser');  
     
      if(parseInt(timesound)>parseInt(minuterestant)){
        if(this.state.lng === "FR"){
          alert('Crédit insuffisant.');
          return;
        }else{
          alert('Credit not enough.');
          return;
        }
        
      }

      if(this.state.lng === "FR"){
        this.setState({

          textloading:'Transcription en cours ...',
        
        });
      }else{
        this.setState({

          textloading:'Loading transcription ...',
        
        });
      }

      this.setState({
        
        loadershow: true,
        soundlink: this.state.wbslink + "unsaidtest/audio/" + nomaudio + ".wav",
        soundname : nomsound,
        idaudioselect : nomaudio,
      
      });

      let idsc = parseInt(sessionStorage.getItem('idsociety'));

      fetch(this.state.wbslink + `diarizer.php`,{                  

                method:'post',
                headers: {'Content-Type':'application/x-www-form-urlencoded'},
                body: queryString.stringify({nomaudio : nomaudio + '.wav', iduserdiari: iduserdiarizeur, dureeaudio : timesound,langueaudio:lgnsound, idsociete:idsc })

        })

        .then(response => response.json())
        .then(data => {

          if(JSON.stringify(data).replace(/\"/g, "") == "OK"){

            let newcredit = minuterestant - timesound;

            sessionStorage.setItem(
              'creditrestant', newcredit 
            );

            //this.loadpseakerafterdiarize(data)
            this.refreshlistsound()

            if(this.state.lng === "FR"){
              document.title = "Sentiment global par center";
            }else{
              document.title = "Global sentiment per center";
            }

          }else{

            this.setState({ diarizer: [], loadershow: false, });


            //alert(JSON.stringify(data));

          }
          //alert(data)
          //const jsonfile = JSON.parse(data)
          //alert(JSON.stringify(jsonfile.recognizedPhrases[0].nbest[3].display))
            //this.setState({ diarizer: data});

        })
        .catch(error => {
              this.setState({ diarizer: [], loadershow: false});
        });   
        
  };

  loadpseakerafterdiarize(data){

    if(data.length>0){

        this.state.speaker1.length = 0;
        this.state.speaker2.length = 0;  

        var Positif1 = 0;
        var Neutre1 = 0;
        var Negatif1 = 0;
        var Speaker1speak = 0;

        var Positif2 = 0;
        var Neutre2 = 0;
        var Negatif2 = 0;
        var Speaker2speak = 0;

        var Positif = 0;
        var Neutre = 0;
        var Negatif = 0;
        var Speaktotal = data.length;

        var SentimentGlobalePositif = 0.00;
        var SentimentGlobaleNegatif = 0.00;
        var SentimentGlobaleNeutre = 0.00;

        data.forEach((item)=>{

          if(item.sentimentspeaker === 'positive'){
            Positif++
          }

          if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
            Neutre++
          }

          if(item.sentimentspeaker === 'negative'){
            Negatif++
          }


          {/*if(parseFloat(item.sentiment)>=0.05){
            SentimentGlobalePositif+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)>-0.05 && parseFloat(item.sentiment)<0.05){
            SentimentGlobaleNeutre+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)<= -0.05){
            SentimentGlobaleNegatif+= parseFloat(item.sentiment)
          }*/}

          if(item.speaker === '1'){

            Speaker1speak++

            if(item.sentimentspeaker === 'positive'){
              Positif1++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker == 'mixed'){
              Neutre1++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif1++
            }

            this.state.speaker1.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }else{

            Speaker2speak++

            if(item.sentimentspeaker === 'positive'){
              Positif2++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre2++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif2++
            }

            this.state.speaker2.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }

        })

      }

      this.state.speaker1sentiment.push({ 
        name: 'Positif', value:  (Positif1/Speaker1speak)*100,
      });
      this.state.speaker1sentiment.push({ 
          name: 'Neutre', value: (Neutre1/Speaker1speak)*100 ,
      });
      this.state.speaker1sentiment.push({ 
        name: 'Négatif', value: (Negatif1/Speaker1speak)*100,
      });

      this.state.speaker2sentiment.push({ 
        name: 'Positif', value:  (Positif2/Speaker2speak)*100,
      });
      this.state.speaker2sentiment.push({ 
          name: 'Neutre', value: (Neutre2/Speaker2speak)*100 ,
      });
      this.state.speaker2sentiment.push({ 
        name: 'Négatif', value: (Negatif2/Speaker2speak)*100,
      });

      SentimentGlobalePositif = ((Positif/Speaktotal)*100);
      SentimentGlobaleNeutre = ((Neutre/Speaktotal)*100);
      SentimentGlobaleNegatif = ((Negatif/Speaktotal)*100);

      this.setState({
        speakers: data,
        speakerpositif: SentimentGlobalePositif.toFixed(2),
        speakerneutre: SentimentGlobaleNeutre.toFixed(2),
        speakernegatif: SentimentGlobaleNegatif.toFixed(2),
        loadershow: false,
      })

      if(this.state.lng === "FR"){
        document.title = "Sentiment global par center";
      }else{
        document.title = "Global sentiment per center";
      }

    var idlastline = 'lastline';
    var element = document.getElementById(idlastline);

    if(element !== 'null'){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }

      this.refreshlistsound();   
       
  }
  
  sentimentvalue(idaudiospeak) {

    if(this.state.lng === "FR"){
      this.setState({

        textloading:'Chargement en cours ...',
      
      });
    }else{
      this.setState({

        textloading:'Loading data ...',
      
      });
    }

    this.setState({ 

      loadershow: true,
      soundlink: this.state.wbslink + "audio/" + idaudiospeak + ".wav",
      idaudioselect : idaudiospeak,
      

    });

    fetch(this.state.wbslink + `speaker.php`,{                  
            
            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({
              idaudio : idaudiospeak
            })

      })

      .then(response => response.json())
      .then(data => {
    
          if(data.length>0){

            this.loadpseaker(data)

          }

      })
      .catch(error => {
          this.setState({ speakers: []});
      });  
      
};   


diarizationeffectue(){

  if(this.state.lng === "FR"){
    alert('Transcription déjà effectué.')
  }else{
    alert('Transcription finish.')
  }  

}

diarizationnoneffectue(){

  if(this.state.lng === "FR"){
    alert('Transcription non effectué.')
  }else{
    alert('Transcription not finish.')
  }  

}

  loadpseaker(data){

    if(data.length>0){

        this.state.speaker1.length = 0;
        this.state.speaker2.length = 0;  

        var Positif1 = 0;
        var Neutre1 = 0;
        var Negatif1 = 0;
        var Speaker1speak = 0;
        
        var idscroll =0;

        var Positif2 = 0;
        var Neutre2 = 0;
        var Negatif2 = 0;
        var Speaker2speak = 0;

        var Positif = 0.00;
        var Neutre = 0.00;
        var Negatif = 0.00;
        var Speaktotal = data.length;

        var SentimentGlobalePositif = 0.00;
        var SentimentGlobaleNegatif = 0.00;
        var SentimentGlobaleNeutre = 0.00;

        data.forEach((item)=>{

          {/*if(parseFloat(item.sentiment)>=0.05){
            SentimentGlobalePositif+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)>-0.05 && parseFloat(item.sentiment)<0.05){
            SentimentGlobaleNeutre+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)<= -0.05){
            SentimentGlobaleNegatif+= parseFloat(item.sentiment)
          }*/}

          if(item.speaker === '1'){

            idscroll = parseInt(item.iddiarization);

            Speaker1speak++

            if(item.sentimentspeaker === 'positive'){
              Positif1++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre1++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif1++
            }

            this.state.speaker1.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }else{

            Speaker2speak++

            if(item.sentimentspeaker === 'positive'){
              Positif2++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre2++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif2++
            }

            this.state.speaker2.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif: parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }

        })

      }

      this.state.speaker1sentiment.push({ 
        name: 'Positif', value:  (Positif1/Speaker1speak)*100,
      });
      this.state.speaker1sentiment.push({ 
          name: 'Neutre', value: (Neutre1/Speaker1speak)*100 ,
      });
      this.state.speaker1sentiment.push({ 
        name: 'Négatif', value: (Negatif1/Speaker1speak)*100,
      });

      this.state.speaker2sentiment.push({ 
        name: 'Positif', value:  (Positif2/Speaker2speak)*100,
      });
      this.state.speaker2sentiment.push({ 
          name: 'Neutre', value: (Neutre2/Speaker2speak)*100 ,
      });
      this.state.speaker2sentiment.push({ 
        name: 'Négatif', value: (Negatif2/Speaker2speak)*100,
      });

      //SentimentGlobalePositif = ((Positif/Speaktotal)*100);
      ///SentimentGlobaleNeutre = ((Neutre/Speaktotal)*100);
      //SentimentGlobaleNegatif = ((Negatif/Speaktotal)*100);
      {/*speakerpositif: SentimentGlobalePositif.toFixed(2),
        speakerneutre: SentimentGlobaleNeutre.toFixed(2),
      speakernegatif: SentimentGlobaleNegatif.toFixed(2),*/}

      this.setState({
        speakers: data,
        loadershow: false,
        isModaldetails : true,
      }) 

      if(this.state.lng === "FR"){
        document.title = "Sentiment global par center";
      }else{
        document.title = "Global sentiment per center";
      }
    
    {/*var idlastline = 'lastline';
    var element = document.getElementById(idlastline);

    if(element !== 'null'){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }*/}
       
  }

  selectligne(idscroll){

    var idline = idscroll;

    var element = document.getElementById(idline);

    if(element !== 'null' && parseInt(idline)>0){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }

    idline = 0;

    element = 'null';
    
  }




  updatemodal(idaudio,thematiqueupdate,speaker1,speaker2,namesound) {
   
    this.setState({ isModalupdate: true,
      soundlink: this.state.wbslink + "audio/" + idaudio + ".wav",
      soundname : namesound,
      idaudioselect : idaudio,
      thematique: thematiqueupdate,
      speaker1: speaker1,
      speaker2: speaker2,
      speakers: []});
    //this.themeatiquesound(idaudio);      
    };   

  themeatiquemodal(idaudio,thematiqueupdate) {
    this.setState({ isModalthematique: true, idaudioselect : idaudio, thematique: thematiqueupdate, speakers: []});
    //this.themeatiquesound(idaudio);      
};   

updatesound () {

  fetch(this.state.wbslink + `audio/updateaudio.php`,{                  

            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({idaudio : this.state.idaudioselect, thematique: this.state.thematique, speaker1: this.state.speaker1, speaker2: this.state.speaker2})

    })
    .then(response => response.json())
    .then(data => {
      //alert(JSON.stringify(data))

        this.setState({ listsound: data, isModalupdate: false, thematique :'', idaudioselect :0 , speaker1: '', speaker2: ''});

        this.refreshlist();

    })
    .catch(error => {

          this.setState({ listsound: [], isModalupdate: false, thematique :'', idaudioselect :0, speaker1: '', speaker2: '' });

    });   
    
};   

  themeatiquesound () {

    fetch(this.state.wbslink + `thematique.php`,{                  

              method:'post',
              headers: {'Content-Type':'application/x-www-form-urlencoded'},
              body: queryString.stringify({idaudio : this.state.idaudioselect, thematique: this.state.thematique})

      })
      .then(response => response.json())
      .then(data => {
        //alert(JSON.stringify(data))
        const jsonfile = JSON.parse(data)
        //alert(JSON.stringify(jsonfile.recognizedPhrases[0].nbest[3].display))
          this.setState({ listsound: data, isModalthematique: false, thematique :'', idaudioselect :0 });

          this.refreshlist();

      })
      .catch(error => {
            this.setState({ listsound: [], isModalthematique: false, thematique :'', idaudioselect :0 });
      });   
      
};   

speakermodal(idaudio,speaker1update,speaker2update) {
  this.setState({ isModalspeaker: true, idaudioselect : idaudio, speaker1: speaker1update, speaker2: speaker2update});
  //this.themeatiquesound(idaudio);      
};   

speakermodalsound () {

  fetch(this.state.wbslink + `speakerupdate.php`,{                  

            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({idaudio : this.state.idaudioselect, speaker1: this.state.speaker1, speaker2: this.state.speaker2})

    })
    .then(response => response.json())
    .then(data => {
      //alert(JSON.stringify(data))
      const jsonfile = JSON.parse(data)
      //alert(JSON.stringify(jsonfile.recognizedPhrases[0].nbest[3].display))
        this.setState({ listsound: data, isModalspeaker: false, thematique :'', idaudioselect :0, speaker1: '', speaker2: '' });

        //this.refreshlist();

    })
    .catch(error => {
          this.setState({ listsound: [], isModalspeaker: false, thematique :'', idaudioselect :0, speaker1: '', speaker2: '' });
    });   
    
};   

  
ecouteraudio (soundid,soundnameshow,positifvalue,neutrevalue,negatifvalue,nomspeaker1,nomspeaker2,thematique) {

  this.setState({
    soundlink: this.state.wbslink + "audio/" + soundid + ".wav",
    soundname : soundnameshow,
    idaudioselect : soundid,
    speaker1: [],
    speaker2: [],
    speaker1name: nomspeaker1,
    speaker2name: nomspeaker2,
    thematique : thematique,
    speaker1sentiment: [],
    speaker2sentiment: [],
    speakerpositif: (positifvalue*100).toFixed(2),
    speakerneutre: (neutrevalue*100).toFixed(2),
    speakernegatif: (negatifvalue*100).toFixed(2),
  });

  this.sentimentvalue(soundid)

};   


scrolltospeech=(payload)=>{

  
  var idline = JSON.stringify(payload.payload.idspeak).replace(/\"/g, "")

  var element = document.getElementById(idline);

  if(element !== 'null' && parseInt(idline)>0){
    element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
  }

  idline = 0;

  element = 'null';

  /*text.slice(0, text.lastIndexOf(':'))*/
  
  
     
};

selectlng(e) {
  this.setState({ soundlng: e.target.value });
}

selectdatedu = (datevalue) =>{

  dateselectdu = new Date(datevalue);

  var moisdu = '';

  if(parseInt(datevalue.getMonth())<9){
    moisdu = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
  }else{
    moisdu = (parseInt(datevalue.getMonth()) + 1)
  }

  var jourdu = '';
  if(parseInt(datevalue.getDate())<9){
    jourdu = '0' + (parseInt(datevalue.getDate()))
  }else{
    jourdu = (parseInt(datevalue.getDate()))
  }
  var datedu = datevalue.getFullYear()  + '-' + moisdu + '-' + jourdu ;
  
    this.setState({ datedu: datedu})

}

selectdateau = (datevalue) =>{

  dateselectau = new Date(datevalue);

  var moisau = '';

  if(parseInt(datevalue.getMonth())<9){
    moisau = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
  }else{
    moisau = (parseInt(datevalue.getMonth()) + 1)
  }

  var jourau = '';
  if(parseInt(datevalue.getDate())<9){
    jourau = '0' + (parseInt(datevalue.getDate()))
  }else{
    jourau = (parseInt(datevalue.getDate()))
  }
  var dateau = datevalue.getFullYear()  + '-' + moisau + '-' + jourau ;
  
    this.setState({ dateau: dateau})

}

async selectlistdate() {

  if (this.state.dateau == "") {

    this.setState({ errorform: true, errormessage: 'Date de début invalide.' });
                      
    return;

  }

  if (this.state.dateau == "") {

    this.setState({ errorform: true, errormessage: 'Date de début invalide.' });
                      
    return;

  }
    
  let idsc = parseInt(sessionStorage.getItem('idsociety'));
  let idtype = parseInt(sessionStorage.getItem('typeuser'));
  let iduserselect = parseInt(sessionStorage.getItem('iduser'));
  
  if(idsc>1 && idtype==3){

    const response = await fetch(this.state.wbslink + 'audio/listaudioidsentiment.php',{                  
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        idsoc:idsc,
        Datedu: this.state.datedu,
        Dateau: this.state.dateau,
      })});

    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") != "0"){
      this.setState({ listsound: data, loadershow: false})
      this.refreshsentiment(data)
    }else{
      this.setState({
        loadershow: false,
        nombrepositif :0,
        nombreneutre :0,
        nombrenegatif :0,
        speakerpositif :0,
        speakernegatif : 0,
        speakerneutre : 0,
        listsound: [],
      })
    }

  }

  if(idsc>1 && idtype==4){

    const response = await fetch(this.state.wbslink + 'audio/listaudioidsentiment.php',{                  
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        idsoc:idsc,
        Datedu: this.state.datedu,
        Dateau: this.state.dateau,
      })});

    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") != "0"){
      this.setState({ listsound: data, loadershow: false})
      this.refreshsentiment(data)
    }else{
      this.setState({
        loadershow: false,
        nombrepositif :0,
        nombreneutre :0,
        nombrenegatif :0,
        speakerpositif :0,
        speakernegatif : 0,
        speakerneutre : 0,
        listsound: [],
      })
    }

  }

  if(idsc==1 && idtype==4){

    const response = await fetch(this.state.wbslink + 'audio/listaudioidusersentiment.php',{                  
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        idusers:iduserselect,
        Datedu: this.state.datedu,
        Dateau: this.state.dateau,
      })});

    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") != "0"){
      this.setState({ listsound: data, loadershow: false})
      this.refreshsentiment(data)
    }else{
      this.setState({
        loadershow: false,
        nombrepositif :0,
        nombreneutre :0,
        nombrenegatif :0,
        speakerpositif :0,
        speakernegatif : 0,
        speakerneutre : 0,
        listsound: [],
      })
    }

  }
  
  if(idtype==2){

    const response = await fetch(this.state.wbslink + 'audio/listaudiosentiment.php',{                  
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        idsoc:idsc,
        Datedu: this.state.datedu,
        Dateau: this.state.dateau,
      })});

    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") != "0"){
      this.state.sentimentglobal.length = 0;
      this.setState({listsound: data,loadershow: false})
      this.refreshsentiment(data)
    }else{

      this.setState({
        loadershow: false,
        nombrepositif :0,
        nombreneutre :0,
        nombrenegatif :0,
        speakerpositif :0,
        speakernegatif : 0,
        speakerneutre : 0,
        listsound: [],
      })

    }
  
  }
  
}; 

refreshsentiment (data){

        var Positif = 0;
        var Neutre = 0;
        var Negatif = 0;
        var Soundtotal = data.length;

        var SentimentGlobalePositif = 0.00;
        var SentimentGlobaleNegatif = 0.00;
        var SentimentGlobaleNeutre = 0.00;

        data.forEach((item)=>{

          if(parseFloat(item.sentimentpositif)>parseFloat(item.sentimentnegatif)){
            Positif++
          }

          if(parseFloat(item.sentimentpositif)==parseFloat(item.sentimentnegatif)){
            Neutre++
          }

          if(parseFloat(item.sentimentpositif)<parseFloat(item.sentimentnegatif)){
            Negatif++
          }

        })

        SentimentGlobalePositif = (Positif/Soundtotal)*100;
        SentimentGlobaleNegatif = (Negatif/Soundtotal)*100;
        SentimentGlobaleNeutre = (Neutre/Soundtotal)*100;

        this.setState({
          nombrepositif :Positif,
          nombreneutre :Neutre,
          nombrenegatif :Negatif,
          speakerpositif : Math.round(SentimentGlobalePositif),
          speakernegatif : Math.round(SentimentGlobaleNegatif),
          speakerneutre : Math.round(SentimentGlobaleNeutre),
        })

        this.state.sentimentglobal.push({ 
          name: 'Positif', value:  Math.round(SentimentGlobalePositif),
        });
        this.state.sentimentglobal.push({ 
            name: 'Neutre', value: Math.round(SentimentGlobaleNeutre) ,
        });
        this.state.sentimentglobal.push({ 
          name: 'Négatif', value: Math.round(SentimentGlobaleNegatif),
        });

}

  render() {

    const handleChange = (e) => {
      this.setState({selectedValue: e.target.value})
    }

          return (
              
                <div className="App" style={{marginTop:'80px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel par centre' : 'Global call sentiment per center'}</p>

                    <div className="row justify-content-md-center sentimentglobal">
                      <div className="col-sm sentimentglobal">
                        <BarChart width={500} height={300} 
                            margin={{
                              top: 0,
                              right: 60,
                              left: 20,
                              bottom: 5
                            }}
                            data={dataville}
                            layout="vertical"
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" domain={[0, 100]}/>
                            <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="name" domain={[0, 100]}/>
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="positif" stackId="a" fill="#00934c" />
                            <Bar dataKey="neutre" stackId="a" fill="#e89900" />
                            <Bar dataKey="negatif" stackId="a" fill="#e40001" />
                          </BarChart>
                          
                      </div>
                      <div className="col-sm justify-content-center sentimentglobal">

                        

                      
                        
                    <div className="champnegatifglobal" style={{padding:'5px'}}>

                      <div className="row">
                        <div className="col">
                          
                          <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                          <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakernegatif}%</p>
                          <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.nombrenegatif} {this.state.lng ==='FR'? 'audios': 'sounds'}</p>

                        </div>
                        <div className="col">
                          <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px', marginTop:'12.5px' }} /></span>
                        </div>
                      </div>



                      </div>

                          <div className="champneutreglobal" style={{padding:'5px'}}>

                            <div className="row">
                              <div className="col">
                                
                                <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                                <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerneutre}%</p>
                                <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.nombreneutre} {this.state.lng ==='FR'? 'audios': 'sounds'}</p>

                              </div>
                              <div className="col">
                                <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px', marginTop:'12.5px' }} /></span>
                              </div>
                            </div>

                          </div>

                          <div className="champpositifglobal" style={{padding:'5px'}}>

                              <div className="row">
                                <div className="col">
                                  
                                  <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                                  <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerpositif}%</p>
                                  <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.nombrepositif} {this.state.lng ==='FR'? 'audios': 'sounds'}</p>
                                  

                                </div>
                                <div className="col">
                                  <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px', marginTop:'12.5px' }} /></span>
                                </div>
                              </div>
                            
                            </div>

                      </div>
                      <div className="col-sm" style={{color:'#2A83E8'}}>
                        <p style={{fontSize:"1.2em"}}> Variation*</p>
                        <p style={{fontSize:"2em",fontWeight:'bold'}}>-5.0%</p>
                        <p style={{fontSize:"1.2em"}}> Variation*</p>
                        <p style={{fontSize:"2em",fontWeight:'bold'}}>+2.4%</p>
                        <p style={{fontSize:"1.2em"}}> Variation*</p>
                        <p style={{fontSize:"2em",fontWeight:'bold'}}>0.0%</p>
                      </div>
                    </div>


                    <BrowserView>

                      {/*
                      <div className=' d-flex flex-row justify-content-center' >
                        <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('all')}>{this.state.lng ==="FR" ? 'Tous les temps' : 'All time'}</Button>
                        <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('year')}>{this.state.lng ==="FR" ? 'Dernière année' : 'Last year'}</Button>
                        <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('month')}>{this.state.lng ==="FR" ? 'Dernier mois' : 'Last month'}</Button>
                        <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('week')}>{this.state.lng ==="FR" ? 'Dernière semaine' : 'Last week'}</Button>
                      </div>
                      */}

                        <div className="row" style={{marginBottom:"40px", marginTop:"20px"}}>

                          <div className="col-4 dateinput" >
                            <p>{this.state.lng ==='FR'? 'Début filtre': 'Start filter'}</p>
                              <DatePicker
                                      maxDate={new Date()}
                                      selected = {dateselectdu}
                                      onChange={(date) => this.selectdatedu(date)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      locale="fr"
                                      dateFormat="dd/MM/yyyy"
                                      dropdownMode="select"
                                      withPortal
                                    />

                            
                          </div>

                          <div className="col-4 dateinput">

                            <p>{this.state.lng ==='FR'? 'Fin filtre': 'End filter'}</p>

                                <DatePicker
                                        maxDate={new Date()}
                                        selected = {dateselectau}
                                        onChange={(date) => this.selectdateau(date)}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        locale="fr"
                                        dateFormat="dd/MM/yyyy"
                                        dropdownMode="select"
                                        withPortal
                                      />
                          </div>

                          <div className="col-4 dateinput">
                            <p style={{marginTop:"-5px"}}>{this.state.lng ==='FR'? 'Action': 'Action'}</p>
                                  <Button type="submit" className="btn" style={{width:'100px'}} variant="primary" onClick={() => this.selectlistdate()}>{this.state.lng ==='FR'? 'Filtrer': 'Filter'}</Button>
                          </div>

                          </div>
                      
                      </BrowserView>


                      <MobileView>

                        <div className='d-flex align-items-center flex-column'>

                        
                          {this.state.lng ==='FR'? 'Début filtre': 'Start filter'}
                          <DatePicker
                                  maxDate={new Date()}
                                  selected = {dateselectdu}
                                  onChange={(date) => this.selectdatedu(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  locale="fr"
                                  dateFormat="dd/MM/yyyy"
                                  dropdownMode="select"
                                  withPortal
                                />

                          {this.state.lng ==='FR'? 'Fin filtre': 'End filter'}
                          <DatePicker
                                  maxDate={new Date()}
                                  selected = {dateselectau}
                                  onChange={(date) => this.selectdateau(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  locale="fr"
                                  dateFormat="dd/MM/yyyy"
                                  dropdownMode="select"
                                  withPortal
                                />
                         

                          <Button className="btn" style={{width:'100px',marginTop:"20px"}} variant="primary" onClick={() => this.selectlistdate()}>{this.state.lng ==='FR'? 'Filtrer': 'Filter'}</Button>

                          
                          </div>
                      </MobileView>
                          
                    {this.state.loadershow === true ?
                          

                    <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                      

                    :

                      <div>

                        {this.state.listsound.length === 0 ?

                        <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Pas d\'audio transcrit.' : 'No sound transcript.'}&nbsp;</p>

                        :

                        null

                        }
                        
                      </div>

                    }

                    <div className='centrervue'>

                    <input className="inputfind" style={{marginTop: isBrowser?"0px":"50px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>

                    <BrowserView>

                    {this.state.listsound.length>0?

                    <Table>
                      <Thead>
                        <Tr>
                          
                          <Th >{this.state.lng ==="FR" ? 'Transcrire' : 'Transcribe Call'}</Th>
                          <Th >{this.state.lng ==="FR" ? 'ID de l\'appel' : 'ID Call'}</Th>
                          <Th style={{width:"80px"}}>{this.state.lng ==="FR" ? 'Durée' : 'Duration'}</Th>
                          <Th style={{width:"220px"}}>{this.state.lng ==="FR" ? 'Date de transcription' : 'Transcription date'}</Th>
                          {/*<TableCell >{this.state.lng ==="FR" ? 'Mettre à jour Thématique' : 'Update Thematic'}</TableCell>*/}
                          {/*<Th >{this.state.lng ==="FR" ? 'Thématique' : 'Thematic'}</Th>*/}
                          <Th >{this.state.lng ==="FR" ? 'Résumé' : 'Summarization'}</Th>
                          {/*<TableCell >{this.state.lng ==="FR" ? 'Mettre à jour Speaker' : 'Update Speaker'}</TableCell>*/}
                          <Th >Speaker</Th>
                          <Th >{this.state.lng ==="FR" ? 'Commentaire' : 'Comment'}</Th>
                          <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Détails' : 'Call Details'}</Th>
                          {/*<Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Th>*/}
                        
                        </Tr>
                      </Thead>
                      <Tbody>
                      {this.state.listsound.filter(sound => sound.idaudio > 15 ).map((row) => (
                          <Tr key={row.idaudio} style={{backgroundColor: row.idaudio === this.state.idaudioselect ? "#333333": "white"}} className="tableborder" >
                            
                            <Td >{row.iddiarizeur === '0' ?

                                          <button  className="btn-primary btn-circle btn-xl" style={{margin:"10px"}} onClick={() => this.audiosend(row.idaudio,row.nomaudio,row.duree,row.langue)}><span><ReadMoreIcon /></span></button>

                                          :

                                          <button  className="btn-gris btn-circle btn-xl" style={{margin:"10px"}} onClick={() => this.diarizationeffectue()}><span><CheckBoxIcon /></span></button>

                                          }
                            </Td>
                            
                            <Td style={{color: row.idaudio === this.state.idaudioselect ? "white": "black",paddingTop:"10px"}} className="maxwidthline align-items-center justify-content-center">
                            
                            {row.idaudio === this.state.idaudioselect?
                              
                              <div className="container " >

                                {this.state.soundnamebutton== true?

                                  <div className="row ">


                                    <div className="col " style={{width:"25px",paddingTop:"20px"}}>
                                      
                                      {this.state.soundnamebutton== true?
                                        <span style={{color:'#dc3545'}} onClick={() => this.refreshlistsound()}><CancelIcon /></span>
                                        :
                                        null
                                      }
                                      
                                    </div>
                                    <div className="col">

                                      {this.state.thematique === "" ?

                                        <div>

                                            {this.state.soundnamebutton== true?

                                                <textarea type="textarea" name="thematique" rows={3}  value={this.state.soundname} onChange={(e) => this.setState({ soundname: e.target.value})}/>

                                                :

                                                <p onClick={() => this.setState({soundname:row.nomaudio!== ""? row.nomaudio :"", idaudioselect:row.idaudio,soundnamebutton:true})}>{row.nomaudio}</p>
                                          
                                            }


                                        </div>


                                          :

                                          <div>

                                              {this.state.soundnamebutton== true?

                                              <textarea type="textarea" name="thematique" rows={3}  value={this.state.soundname} onChange={(e) => this.setState({ soundname: e.target.value})}/>

                                              :

                                              <p onClick={() => this.setState({soundname:row.nomaudio!== ""? row.nomaudio :"", idaudioselect:row.idaudio,soundnamebutton:true})}>{row.nomaudio}</p>

                                              }

                                          </div>


                                      }
                                      
                                    </div>
                                    <div className="col" style={{width:"25px",paddingTop:"20px"}}>
                                      {this.state.soundnamebutton== true?
                                        <span style={{color:'#00934c'}} onClick={() => this.updatecomment()}><SaveAsIcon /></span>
                                        :
                                        null
                                      }        
                                    </div>
                                  </div>

                                  :



                                  <p onClick={() => this.setState({soundname:row.nomaudio!== ""? row.nomaudio :"", idaudioselect:row.idaudio,soundnamebutton:true})}>{row.nomaudio}</p>

                                
                                
                                }

                              </div>

                              :

                              <p onClick={() => this.setState({soundname:row.nomaudio!== ""? row.nomaudio :"", idaudioselect:row.idaudio,soundnamebutton:true})}>{row.nomaudio}</p>
                              
                              }

                              </Td>
                              
                            {/*<Td style={{color: row.idaudio === this.state.idaudioselect ? "white": "black"}} className="maxwidthline">{row.nomaudio.slice(row.nomaudio.lenght, -4)}</Td>*/}
                            <Td style={{color: row.idaudio === this.state.idaudioselect ? "white": "black"}}>{Math.floor((row.duree % 3600) / 60)}m {Math.round(((row.duree / 60)-Math.floor(row.duree / 60))*60)}s</Td>
                            <Td style={{color: row.idaudio === this.state.idaudioselect ? "white": "black", paddingTop:"18px"}}>{row.iddiarizeur === '0' ? "" : <p>{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}s</p>}</Td>
                            <Td style={{color: row.idaudio === this.state.idaudioselect ? "white": "black",textAlign:"left"}} className="maxwidthline">{row.summarization.substring(0,100)} {row.summarization.length>100?" ...":""}</Td>
                            {/*<TableCell ><button type="submit" className="btn-success btn-circle btn-xl" onClick={() => this.themeatiquemodal(row.idaudio,row.thematique)}><span><AssignmentIcon  style={{color:"white"}}/></span></button>
                                    </TableCell>*/}
                            
                            {/*
                            <Td style={{color: row.idaudio === this.state.idaudioselect ? "white": "black"}} className="maxwidthline">
                            
                            {row.idaudio === this.state.idaudioselect?
                              
                              <div className="container " >

                                {this.state.thematiquebutton== true?

                                  <div className="row ">


                                    <div className="col " style={{width:"25px"}}>
                                      
                                      {this.state.thematiquebutton== true?
                                        <span style={{color:'#dc3545'}} onClick={() => this.refreshlistsound()}><CancelIcon /></span>
                                        :
                                        null
                                      }
                                      
                                    </div>
                                    <div className="col">

                                      {this.state.thematique === "" ?

                                        <div>

                                            {this.state.thematiquebutton== true?

                                                <textarea type="textarea" name="thematique" rows={3}  value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>

                                                :

                                                <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.idaudio,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>
                                          
                                            }


                                        </div>


                                          :

                                          <div>

                                              {this.state.thematiquebutton== true?

                                              <textarea type="textarea" name="thematique" rows={3}  value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>

                                              :

                                              <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.idaudio,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>

                                              }

                                          </div>


                                      }
                                      
                                    </div>
                                    <div className="col" style={{width:"25px"}}>
                                      {this.state.thematiquebutton== true?
                                        <span style={{color:'#00934c'}} onClick={() => this.updatecomment()}><SaveAsIcon /></span>
                                        :
                                        null
                                      }        
                                    </div>
                                  </div>

                                  :



                                  <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.idaudio,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>

                                
                                
                                }

                              </div>

                              :

                              <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.idaudio,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>
                              
                              }

                              </Td>
                              */}
                            {/*<TableCell ><button type="submit" className="btn-warning btn-circle btn-xl" style={{color:'white'}}onClick={() => this.speakermodal(row.idaudio,row.speaker1,row.speaker2)}><span><RecordVoiceOverIcon /></span></button></TableCell>*/}
                            <Td style={{color: row.idaudio === this.state.idaudioselect ? "white": "black"}} className="maxwidthline">
                            
                          
                            
                            {row.idaudio === this.state.idaudioselect?
                              
                              <div className="container noselect" >

                                {this.state.speakerbutton== true?

                                  <div className="row ">


                                    <div className="col " style={{width:"10px", marginTop:"20px"}}>
                                      
                                      {this.state.speakerbutton== true?
                                        <span style={{color:'#dc3545'}} onClick={() => this.refreshlistsound()}><CancelIcon /></span>
                                        :
                                        null
                                      }
                                      
                                    </div>
                                    <div className="col">

                                      {this.state.speaker1 === "" ?

                                        <div>

                                            {this.state.speakerbutton== true?

                                                <div style={{width:"200px"}}>

                                                    <input  className="noselect" type="text" placeholder={this.state.speaker1} ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                                                  
                                                    <input  className="noselect" type="text" placeholder={this.state.speaker2} ref="speaker2" style={{marginTop:"10px"}} value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>

                                                </div>

                                                :

                                                <p onClick={() => this.setState({speaker1:row.speaker1, speaker2:row.speaker2,idaudioselect:row.idaudio,speakerbutton:true})}>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</p>
                                          
                                            }


                                        </div>


                                          :

                                          <div>

                                              {this.state.speakerbutton== true?

                                                <div style={{width:"200px"}}>

                                                  
                                                  <input  className="noselect" type="text" placeholder={this.state.speaker1} ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                                                  
                                                  <input  className="noselect" type="text" placeholder={this.state.speaker2} ref="speaker2" style={{marginTop:"10px"}}value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>

                                                </div>

                                              :

                                                <p onClick={() => this.setState({speaker1:row.speaker1, speaker2:row.speaker2,idaudioselect:row.idaudio,speakerbutton:true})}>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</p>

                                              }

                                          </div>


                                      }
                                      
                                    </div>
                                    <div className="col" style={{width:"10px", marginTop:"20px"}}>
                                      {this.state.speakerbutton== true?
                                        <span style={{color:'#00934c'}} onClick={() => this.speakermodalsound()}><SaveAsIcon /></span>
                                        :
                                        null
                                      }        
                                    </div>
                                  </div>

                                  :



                                  <p onClick={() => this.setState({speaker1:row.speaker1, speaker2:row.speaker2,idaudioselect:row.idaudio,speakerbutton:true})}>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</p>

                                
                                
                                }

                              </div>

                              :

                              <p onClick={() => this.setState({speaker1:row.speaker1, speaker2:row.speaker2,idaudioselect:row.idaudio,speakerbutton:true})}>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</p>
                              
                              }
                            
                            
                            </Td>
                            <Td style={{color: row.idaudio === this.state.idaudioselect ? "white": "black"}} className="maxwidthline" >
                            
                            {row.idaudio === this.state.idaudioselect?
                              
                              <div className="container " >

                                {this.state.commentbutton== true?

                                  <div className="row ">


                                    <div className="col " style={{width:"25px"}}>
                                      
                                      {this.state.commentbutton== true?
                                        <span style={{color:'#dc3545'}} onClick={() => this.refreshlistsound()}><CancelIcon /></span>
                                        :
                                        null
                                      }
                                      
                                    </div>
                                    <div className="col">

                                      {this.state.comment === "" ?

                                        <div>

                                            {this.state.commentbutton== true?

                                                <input type="text" className="inputtable" value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value})}></input>

                                                :

                                                <p onClick={() => this.setState({comment:row.comment.length>0? row.comment :"", idaudioselect:row.idaudio,commentbutton:true})}>{row.comment.length>0 ? row.comment : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter un commentaire' : 'Click to add comment'} </p>}</p>
                                          
                                            }


                                        </div>


                                          :

                                          <div>

                                              {this.state.commentbutton== true?

                                              <input type="text" className="inputtable" value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value})}></input>

                                              :

                                              <p onClick={() => this.setState({comment:row.comment.length>0? row.comment :"", idaudioselect:row.idaudio,commentbutton:true})}>{row.comment.length>0 ? row.comment : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter un commentaire' : 'Click to add comment'} </p>}</p>

                                              }

                                          </div>


                                      }
                                      
                                    </div>
                                    <div className="col" style={{width:"25px"}}>
                                      {this.state.commentbutton== true?
                                        <span style={{color:'#00934c'}} onClick={() => this.updatecomment()}><SaveAsIcon /></span>
                                        :
                                        null
                                      }        
                                    </div>
                                  </div>

                                  :



                                  <p onClick={() => this.setState({comment:row.comment.length>0? row.comment :"", idaudioselect:row.idaudio,commentbutton:true})}>{row.comment.length>0 ? row.comment : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter un commentaire' : 'Click to add comment'} </p>}</p>

                                
                                
                                }

                              </div>

                              :

                              <p onClick={() => this.setState({comment:row.comment.length>0? row.comment :"", idaudioselect:row.idaudio,commentbutton:true})}>{row.comment.length>0 ? row.comment : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter un commentaire' : 'Click to add comment'} </p>}</p>
                              
                              }
                              
                              
                            </Td>
                            <Td style={{textAlign:'center'}}>

                              {parseFloat(row.sentimentpositif)>parseFloat(row.sentimentnegatif) ?

                              <button className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety)}><span style={{color:'#00934c'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }


                              {parseFloat(row.sentimentpositif)<parseFloat(row.sentimentnegatif) ?

                              <button  className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety)}><span style={{color:'#e40001'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }

                              {parseFloat(row.sentimentpositif)===parseFloat(row.sentimentnegatif) && row.iddiarizeur !== '0' ?

                              <button className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety)}><span style={{color:'#e89900'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }

                              {/*{parseInt(row.sentimentglobale) === 1 && row.iddiarizeur !== '0'?

                              <button type="submit" className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique)}><span style={{color:'#00934c'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }


                              {parseInt(row.sentimentglobale) === 2 && row.iddiarizeur !== '0'?

                              <button type="submit" className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique)}><span style={{color:'#e40001'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }

                              {parseInt(row.sentimentglobale) === 0 && row.iddiarizeur !== '0' ?

                              <button type="submit" className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique)}><span style={{color:'#e89900'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }*/}

                              {row.iddiarizeur === '0' ?

                              <button  className="btn-gris btn-circle btn-xl" onClick={() => this.diarizationnoneffectue()}><span style={{color:"#333333"}}><VisibilityOffIcon /></span></button>

                              :

                              null

                              }

                            </Td>

                            {/*<Td ><button  className="btn btntransparent btn-circle btn-xl" onClick={() => this.updatemodal(row.idaudio,row.thematique,row.speaker1,row.speaker2,row.nomaudio.slice(row.nomaudio.lenght, -4))}><span><EditIcon style={{color:"#2A83E8"}}/></span></button> </Td>*/}

                       
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>

                    :

                      null

                    }

                    {this.state.listsound.length>0?

                      <ReactPaginate
                      previousLabel={"<< prev"}
                      previousClassName={"prevClass"}
                      previousLinkClassName={"prevClass"}
                      nextLabel={"next >>"}
                      nextClassName={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>

                      :

                      null
                    }

                    </BrowserView>

                    <MobileView>
                    
                    <div className='paginationmobile' >

                    {this.state.listsound.length>0?

                      <ReactPaginate
                      previousLabel={"<<"}
                      previousClassName={"prevClass"}
                      previousLinkClassName={"prevClass"}
                      nextLabel={">>"}
                      nextClassName={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>

                      :

                      null
                      
                    }

                    </div>

                    <div style={{height:"80px"}}>

                    </div>

                    {this.state.listsound.map((row) => (

                      <Card style={{ width: '90%', margin:"5%"}}>

                        <Card.Body key={row.idaudio} style={{backgroundColor: row.idaudio === this.state.idaudioselect ? "#333333": "white"}}>
                          
                          <p style={{color: row.idaudio === this.state.idaudioselect ? "white": "black"}}>{row.nomaudio}</p>

                          {row.iddiarizeur === '0' ?

                            <div>

                                <button className="btn-primary btn-circle btn-xl" onClick={() => this.audiosend(row.idaudio,row.nomaudio,row.duree,row.langue)}><span><ReadMoreIcon /></span></button>

                                <p style={{marginTop:"20px"}}>{this.state.lng ==="FR" ? 'L\'audio n\'a pas encore été transcrit.' : 'Sound not transcript.'}</p>

                            </div>
                            

                            :

                            <div>

                                {row.summarization !== "" ?
                                  <p style={{color: row.idaudio === this.state.idaudioselect ? "white": "black", textAlign:"left"}}>{this.state.lng ==="FR" ? 'Résumé : ' : 'Summarization : '} {row.summarization.substring(0,100)} {row.summarization.length>100?" ...":""}</p>
                                  :
                                  null
                                }

                                {/* 
                                {row.thematique !== "" ?
                                  <p style={{color: row.idaudio === this.state.idaudioselect ? "white": "black", fontWeight:"bold", textAlign:"left"}}>Thématique: {row.thematique}</p>
                                  :
                                  null
                                }
                                */}

                                <p style={{color: row.idaudio === this.state.idaudioselect ? "white": "black", textAlign:"left"}}>Speaker 1 : {row.speaker1}<br/>Speaker 2 : {row.speaker2}</p>
                                <p style={{color: row.idaudio === this.state.idaudioselect ? "white": "black", textAlign:"left"}}>{row.comment}</p>
                                <p style={{color: row.idaudio === this.state.idaudioselect ? "white": "black"}}>{this.state.lng ==="FR" ? 'Durée : ' : 'Duration : '}{Math.floor((row.duree % 3600) / 60)}m {Math.round(((row.duree / 60)-Math.floor(row.duree / 60))*60)}s {row.iddiarizeur === '0' ? "" : <p>({parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.dateheurediarization.replace(/[-]/g, '/'))).getSeconds())}s)</p>}</p>
                                {parseFloat(row.sentimentpositif)>parseFloat(row.sentimentnegatif) ?
                                  
                                  <div className='d-flex align-items-center'style={{ marginTop:"20px"}}>
                                          
                                      <button  className="btn btntransparent btn-circle btn-xl " style={{marginLeft: "40%"}} onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety)}><span style={{color:'#00934c'}}><VisibilityIcon /></span></button>
                                 
                                  </div>

                                  :

                                  null

                                }


                                {parseFloat(row.sentimentpositif)<parseFloat(row.sentimentnegatif) ?

                                  <div className='d-flex align-items-center' style={{ marginTop:"20px" }}>
                                                                            
                                    <button className="btn btntransparent btn-circle btn-xl" style={{marginLeft: "40%"}} onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety)}><span style={{color:'#e40001'}}><VisibilityIcon /></span></button>

                                  </div>

                                  :

                                  null

                                }

                                {parseFloat(row.sentimentpositif)===parseFloat(row.sentimentnegatif) && row.iddiarizeur !== '0' ?

                                  <div className='d-flex align-items-center' style={{ marginTop:"20px" }}>
                                                                                                              
                                    <button className="btn btntransparent btn-circle btn-xl" style={{marginLeft: "40%"}} onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization,row.dataextraction,row.idsociety)}><span style={{color:'#e89900'}}><VisibilityIcon /></span></button>

                                  </div>

                                  :

                                  null

                                }
                                                              

                            </div>

                          }

                        </Card.Body>

                      </Card>

                    ))}



                    </MobileView>

                      

                    </div> 

                    {this.state.isModaldetails === true ?

                    <div> 

                    <Modal
                    fullscreen
                    show={this.state.isModaldetails}
                    onHide={ () => this.setState({isModaldetails:false, speaker1:0})}
                    centered
                    animation={false}
                    className="modaldetails"
                    >
                    
                      <Modal.Body>

                        <BrowserView>

                        <div className='modaldetailsdata' >

                            {/*<p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.soundname}</p>*/}

                            <div style={{position:"fixed", right:'10px', top:0, zIndex: '1000'}}>
                              <button  className="btn btntransparent" onClick={() => this.setState({isModaldetails:false,speaker1:0})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '50px', height:'50px' }}/></span></button>
                            </div>
                                                    
                                <div className="row">

                                  <div className="col-sm thematiquevalue">
                                    <div className="row">
                                        <div style={{width:'18%'}}>
                                        <img src={logo} style={{width:'100px', height:'70px', marginTop:'20px'}}/>
                                        {/*<span style={{color:'white',textAlign:"center"}}><ForumIcon sx={{ width: '100%', height:'100%' }}/></span>*/}
                                        </div>

                                        <div style={{width:'82%'}} className="d-flex flex-column align-items-center ">
                                          <p style={{color:'white', fontWeight:'bold', marginTop:'10px'}}>{this.state.soundname}</p>
                                          {/*<p style={{color:'white', fontWeight:'bold', fontSize:'1em',textAlign:"left", marginTop:'10px', textDecoration:'underline'}}>{this.state.lng ==="FR" ? 'Thématique' : 'Thematique'}</p>*/}
                                          {/*<hr className="lineseparator"></hr>*/}
                                          <p style={{color:'white',textAlign:"left"}}>{this.state.lng ==="FR" ? 'Résumé: ' : 'Summarization: '}{this.state.summarization}</p>
                                        </div>

                                    </div>
                                    
                                  </div>
                                  
                                  {/*<div className="col-sm">
                                    
                                    <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Audio player' : 'Audio player'}</p>

                                        

                                        <ReactToPrint
                                          trigger={() => (
                                            <a className="ant-btn ant-btn-primary" href="#">
                                              {this.state.lng ==='FR'? 'Imprimer': 'Print'}
                                            </a>
                                          )}
                                          content={() => this.componentRef}
                                          />
                                                                
                                  </div>*/}

                                    {parseInt(this.state.idsoc) == 5 ?

                                    <p style={{textAlign:'left', margin:"20px", marginTop:"20px"}} >{parse(this.state.dataextract)}</p>     

                                    :

                                    null

                                    }

                                  <div className="col-sm" >

                                  <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Global call sentiment'}</p>

                                    <div className='sentimentglobal' style={{marginLeft:'5px',marginRight:'5px',marginTop:'0px', fontWeight:'bold'}}>

                                      <div className="champnegatifmodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakernegatif}%</p>

                                          </div>
                                          <div className="col">
                                            <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                          </div>
                                        </div>
                                      
                                          
                                      
                                      </div>

                                      <div className="champneutremodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerneutre}%</p>

                                          </div>
                                          <div className="col">
                                            <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                          </div>
                                        </div>
                                        
                                      </div>

                                      <div className="champpositifmodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerpositif}%</p>

                                          </div>
                                          <div className="col">
                                            <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                          </div>
                                        </div>
                                      
                                      </div>

                                    </div>

                                  </div>

                                </div>

                              {this.state.speaker1.length>0 ?

                                <div className='modaldatasentiment'>

                                  <div className="row" >

                                    <div className="col-12 col-md-8">
                                        
                                        <div className='reponsediarization' >


                                        <div >
                                          <p style={{color:'black', fontWeight:'bold', fontSize:'1.2em', textAlign:'center', marginTop:'0px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>
                                        </div>

                                            {this.state.speakers.map((row) => (

                                                      <div key={row.iddiarization} style={{marginTop:'20px'}}>

                                                          <div> {row.speaker === '1' ?
                                                                                  
                                                            <div  id={parseInt(row.iddiarization)}>

                                                              <div className="container">

                                                                <div className="row" >

                                                                  <div className="col-sm">

                                                                      <div class="container">
                                                                        <div class="row">
                                                                          <div class="col">
                                                                            <p className="champnegatifsentimentmodal">
                                                                              {parseInt(parseFloat(row.negatif)*100)}%
                                                                            </p>
                                                                          </div>
                                                                          <div class="col">
                                                                            <p className="champneutresentimentmodal">
                                                                              {parseInt(parseFloat(row.neutre)*100)}%
                                                                            </p>
                                                                          </div>
                                                                          <div class="col">
                                                                            <p className="champpositifsentimentmodal">
                                                                              {parseInt(parseFloat(row.positif)*100)}%
                                                                            </p>
                                                                          </div>
                                                                        </div>
                                                                      </div>

                                                                  </div>
                                                                  
                                                                  <div className="col-sm" style={{textAlign:'center'}}>
                                                                    <p >{row.starttime}</p>
                                                                  </div>

                                                                  <div className="col-sm">
                                                                    
                                                                  </div>

                                                                </div>

                                                              </div>

                                                              <div className="row">

                                                                <div className="col-8">
                                                                  <div style={{width:'100%', textAlign:'left', marginLeft:'20px'}} className='textleft'>
                                                                    <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                                  </div>
                                                                </div>

                                                                <div className="col-1">
                                                                  {row.sentimentspeaker ==='positive' ?

                                                                  <div className="btnpositifmodal btn-sentiment leftunsaid">
                                                                    <div className="centerunsaid">S1</div>
                                                                  </div>
                                                                  :

                                                                  null

                                                                  }

                                                                  {row.sentimentspeaker ==='neutral' ?

                                                                  <div className="btnneutremodal btn-sentiment leftunsaid">
                                                                    <div className="centerunsaid">S1</div>
                                                                  </div>

                                                                  :

                                                                  null

                                                                  }

                                                                  {row.sentimentspeaker ==='mixed' ?

                                                                  <div className="btnmixed btn-sentiment leftunsaid">
                                                                    <div className="centerunsaid">S1</div>
                                                                  </div>

                                                                  :

                                                                  null

                                                                  }

                                                                  {row.sentimentspeaker ==='negative' ?

                                                                  <div className="btnnegatifmodal btn-sentiment leftunsaid">
                                                                    <div className="centerunsaid">S1</div>
                                                                  </div>

                                                                  :

                                                                  null

                                                                  }

                                                                </div>

                                                            </div>

                                                            <hr className="lineseparator"></hr>                                               
                                                              
                                                          </div>

                                                            :
                                                                                  
                                                          <div  id={parseInt(row.iddiarization)}>

                                                            <div className="container">

                                                              <div className="row">

                                                                <div className="col-sm">

                                                                </div>
                                                                
                                                                <div className="col-sm" style={{textAlign:'center'}}>
                                                                  <p >{row.starttime}</p>
                                                                </div>

                                                                <div className="col-sm">
                                                                  <div class="container">
                                                                      <div class="row">
                                                                        <div class="col">
                                                                          <p className="champnegatifsentimentmodal">
                                                                            {parseInt(parseFloat(row.negatif)*100)}%
                                                                          </p>
                                                                        </div>
                                                                        <div class="col">
                                                                          <p className="champneutresentimentmodal">
                                                                            {parseInt(parseFloat(row.neutre)*100)}%
                                                                          </p>
                                                                        </div>
                                                                        <div class="col">
                                                                          <p className="champpositifsentimentmodal">
                                                                            {parseInt(parseFloat(row.positif)*100)}%
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                </div>

                                                              </div>

                                                            </div>

                                                            <div className="row">

                                                            <div className="col-2">
                                                                <div style={{width:'100%', textAlign:'center'}}>
                                                                  <p style={{lineHeight:'20px', color:'transparent'}}>.</p>
                                                                </div>
                                                                
                                                              </div>
                                                              
                                                              <div className="col-1">
                                                                {row.sentimentspeaker ==='positive' ?

                                                                <div className="btnpositifmodal btn-sentiment rightunsaid">
                                                                  <div className="centerunsaid">S2</div>
                                                                </div>
                                                                :

                                                                null

                                                                }

                                                                {row.sentimentspeaker ==='neutral' ?

                                                                <div className="btnneutremodal btn-sentiment rightunsaid">
                                                                  <div className="centerunsaid">S2</div>
                                                                </div>

                                                                :

                                                                null

                                                                }

                                                                {row.sentimentspeaker ==='mixed' ?

                                                                <div className="btnmixed btn-sentiment rightunsaid">
                                                                  <div className="centerunsaid">S2</div>
                                                                </div>

                                                                :

                                                                null

                                                                }

                                                                {row.sentimentspeaker ==='negative' ?

                                                                <div className="btnnegatifmodal btn-sentiment rightunsaid">
                                                                  <div className="centerunsaid">S2</div>
                                                                </div>

                                                                :

                                                                null

                                                                }
                                                                
                                                              </div>

                                                              <div className="col-8">
                                                                <div style={{width:'100%', textAlign:'center'}} className='textright'>
                                                                  <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                                </div>
                                                                
                                                              </div>

                                                          </div>    

                                                          <hr className="lineseparator"></hr>                        
                                                                                                                  
                                                          </div>

                                                        }
                                                        
                                                        </div>

                                                        
                                                      </div>

                                            ))}

                                          <div style={{height:'50px'}} id="lastline">
                                              <p style={{color:'transparent'}}>.</p>
                                          </div>

                                          </div>
                                    </div>



                                    <div className="col-6 col-md-4">

                                      <div className='sentimentchart' style={{marginTop:'0px'}} id="chart">

                                        <p style={{color:'black', marginTop:'0px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}} >S1 : {this.state.speaker1name}</p>                           
                                          
                                          <div className='listesentiment'>
                                            <PieChart width={500} height={300}
                                                  margin={{
                                                    top: 0,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 20,
                                                  }}
                                                >                                     
                                              <Pie  data={Object.values(this.state.speaker1sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                                {data.map((entry, index) => (
                                                  <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                  
                                                ))}
                                                <Label value="Speaker 1" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em'}}/>
                                                {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                              </Pie>
                                              <Tooltip/>
                                              {/*<Legend />*/}
                                            </PieChart>
                                          </div>

                                          <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S1 : Sentiment de l\'appel' : 'S1 : Call sentiment'}</p>

                                          <div className='listesentiment' style={{marginTop:"20px"}}> 

                                            {/*<LineChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="starttime" />
                                              <YAxis domain={[0.00, 1.00]}/>
                                              <Tooltip />
                                              <Line type="monotone" dataKey="Positif" stroke="#00934c" fill="#00934c" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                              <Line type="monotone" dataKey="Neutre" stroke="#e89900"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                              <Line type="monotone" dataKey="Negatif" stroke="#e40001"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                                </LineChart>*/}
                                              
                                              <BarChart width={500} height={300} 
                                              margin={{
                                                top: 0,
                                                right: 60,
                                                left: 20,
                                                bottom: 5
                                              }}
                                              data={Object.values(this.state.speaker1)}
                                            >
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="starttime" />
                                              <YAxis domain={[0, 100]}/>
                                              <Tooltip />
                                              <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                              <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                              <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                            </BarChart>

                                          </div>
                                            
                                          <hr className="lineseparatorspeaker"></hr>

                                          {this.state.speaker2.length>0 ?

                                          <div>

                                            <p style={{color:'#2A83E8', textAlign:'center', fontWeight:'bold', fontSize:'1.5em',marginTop:'20px'}} >S2 : {this.state.speaker2name}</p>                       
                                        
                                            <div className='listesentiment'>
                                              <PieChart width={500} height={300}
                                                    margin={{
                                                      top: 0,
                                                      right: 0,
                                                      left: 0,
                                                      bottom: 20,
                                                    }}
                                                  >          
                                                <Pie  data={Object.values(this.state.speaker2sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                                  {data.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                    
                                                  ))}
                                                  <Label value="Speaker 2" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:'#2A83E8'}} fill="#2A83E8"/>
                                                  {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                                </Pie>
                                                <Tooltip/>
                                                {/*<Legend />*/}
                                              </PieChart>
                                            </div>

                                            <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S2 : Sentiment de l\'appel' : 'S2 : Call sentiment'}</p>

                                            <div className='listesentiment' style={{marginTop:"20px"}}> 

                                            <BarChart width={500} height={300} 
                                                margin={{
                                                  top: 0,
                                                  right: 60,
                                                  left: 20,
                                                  bottom: 5
                                                }}
                                                data={Object.values(this.state.speaker2)}
                                              >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="starttime" />
                                                <YAxis domain={[0, 100]}/>
                                                <Tooltip />
                                                <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                                <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                                <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                              </BarChart>

                                            </div>

                                          </div>

                                          :

                                          null

                                          }

                                          

                                          <div style={{height:'50px'}} >
                                              <p style={{color:'transparent'}}>.</p>
                                          </div>

                                      </div>

                                    </div>




                                  </div>
                                                                                                                                        
                                </div>

                            :

                            null

                            }

                            </div>

                            <div class="container">
                            <div class="row">
                              <div class="col-sm">
                                
                              </div>
                              <div class="col-sm">
                                <div className="audioplayermodal">
                                    <ReactAudioPlayer
                                      src={this.state.soundlink}
                                      controls
                                      volume
                                      
                                      />
                                </div>
                              </div>
                              <div class="col-sm">
                                
                              </div>
                            </div>
                            </div>
                        
                        
                        </BrowserView>

                        <MobileView>

                            <div style={{position:"fixed", right:'10px', top:0, zIndex: '1000', backgroundColor:"white"}}>

                            <div className="container" >
                              <div className="row">

                                <p style={{color:'#2A83E8', fontWeight:'bold', textAlign:"center"}}>{this.state.lng ==="FR" ? 'Relire ' : 'Replay '} {this.state.soundname}</p>
                                <div className="col-10 d-flex justify-content-center">

                                    {/* 
                                    <ReactAudioPlayer
                                      src={this.state.soundlink}
                                      controls
                                      volume 
                                      />
                                      */}

                                      <AudioPlayer
                                        src={this.state.soundlink}    
                                        customControlsSection={
                                          [
                                            RHAP_UI.MAIN_CONTROLS,
                                            RHAP_UI.VOLUME_CONTROLS,
                                          ]
                                        }                                                                  
                                      />

                                </div>

                                <div className="col-2 d-flex justify-content-center">
                                  <button className="btn btntransparent" style={{marginTop:"-5px"}} onClick={() => this.setState({isModaldetails:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '50px', height:'50px' }}/></span></button>
                                </div>

                              </div>

                            </div>

                            </div>

                            {/*<p style={{color:'#2A83E8', fontWeight:'bold', marginTop:'40px', textAlign:"center"}}>{this.state.soundname}</p>*/}
                            <p style={{color:'black',textAlign:"left",marginTop:'150px' }}>{this.state.lng ==="FR" ? 'Résumé: ' : 'Summarization: '}{this.state.summarization}</p>

                            <div className="row">

                                  <div className="col-sm" >

                                  <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Global call sentiment'}</p>

                                    <div className='sentimentglobal' style={{marginLeft:'5px',marginRight:'5px',marginTop:'0px', fontWeight:'bold'}}>

                                      <div className="champnegatifmodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakernegatif}%</p>

                                          </div>
                                          
                                        </div>

                                      </div>

                                      <div className="champneutremodal" style={{padding:'5px'}}>

                                        <div className="row">

                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerneutre}%</p>

                                          </div>
                                          
                                        </div>
                                        
                                      </div>

                                      <div className="champpositifmodal" style={{padding:'5px'}}>

                                        <div className="row">

                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerpositif}%</p>

                                          </div>
                                        
                                        </div>
                                      
                                      </div>

                                    </div>

                                  </div>

                                </div>

                                <p style={{color:'black', marginTop:'20px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}} >{this.state.speaker1name}</p>                           
                                          
                                <div className='listesentiment'>
                                  <PieChart width={350} height={300}
                                        margin={{
                                          top: 0,
                                          right: 0,
                                          left: 0,
                                          bottom: 20,
                                        }}
                                      >                                     
                                    <Pie  data={Object.values(this.state.speaker1sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                      {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                        
                                      ))}
                                      <Label value="Speaker 1" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em'}}/>
                                      {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                    </Pie>
                                    <Tooltip/>
                                    {/*<Legend />*/}
                                  </PieChart>
                                </div>

                                <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:'center',marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Sentiment de l\'appel' : 'Call sentiment'}</p>

                                <div className='listesentiment' style={{marginTop:"20px"}}> 

                                  {/*<LineChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                                      <CartesianGrid strokeDasharray="3 3" />
                                      <XAxis dataKey="starttime" />
                                    <YAxis domain={[0.00, 1.00]}/>
                                    <Tooltip />
                                    <Line type="monotone" dataKey="Positif" stroke="#00934c" fill="#00934c" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                    <Line type="monotone" dataKey="Neutre" stroke="#e89900"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                    <Line type="monotone" dataKey="Negatif" stroke="#e40001"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                      </LineChart>*/}
                                    
                                    <BarChart width={350} height={300} 
                                    margin={{
                                      top: 0,
                                      right: 60,
                                      left: 20,
                                      bottom: 5
                                    }}
                                    data={Object.values(this.state.speaker1)}
                                  >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="starttime" />
                                    <YAxis domain={[0, 100]}/>
                                    <Tooltip />
                                    <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                    <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                    <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                  </BarChart>

                                </div>
                                
                                {/* 
                                  <hr className="lineseparatorspeaker"></hr>
                                */}

                                {this.state.speaker2.length>0 ?

                                <div>

                                    <p style={{color:'#2A83E8', textAlign:'center', fontWeight:'bold', fontSize:'1.5em',marginTop:'40px'}} >{this.state.speaker2name}</p>                       
                                        
                                        <div className='listesentiment'>
                                          <PieChart width={350} height={300}
                                                margin={{
                                                  top: 0,
                                                  right: 0,
                                                  left: 0,
                                                  bottom: 20,
                                                }}
                                              >          
                                            <Pie  data={Object.values(this.state.speaker2sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                              {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                
                                              ))}
                                              <Label value="Speaker 2" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:'#2A83E8'}} fill="#2A83E8"/>
                                              {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                            </Pie>
                                            <Tooltip/>
                                            {/*<Legend />*/}
                                          </PieChart>
                                        </div>

                                        <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:'center',marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Sentiment de l\'appel' : 'Call sentiment'}</p>

                                        <div className='listesentiment' style={{marginTop:"20px"}}> 

                                        <BarChart width={350} height={300} 
                                            margin={{
                                              top: 0,
                                              right: 60,
                                              left: 20,
                                              bottom: 5
                                            }}
                                            data={Object.values(this.state.speaker2)}
                                          >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="starttime" />
                                            <YAxis domain={[0, 100]}/>
                                            <Tooltip />
                                            <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                          </BarChart>

                                        </div>

                                </div>
                                

                                :

                                null

                                }

                                {this.state.speaker1.length>0 ?

                                  <div>

                                      <div >
                                        <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em', textAlign:'center', marginTop:'20px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>
                                      </div>

                                      {this.state.speakers.map((row) => (

                                        <div key={row.iddiarization} style={{marginTop:'20px'}}>

                                            <div> {row.speaker === '1' ?
                                                                    
                                              <div  id={parseInt(row.iddiarization)}>

                                                  <div style={{textAlign:'center'}}>
                                                    <p >{row.starttime}</p>
                                                  </div>

                                                      <div className="container">

                                                          <div className="row">
                                                            
                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champnegatifsentimentmodalmobile">
                                                                {parseInt(parseFloat(row.negatif)*100)}%
                                                              </p>
                                                            </div>

                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champneutresentimentmodalmobile">
                                                                {parseInt(parseFloat(row.neutre)*100)}%
                                                              </p>
                                                            </div>

                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champpositifsentimentmodalmobile">
                                                                {parseInt(parseFloat(row.positif)*100)}%
                                                              </p>
                                                            </div>

                                                          </div>

                                                        </div>

                                                <div className="row">

                                                  <div className="col-11">
                                                    <div style={{width:'100%', textAlign:'left'}} className='textleft'>
                                                      <p style={{lineHeight:'20px', paddingRight:"30px"}}>{row.phrase}</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-1">
                                                    {row.sentimentspeaker ==='positive' ?

                                                    <div className="btnpositifmodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>
                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='neutral' ?

                                                    <div className="btnneutremodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='mixed' ?

                                                    <div className="btnmixed btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='negative' ?

                                                    <div className="btnnegatifmodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                  </div>

                                              </div>

                                              <hr className="lineseparator"></hr>                                               
                                                
                                            </div>

                                              :
                                                                    
                                            <div  id={parseInt(row.iddiarization)}>

                                              <div className="container">

                                                <div className="col-sm" style={{textAlign:'center'}}>
                                                  <p >{row.starttime}</p>
                                                </div>

                                                    <div className="container">

                                                        <div className="row">

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champnegatifsentimentmodalmobile">
                                                              {parseInt(parseFloat(row.negatif)*100)}%
                                                            </p>
                                                          </div>

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champneutresentimentmodalmobile">
                                                              {parseInt(parseFloat(row.neutre)*100)}%
                                                            </p>
                                                          </div>

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champpositifsentimentmodalmobile">
                                                              {parseInt(parseFloat(row.positif)*100)}%
                                                            </p>
                                                          </div>

                                                        </div>

                                                    </div>

                                              </div>

                                              <div className="row">

                                                                                            
                                                <div className="col-1">
                                                  {row.sentimentspeaker ==='positive' ?

                                                  <div className="btnpositifmodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>
                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='neutral' ?

                                                  <div className="btnneutremodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='mixed' ?

                                                  <div className="btnmixed btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='negative' ?

                                                  <div className="btnnegatifmodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }
                                                  
                                                </div>

                                                <div className="col-11">
                                                  <div style={{width:'100%', textAlign:'left'}} className='textright'>
                                                    <p style={{lineHeight:'20px', paddingLeft:"20px"}}>{row.phrase}</p>
                                                  </div>
                                                  
                                                </div>

                                            </div>    

                                            <hr className="lineseparator"></hr>                        
                                                                                                    
                                            </div>

                                          }
                                          
                                          </div>

                                          
                                        </div>

                                        ))}


                                  </div>

                                  :

                                  null

                                }







                        </MobileView>

                      

                        

                          


                    </Modal.Body>
                    </Modal>

                    </div>

                    :

                    null

                    }


                  {this.state.isModaldetails === true ?

                    <div> 

                    <Modal
                    fullscreen
                    show={this.state.isModaldetails}
                    onHide={ () => this.setState({isModaldetails:false})}
                    centered
                    animation={false}
                    className="modaldetails"
                    >
                     
                      <Modal.Body>

                        <div className='modaldetailsdata'>

                          {/*<p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.soundname}</p>*/}

                          <div style={{position:"fixed", right:'10px', top:0, zIndex: '1000'}}>
                            <button type="submit" className="btn btntransparent" onClick={() => this.setState({isModaldetails:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '50px', height:'50px' }}/></span></button>
                          </div>
                                                   
                              <div className="row">

                                <div className="col-sm thematiquevalue">
                                  <div className="row">
                                      <div style={{width:'18%'}}>
                                      <img src={logo} style={{width:'100px', height:'70px', marginTop:'20px'}}/>
                                      {/*<span style={{color:'white',textAlign:"center"}}><ForumIcon sx={{ width: '100%', height:'100%' }}/></span>*/}
                                      </div>

                                      <div style={{width:'82%'}} className="d-flex flex-column align-items-center ">
                                        <p style={{color:'white', fontWeight:'bold', marginTop:'10px'}}>{this.state.soundname}</p>
                                        {/*<p style={{color:'white', fontWeight:'bold', fontSize:'1em',textAlign:"left", marginTop:'10px', textDecoration:'underline'}}>{this.state.lng ==="FR" ? 'Thématique' : 'Thematique'}</p>*/}
                                        {/*<hr className="lineseparator"></hr>*/}
                                        <p style={{color:'white',textAlign:"left"}}>{this.state.lng ==="FR" ? 'Thématique: ' : 'Thematique: '}{this.state.thematique}</p>
                                      </div>

                                  </div>
                                  
                                </div>
                                
                                {/*<div className="col-sm">
                                  
                                  <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Audio player' : 'Audio player'}</p>
      
                                      

                                      <ReactToPrint
                                        trigger={() => (
                                          <a className="ant-btn ant-btn-primary" href="#">
                                            {this.state.lng ==='FR'? 'Imprimer': 'Print'}
                                          </a>
                                        )}
                                        content={() => this.componentRef}
                                        />
                                                              
                                </div>*/}

                                <div className="col-sm" >
      
                                <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Global call sentiment'}</p>
      
                                  <div className='sentimentglobal' style={{marginLeft:'5px',marginRight:'5px',marginTop:'0px', fontWeight:'bold'}}>
      
                                    <div className="champnegatifmodal" style={{padding:'5px'}}>

                                      <div className="row">
                                        <div className="col">
                                          
                                          <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                                          <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakernegatif}%</p>

                                        </div>
                                        <div className="col">
                                          <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                        </div>
                                      </div>
                                    
                                        
                                    
                                    </div>
      
                                    <div className="champneutremodal" style={{padding:'5px'}}>

                                      <div className="row">
                                        <div className="col">
                                          
                                          <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                                          <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerneutre}%</p>

                                        </div>
                                        <div className="col">
                                          <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                        </div>
                                      </div>
                                      
                                    </div>
      
                                    <div className="champpositifmodal" style={{padding:'5px'}}>

                                      <div className="row">
                                        <div className="col">
                                          
                                          <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                                          <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerpositif}%</p>

                                        </div>
                                        <div className="col">
                                          <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                        </div>
                                      </div>
                                     
                                    </div>
      
                                  </div>
      
                                </div>

                              </div>
      
                            {this.state.speaker1.length>0 ?

                              <div className='modaldatasentiment'>
      
                                <div className="row" >

                                  <div className="col-12 col-md-8">
                                      
                                    
      
                                      <div className='reponsediarization' ref={el => (this.componentRef = el)} >

                                      <div >
                                        <p style={{color:'black', fontWeight:'bold', fontSize:'1.2em', textAlign:'center', marginTop:'0px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>
                                      </div>

                                          {this.state.speakers.map((row) => (

                                                    <div key={row.iddiarization} style={{marginTop:'20px'}}>

                                                        <div> {row.speaker === '1' ?
                                                                                
                                                          <div  id={parseInt(row.iddiarization)}>

                                                            <div className="container">

                                                              <div className="row" >

                                                                <div className="col-sm">

                                                                    <div class="container">
                                                                      <div class="row">
                                                                        <div class="col">
                                                                          <p className="champnegatifsentimentmodal">
                                                                            {parseInt(parseFloat(row.negatif)*100)}%
                                                                          </p>
                                                                        </div>
                                                                        <div class="col">
                                                                          <p className="champneutresentimentmodal">
                                                                            {parseInt(parseFloat(row.neutre)*100)}%
                                                                          </p>
                                                                        </div>
                                                                        <div class="col">
                                                                          <p className="champpositifsentimentmodal">
                                                                            {parseInt(parseFloat(row.positif)*100)}%
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                    </div>

                                                                </div>
                                                                
                                                                <div className="col-sm" style={{textAlign:'center'}}>
                                                                  <p >{row.starttime}</p>
                                                                </div>

                                                                <div className="col-sm">
                                                                  
                                                                </div>

                                                              </div>

                                                            </div>

                                                            <div className="row">

                                                              <div className="col-8">
                                                                <div style={{width:'100%', textAlign:'left', marginLeft:'20px'}} className='textleft'>
                                                                  <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                                </div>
                                                              </div>

                                                              <div className="col-1">
                                                                {row.sentimentspeaker ==='positive' ?

                                                                <div className="btnpositifmodal btn-sentiment leftunsaid">
                                                                  <div className="centerunsaid">S1</div>
                                                                </div>
                                                                :

                                                                null

                                                                }

                                                                {row.sentimentspeaker ==='neutral' ?

                                                                <div className="btnneutremodal btn-sentiment leftunsaid">
                                                                  <div className="centerunsaid">S1</div>
                                                                </div>

                                                                :

                                                                null

                                                                }

                                                                {row.sentimentspeaker ==='mixed' ?

                                                                <div className="btnmixed btn-sentiment leftunsaid">
                                                                  <div className="centerunsaid">S1</div>
                                                                </div>

                                                                :

                                                                null

                                                                }

                                                                {row.sentimentspeaker ==='negative' ?

                                                                <div className="btnnegatifmodal btn-sentiment leftunsaid">
                                                                  <div className="centerunsaid">S1</div>
                                                                </div>

                                                                :

                                                                null

                                                                }

                                                              </div>

                                                          </div>

                                                          <hr className="lineseparator"></hr>                                               
                                                            
                                                        </div>

                                                          :
                                                                                
                                                        <div  id={parseInt(row.iddiarization)}>

                                                          <div className="container">

                                                            <div className="row">

                                                              <div className="col-sm">

                                                              </div>
                                                              
                                                              <div className="col-sm" style={{textAlign:'center'}}>
                                                                <p >{row.starttime}</p>
                                                              </div>

                                                              <div className="col-sm">
                                                                <div class="container">
                                                                    <div class="row">
                                                                      <div class="col">
                                                                        <p className="champnegatifsentimentmodal">
                                                                          {parseInt(parseFloat(row.negatif)*100)}%
                                                                        </p>
                                                                      </div>
                                                                      <div class="col">
                                                                        <p className="champneutresentimentmodal">
                                                                          {parseInt(parseFloat(row.neutre)*100)}%
                                                                        </p>
                                                                      </div>
                                                                      <div class="col">
                                                                        <p className="champpositifsentimentmodal">
                                                                          {parseInt(parseFloat(row.positif)*100)}%
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                              </div>

                                                            </div>

                                                          </div>

                                                          <div className="row">

                                                          <div className="col-2">
                                                              <div style={{width:'100%', textAlign:'center'}}>
                                                                <p style={{lineHeight:'20px', color:'transparent'}}>.</p>
                                                              </div>
                                                              
                                                            </div>
                                                            
                                                            <div className="col-1">
                                                              {row.sentimentspeaker ==='positive' ?

                                                              <div className="btnpositifmodal btn-sentiment rightunsaid">
                                                                <div className="centerunsaid">S2</div>
                                                              </div>
                                                              :

                                                              null

                                                              }

                                                              {row.sentimentspeaker ==='neutral' ?

                                                              <div className="btnneutremodal btn-sentiment rightunsaid">
                                                                <div className="centerunsaid">S2</div>
                                                              </div>

                                                              :

                                                              null

                                                              }

                                                              {row.sentimentspeaker ==='mixed' ?

                                                              <div className="btnmixed btn-sentiment rightunsaid">
                                                                <div className="centerunsaid">S2</div>
                                                              </div>

                                                              :

                                                              null

                                                              }

                                                              {row.sentimentspeaker ==='negative' ?

                                                              <div className="btnnegatifmodal btn-sentiment rightunsaid">
                                                                <div className="centerunsaid">S2</div>
                                                              </div>

                                                              :

                                                              null

                                                              }
                                                              
                                                            </div>

                                                            <div className="col-8">
                                                              <div style={{width:'100%', textAlign:'center'}} className='textright'>
                                                                <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                              </div>
                                                              
                                                            </div>

                                                        </div>    

                                                        <hr className="lineseparator"></hr>                        
                                                                                                                
                                                        </div>
                         
                                                      }
                                                      
                                                      </div>

                                                      
                                                    </div>
     
                                          ))}

                                        <div style={{height:'50px'}} id="lastline">
                                            <p style={{color:'transparent'}}>.</p>
                                        </div>

                                        </div>
                                  </div>



                                  <div className="col-6 col-md-4">

                                    <div className='sentimentchart' style={{marginTop:'0px'}} id="chart">

                                      <p style={{color:'black', marginTop:'0px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}} >S1 : {this.state.speaker1name}</p>                           
                                        
                                        <div className='listesentiment'>
                                          <PieChart width={500} height={200}
                                                margin={{
                                                  top: 0,
                                                  right: 0,
                                                  left: 0,
                                                  bottom: 20,
                                                }}
                                              >                                     
                                            <Pie  data={Object.values(this.state.speaker1sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                              {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                
                                              ))}
                                              <Label value="Speaker 1" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em'}}/>
                                              {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                            </Pie>
                                            <Tooltip/>
                                            {/*<Legend />*/}
                                          </PieChart>
                                        </div>
    
                                        <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S1 : Sentiment de l\'appel' : 'S1 : Call sentiment'}</p>
    
                                        <div className='listesentiment' style={{marginTop:"20px"}}> 

                                          {/*<LineChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="starttime" />
                                            <YAxis domain={[0.00, 1.00]}/>
                                            <Tooltip />
                                            <Line type="monotone" dataKey="Positif" stroke="#00934c" fill="#00934c" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                            <Line type="monotone" dataKey="Neutre" stroke="#e89900"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                            <Line type="monotone" dataKey="Negatif" stroke="#e40001"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                              </LineChart>*/}
                                            
                                            <BarChart width={500} height={300} 
                                            margin={{
                                              top: 0,
                                              right: 60,
                                              left: 20,
                                              bottom: 5
                                            }}
                                            data={Object.values(this.state.speaker1)}
                                          >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="starttime" />
                                            <YAxis domain={[0, 100]}/>
                                            <Tooltip />
                                            <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                          </BarChart>

                                        </div>
                                          
                                        <hr className="lineseparatorspeaker"></hr>

                                        <p style={{color:'#2A83E8', textAlign:'center', fontWeight:'bold', fontSize:'1.5em',marginTop:'20px'}} >S2 : {this.state.speaker2name}</p>                       
                                      
                                        <div className='listesentiment'>
                                          <PieChart width={500} height={200}
                                                margin={{
                                                  top: 0,
                                                  right: 0,
                                                  left: 0,
                                                  bottom: 20,
                                                }}
                                              >          
                                            <Pie  data={Object.values(this.state.speaker2sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                              {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                
                                              ))}
                                              <Label value="Speaker 2" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:'#2A83E8'}} fill="#2A83E8"/>
                                              {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                            </Pie>
                                            <Tooltip/>
                                            {/*<Legend />*/}
                                          </PieChart>
                                        </div>
      
                                        <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S2 : Sentiment de l\'appel' : 'S2 : Call sentiment'}</p>
    
                                        <div className='listesentiment' style={{marginTop:"20px"}}> 

                                        <BarChart width={500} height={300} 
                                            margin={{
                                              top: 0,
                                              right: 60,
                                              left: 20,
                                              bottom: 5
                                            }}
                                            data={Object.values(this.state.speaker2)}
                                          >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="starttime" />
                                            <YAxis domain={[0, 100]}/>
                                            <Tooltip />
                                            <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                          </BarChart>

                                        </div>

                                        <div style={{height:'50px'}} >
                                            <p style={{color:'transparent'}}>.</p>
                                        </div>

                                    </div>

                                  </div>




                                </div>
                                                                                                                                      
                              </div>

                        :

                        null

                        }


                                      
                                      
          
                            

                        </div>

                        <div class="container">
                          <div class="row">
                            <div class="col-sm">
                              
                            </div>
                            <div class="col-sm">
                              <div className="audioplayermodal">
                                  <ReactAudioPlayer
                                    src={this.state.soundlink}
                                    controls
                                    volume
                                    
                                    />
                              </div>
                            </div>
                            <div class="col-sm">
                              
                            </div>
                          </div>
                        </div>

                        

                          


                    </Modal.Body>
                    </Modal>

                    </div>

                    :

                    null

                  }

                  {this.state.isModalupdate === true ?

                  <Modal

                  show={this.state.isModalupdate}
                  onHide={ () => this.setState({isModalupdate:false,thematique :'', idaudioselect :0 , speaker1: '', speaker2: '',soundlink: '', soundname : '',})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row">
                                      
                        <div className="d-flex flex-column" >

                            <div className='d-flex flex-column align-items-center'>
                                                
                            <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.soundname}</p>
                                
                                <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>
                                        
                                  <ReactAudioPlayer
                                  src={this.state.soundlink}
                                  controls
                                  volume
                                  className="audioplayermodal"
                                  />

                                </p>

                            </div>

                            <div className="d-flex flex-column align-items-center" style={{marginTop:'50px'}}>
                                <p style={{fontWeight:"bold", textAlign:"center"}}>{this.state.lng ==="FR" ? 'Thématique :' : 'Thematique:'}&nbsp;</p>
                                <form>
                                <label>
                                  <textarea type="textarea" name="thematique" rows={3} style={{width:'400px'}} value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>
                                </label>

                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Speaker :&nbsp;</p>
                                
                                
                                <p style={{fontWeight:"bold"}}>Speaker 1  :&nbsp;</p>
                                <input  style={{width:'400px'}} type="text" placeholder="Speaker 1" ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                                <p style={{fontWeight:"bold"}}>Speaker 2  :&nbsp;</p>
                                <input  style={{width:'400px'}} type="text" placeholder="Speaker 2" ref="speaker2" value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>
                                
                                <div className='row text-white d-flex align-items-center' style={{marginLeft:'20px'}}>
                                                
                                    <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({isModalupdate:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                    <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatesound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                                </div>
                                
                              </form>

                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.isModaladd === true ?

                  <Modal

                  show={this.state.isModaladd}
                  onHide={ () => this.setState({isModaladd:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                      
                        <div className="d-flex flex-column align-items-center" style={{margin:"20px"}}>

                            <label htmlFor="filePicker" style={{background:"#2A83E8", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}> {this.state.lng ==='FR'? 'Choisir fichier': 'Choose file'} </label>
                            <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept=".wav"/>

                            {this.state.soundfilename === null ?

                                <div >
                                    {this.state.lng ==='FR'? 'Aucun fichier selectionné': 'No file chosen'}                     
                                </div>

                              :

                                this.state.soundfilename

                            }

                            <p>{this.state.lng ==='FR'? 'Langue de l\'audio': 'Audio language'}  :</p>
                            <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                              <Form.Select style={{height:'40px', width:'200px' }} value={this.state.soundlng} onChange={this.selectlng}>
                                {this.state.listlng.map((lng) => (
                                  <option value={lng.lng}>{this.state.lng ==='FR'? lng.languefr: lng.langueen}</option>
                                ))}
                              </Form.Select>
                            </div>

                            <div style={{margin:'10px'}}>

                            {this.state.soundlng !== '' ?

                              <button onClick={this.onFileUpload} style={{marginTop:'70px'}} className="btn btn-primary"> 
                                {this.state.lng ==='FR'? 'TELEGARGER DANS LE CLOUD ': 'UPLOADED TO THE CLOUD'} 
                              </button>

                              :

                              null

                            }
                              
                            </div>                            
                            
                        </div>

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.isModalthematique === true ?

                      <Modal
                      
                      show={this.state.isModalthematique}
                      onHide={ () => this.setState({isModalthematique:false})}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >
                        <Modal.Body>
                                    
                        <div className="d-flex flex-row">
                                          
                            <div className="d-flex flex-column" style={{margin:"20px"}}>
                                
                                <div className="d-flex flex-column">
                                    <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Thématique :' : 'Thematique'}&nbsp;</p>
                                    <form>
                                    <label>
                                      <textarea type="textarea" name="thematique" rows={5} style={{width:'400px'}} value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>
                                    </label>
                                    
                                    <div className='row text-white d-flex align-items-center'>
                                                
                                        <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({isModalthematique:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.themeatiquesound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                                    </div>
                                    
                                  </form>
                                </div>
                                
                            </div>

                        </div>    

                      </Modal.Body>
                      </Modal>

                      :

                      null

                  }

                  {this.state.isModalspeaker === true ?

                  <Modal
                  
                  show={this.state.isModalspeaker}
                  onHide={ () => this.setState({isModalspeaker:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>SPEAKER :&nbsp;</p>
                                <form>
                                
                                <p style={{fontWeight:"bold"}}>Speaker 1  :&nbsp;</p>
                                <input  type="text" placeholder="Speaker 1" ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                                <p>&nbsp;</p>
                                <p style={{fontWeight:"bold"}}>Speaker 2  :&nbsp;</p>
                                <input  type="text" placeholder="Speaker 2" ref="speaker2" value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>
                                
                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({isModalspeaker:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.speakermodalsound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

               
                
                </div>
          );
        }
    
}

export default Globalsentimentville;


import React,  {Alert,Component} from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { isExpired, decodeToken }from "react-jwt";
import { Button,Form,Modal} from 'react-bootstrap';
import Loader from "react-js-loader";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

class Termsofservices extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      lng:'',
      serviceprovision:true,
      acceptexperience:false,
      textacceptexperience:"",
      acceptmarketing:false,
      textacceptmarketing:"",
      acceptshare:false,
      textacceptshare:"",
      acceptupload:true,
      loadingdata:true,
      };

        
  }
    
    componentDidMount() {
        
    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let token = sessionStorage.getItem('tokenunsaid');

    if(token === '' || token === 'Undefined' || token === null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        lng:'',
      })
      return;
    }

    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    if(lnguser == "FR"){

      document.title = "Conditions générales de services";

    }else{

      document.title = "Terms of services";

    }

      this.setState({
        loadingdata:false
      })

    window.scrollTo(0,0)
        
  };
    
  render() {

          return (
              
                <div style={{margin:"2%",marginTop:"80px"}} >

                {this.state.loadingdata === true ?
                      
                  <div className='centercontent'>

                    <Loader type="spinner-cub" bgColor={'#2A83E8'} color={'#2A83E8'} size={100} />

                  </div>
                  

                :

                  <div className="rgpdborder">  

                    <p style={{color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "1. Objet":"1. Purpose"}</p>
                    <p style={{color:"black",textAlign:"left"}}>{this.state.lng=== "FR"? "Ces Conditions Générales de Service définissent les conditions applicables à la souscription et à l'utilisation des services SaaS U-Note et U-Feel fournis par Unsaid. Toute commande passée auprès d'Unsaid implique l'acceptation sans réserve des présentes Conditions Générales de Service.":"These Terms of Service define the conditions applicable to the subscription and use of the SaaS services U-Note and U-Feel provided by Unsaid. Any order placed with Unsaid implies acceptance without reservation of these Terms of Service."}</p>
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "2. Services proposés":"2. Services Offered"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid propose deux principaux services SaaS :":"Unsaid offers two main SaaS services:"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• U-Note : Génération automatique de rapports à partir des enregistrements d'appels audio ou vidéo.":"• U-Note: Automatic report generation from audio or video call recordings."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• U-Feel : Analyse des sentiments des conversations et création de rapports de données pour une meilleure gestion des performances et de la satisfaction client.":"• U-Feel: Sentiment analysis of conversations and creation of data reports for better performance and customer satisfaction management."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}> {this.state.lng=== "FR"? "Des services de conseil et de développement informatique sont également disponibles sur demande.":"  Consulting and IT development services are also available upon request."}</p>
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "3. Tarification":"3. Pricing"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les prix des services SaaS sont indiqués sur le site internet ou dans des devis personnalisés fournis sur demande. Unsaid se réserve le droit de modifier ses tarifs à tout moment. Les clients seront informés à l'avance de toute modification tarifaire.":"The prices of SaaS services are listed on the website or in customized quotes provided upon request. Unsaid reserves the right to change its prices at any time. Customers will be informed in advance of any price changes."}</p>          
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "4. Modalités de paiement":"4. Payment Terms"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Le paiement des services s'effectue selon les modalités indiquées dans le devis ou sur la plateforme SaaS. En général, le paiement se fait par carte bancaire ou virement bancaire lors de la commande, sauf indication contraire. Tout retard de paiement entraînera l'application de pénalités de retard.":"Payment for services is made according to the terms indicated in the quote or on the SaaS platform. Generally, payment is made by credit card or bank transfer upon order, unless otherwise stated. Any delay in payment will result in the application of late fees."}</p>          
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "5. Livraison des services":"5. Delivery of Services"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les services SaaS sont accessibles dès que la commande est confirmée et le paiement effectué. Les rapports générés par U-Note et U-Feel sont disponibles dans les délais spécifiés lors de la souscription, généralement en temps réel ou dans les heures suivant l'enregistrement des données.":"SaaS services are accessible as soon as the order is confirmed and payment is made. Reports generated by U-Note and U-Feel are available within the time frames specified at subscription, typically in real-time or within hours after data recording."}</p>          
                 
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "6. Utilisation de l'Intelligence Artificielle (IA)":"6. Use of Artificial Intelligence (AI)"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les services U-Note et U-Feel reposent sur des technologies d'intelligence artificielle développées à la fois en interne par Unsaid et par des partenaires tiers. Les données des utilisateurs peuvent être traitées sur les serveurs internes d'Unsaid ou via des solutions partenaires, garantissant des niveaux élevés de précision et de performance.":"The U-Note and U-Feel services rely on artificial intelligence technologies developed both internally by Unsaid and by third-party partners. User data may be processed on Unsaid's internal servers or via partner solutions, ensuring high levels of accuracy and performance."}</p>             
                   
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "7. Protection des données":"7. Data Protection"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid s'engage à respecter les dispositions légales en matière de protection des données personnelles. Les données traitées par les services SaaS sont protégées et gérées conformément aux dispositions du RGPD. Pour toute question relative à la gestion des données, vous pouvez consulter notre Politique de Confidentialité ou contacter notre équipe à l'adresse : ":"Unsaid is committed to complying with legal provisions regarding personal data protection. Data processed by SaaS services are protected and handled in accordance with GDPR provisions. For any questions related to data management, you can consult our Privacy Policy or contact our team at:"} <a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:contact@unsaid.fr"}>contact@unsaid.fr</a></p>             
                  
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "8. Résiliation":"8. Termination"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les abonnements aux services SaaS peuvent être résiliés par l'utilisateur ou par Unsaid selon les conditions prévues dans le contrat d'abonnement. En cas de non-respect des présentes Conditions Générales de Service par le client, Unsaid se réserve le droit de suspendre ou de résilier immédiatement l'accès aux services.":"Subscriptions to SaaS services can be terminated by the user or by Unsaid under the conditions set out in the subscription contract. In the event of non-compliance with these Terms of Service by the customer, Unsaid reserves the right to suspend or terminate access to services immediately."}</p>             
               
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "9. Responsabilité":"9. Liability"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid ne peut être tenu responsable des dommages indirects liés à l'utilisation de ses services, tels que la perte de données ou les interruptions de service. La responsabilité d'Unsaid est limitée aux montants perçus pour le service en question.":"Unsaid cannot be held liable for indirect damages related to the use of its services, such as data loss or service interruptions. Unsaid's liability is limited to the amounts received for the disputed service."}</p>             

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "10. Droit applicable et juridiction":"10. Applicable Law and Jurisdiction"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les présentes Conditions Générales de Service sont régies par le droit français. Tout litige relatif à l'interprétation ou à l'exécution des présentes Conditions Générales de Service sera soumis à la compétence exclusive des tribunaux de Paris (France).":"These Terms of Service are governed by French law. Any dispute related to the interpretation or execution of these Terms of Service will be subject to the exclusive jurisdiction of the Paris courts(France)."}</p>             
                    
                  </div>

                }
                    
     
                </div>
          );
        }
    
}

export default Termsofservices;

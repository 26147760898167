import './moncss.css';
import React,  {Component} from 'react';
import { Button} from 'react-bootstrap';
import { isBrowser} from "react-device-detect";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

let newWindow = "";

class Sucess extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      nom:'',
      prenoms:'',
      mail:'',
      idtype:0,
      typeuser:'',
      iduser : 0,
      tariftype: '',
      montant:0.00,
      lng:'',
      datapaiement:props.match.params.datapaiement,
    };
        
  }
    
    componentDidMount() {

      var bytes  = CryptoJS.AES.decrypt(this.state.datapaiement.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
      var originaldata = bytes.toString(CryptoJS.enc.Utf8);
      var userdata  = originaldata.split('-');

      this.setState({
        lng:userdata[0],
        montant:userdata[1],
      })  

      if(userdata[0]== 'FR'){

        document.title = "Paiement";
    
      }else{
    
        document.title = "Payment";
    
      }
 

    };

    componentWillMount() {
      
              
    };

    validation(){

      window.open("https://unsaidapps.com/Buycredit", "_self");
      window.close();
    
    }
   
    
  render() {

          return (

              <div style={{paddingTop:'100px'}} className="datacenter">

                <p style={{fontSize:isBrowser? '2rem' : '1.5rem'}}>{this.state.lng ==="FR" ? 'Votre paiement a été effectué avec succès.' : 'Your payment has been successfully processed.'}</p>
                <p style={{fontSize:isBrowser? '2rem' : '1.5rem'}}>{this.state.lng ==="FR" ? 'Votre crédit actuel est de ' : 'Your current crédit is '} {(parseFloat(this.state.montant).toFixed(2))} €.</p>
                <p style={{fontSize:isBrowser? '2rem' : '1.5rem'}}>{this.state.lng ==="FR" ? 'Veuillez rafraîchir la page sur la plate-forme. ' : 'Please refresh page on the platform.'}</p>
                <div className="success-checkmark">
                  <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                  </div>
                </div>
                <Button className="btn" style={{ marginTop:'10px', width:'150px'}} onClick={() => this.validation()} >Ok</Button>
              </div>
             
          );
        }
    
}

export default Sucess;

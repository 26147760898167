import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PaymentsIcon from '@mui/icons-material/Payments';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {isMobile,isBrowser} from "react-device-detect";
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import Card from 'react-bootstrap/Card';
import {  BarChart, Bar,LineChart, Line,Label, LabelList, PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from "recharts";
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");


const COLORS = ['#00934c', '#e89900', '#e40001'];

const COLORSCHART = ['#00934c', '#e89900', '#e40001'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

var datapie= [];
var datapieload= [];
var datalinechart= [];
var datalinechartload= [];

var errocode = 0;


class Purchasecredit extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        listjargon: [],
        modalupdate: false,
        modaldelete: false,
        idjargon:0,
        wordjargon:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        nbreuser:0,
        nbrecenter:0,
        nbreaudio:0,
        audionotdiarized:0,
        credit:0,
        startertime:0,
        starterprice:0.00,
        businesstime:0,
        businessprice:0.00,
        basictime:0,
        busicprice:0.00,
        enterprisetime:0,
        enterpriseprice:0.00,
        idtypeuser:0,
        mail:'',
        taxe:0.00,
        taxebasic:0.00,
        taxebusiness:0.00,
        taxebasicannually:0.00,
        taxebasicmonthly:0.00,
        taxebusinessannually:0.00,
        taxebusinessmonthly:0.00,
        ttcstarter:0.00,
        ttcbusiness:0.00,
        ttcbasic:0.00,
        ttcbusinessmonthly:0.00,
        ttcbasicmonthly:0.00,
        ttcbusinessannually:0.00,
        ttcbasicannually:0.00,
        htbusinessmonthly:0.00,
        htbasicmonthly:0.00,
        htbusinessannually:0.00,
        htbasicannually:0.00,
        basicoption:"annually",
        businessoption:"annually",
        subscriptionoption:"",
        priceht:0.00,
        pricetaxe:0.00,
        pricettc:0.00,
        modalvalidate:false,
        linkpaypal:"",
        modalenterprise:false,
        subjectmail:"",
        bodymail:"",
        mailsent:false,
        feepaypal:0.00,
        feetransactionpaypal:0.00,
        feeoutsidefrance:0.00,
        feeservice:0.00,
        totalpaypal:0.00,
      };

      this.selecttypebasic = this.selecttypebasic.bind(this);
      this.selecttypebusiness = this.selecttypebusiness.bind(this);

  }

  
  selecttypebasic(e) {
  this.setState({ basicoption: e.target.value });
}

selecttypebusiness(e) {
  this.setState({ businessoption: e.target.value });
}

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.init).replace(/\"/g, "");
    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    this.setState({
      idtypeuser: parseInt(userdata[1]),
      taxe:parseFloat(userdata[7])
    })
    
  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let typelangue = sessionStorage.getItem( 'langueuser');

    this.setState({ lng:typelangue})

    if(typelangue== 'FR'){

      document.title = "Acheter du crédit";

    }else{

      document.title = "Purchase credit";

    }

    let token = sessionStorage.getItem('tokenunsaid');
  
    const myDecodedToken = decodeToken(token);

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


    fetch(this.state.wbslink + `listpricing`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
    .then(data => {

      this.setState({

        feepaypal: parseFloat(JSON.stringify(data[0].feepaypal).replace(/\"/g, "")),
        feetransactionpaypal: parseFloat(JSON.stringify(data[0].feetransaction).replace(/\"/g, "")),
        feeoutsidefrance: parseFloat(JSON.stringify(data[0].feeoutsidefrance).replace(/\"/g, "")),
        htbusinessmonthly:parseFloat(JSON.stringify(data[0].businessmonthly).replace(/\"/g, "")),
        htbusinessannually:parseFloat(JSON.stringify(data[0].businessannually).replace(/\"/g, "")),
        htbasicmonthly:parseFloat(JSON.stringify(data[0].basicmonthly).replace(/\"/g, "")),
        htbasicannually:parseFloat(JSON.stringify(data[0].basicannualy).replace(/\"/g, "")),
      });

      var ttcstarteradd = (parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, ""))/100) * this.state.taxe
      var ttcbusinessadd = (parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, ""))/100) * this.state.taxe
      var ttcbasicadd = (parseFloat(JSON.stringify(data[0].basicprice).replace(/\"/g, ""))/100) * this.state.taxe


      var totalbasicannualy = ((parseFloat(JSON.stringify(data[0].basicannualy).replace(/\"/g, "")) *1.2) + 0.42)/0.9652;
      var totalbasicmonthly= ((parseFloat(JSON.stringify(data[0].basicmonthly).replace(/\"/g, "")) *1.2) + 0.42)/0.9652;
      var totalbusinessannually = ((parseFloat(JSON.stringify(data[0].businessannually).replace(/\"/g, "")) *1.2) + 0.42)/0.9652;
      var totalbusinessmonthly = ((parseFloat(JSON.stringify(data[0].businessmonthly).replace(/\"/g, "")) *1.2) + 0.42)/0.9652;

      var fraispaypalbasicannualy = (totalbasicannualy*0.029) + parseFloat(JSON.stringify(data[0].feetransaction).replace(/\"/g, ""))
      var fraispaypalbasicmonthly = (totalbasicmonthly*0.029) + parseFloat(JSON.stringify(data[0].feetransaction).replace(/\"/g, ""))
      var fraispaypalbusinessannuall = (totalbusinessannually*0.029) + parseFloat(JSON.stringify(data[0].feetransaction).replace(/\"/g, ""))
      var fraispaypalbusinessmonthly = (totalbusinessmonthly*0.029) + parseFloat(JSON.stringify(data[0].feetransaction).replace(/\"/g, ""))


      var tvabasicannualy = (parseFloat(JSON.stringify(data[0].basicannualy).replace(/\"/g, "")) + fraispaypalbasicannualy) * this.state.taxe
      var tvabasicmonthly = (parseFloat(JSON.stringify(data[0].basicmonthly).replace(/\"/g, "")) + fraispaypalbasicmonthly) * this.state.taxe
      var tvabusinessannually = (parseFloat(JSON.stringify(data[0].businessannually).replace(/\"/g, "")) + fraispaypalbusinessannuall) * this.state.taxe
      var tvabusinessmonthly = (parseFloat(JSON.stringify(data[0].businessmonthly).replace(/\"/g, "")) + fraispaypalbusinessmonthly) * this.state.taxe

      this.setState({
        startertime:parseInt(JSON.stringify(data[0].startertime).replace(/\"/g, "")),
        starterprice:parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        ttcstarter:ttcstarteradd + parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        businesstime:parseInt(JSON.stringify(data[0].businesstime).replace(/\"/g, "")),
        basicprice:parseFloat(JSON.stringify(data[0].basicprice).replace(/\"/g, "")),
        basictime:parseInt(JSON.stringify(data[0].basictime).replace(/\"/g, "")),
        businessprice:parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
        //ttcbusiness:ttcbusinessadd + parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
        //ttcbusinessmonthly: businessmonthly,
        //ttcbusinessannually: businessannually,
        //ttcbasic: ttcbasicadd + parseFloat(JSON.stringify(data[0].basicprice).replace(/\"/g, "")),
        //ttcbasicannually: basicannually,
        //ttcbasicmonthly: basicmonthly,
        //taxebasic:taxebasicannually,
        //taxebusiness:taxebasicannually,
        //htbusinessmonthly:businessmonthlyht,
       // htbasicmonthly:totalbasicmonthly,
        //htbusinessannually:businessannuallyht,
        //htbasicannually:totalbasicannualy,
        taxebasicannually:tvabasicannualy *12,
        taxebasicmonthly:tvabasicmonthly,
        taxebusinessannually:tvabusinessannually*12,
        taxebusinessmonthly:tvabusinessmonthly,
      });


      {/** 
      //BASIC 
      var percentbasic = parseFloat(JSON.stringify(data[0].basicannualy).replace(/\"/g, ""))/100
      var taxebasicannually  = percentbasic * this.state.taxe
      var basicannually  = (taxebasicannually + parseFloat(JSON.stringify(data[0].basicannualy).replace(/\"/g, ""))) *12
      var basicannuallyht  = (parseFloat(JSON.stringify(data[0].basicannualy).replace(/\"/g, ""))) *12

      var basicmonthly = parseFloat(JSON.stringify(data[0].basicmonthly).replace(/\"/g, ""))
      var taxebasicmonthly = (basicmonthly/100)*this.state.taxe
      var basicmonthlyht = basicmonthly
      var basicmonthly = basicmonthly + taxebasicmonthly
      


      //BUSINESS
      var percentbusiness = parseFloat(JSON.stringify(data[0].businessannually).replace(/\"/g, ""))/100
      var taxebusinessannually  = percentbusiness * this.state.taxe
      var businessannually  = (taxebusinessannually + parseFloat(JSON.stringify(data[0].businessannually).replace(/\"/g, ""))) *12
      var businessannuallyht  = (parseFloat(JSON.stringify(data[0].businessannually).replace(/\"/g, ""))) *12

      var businessmonthly = parseFloat(JSON.stringify(data[0].businessmonthly).replace(/\"/g, ""))
      var taxebusinessmonthly = (businessmonthly/100)*this.state.taxe
      var businessmonthlyht = businessmonthly
      var businessmonthly = businessmonthly + taxebusinessmonthly


      this.setState({
        startertime:parseInt(JSON.stringify(data[0].startertime).replace(/\"/g, "")),
        starterprice:parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        ttcstarter:ttcstarteradd + parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        businesstime:parseInt(JSON.stringify(data[0].businesstime).replace(/\"/g, "")),
        basicprice:parseFloat(JSON.stringify(data[0].basicprice).replace(/\"/g, "")),
        basictime:parseInt(JSON.stringify(data[0].basictime).replace(/\"/g, "")),
        businessprice:parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
        ttcbusiness:ttcbusinessadd + parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
        ttcbusinessmonthly: businessmonthly,
        ttcbusinessannually: businessannually,
        ttcbasic: ttcbasicadd + parseFloat(JSON.stringify(data[0].basicprice).replace(/\"/g, "")),
        ttcbasicannually: basicannually,
        ttcbasicmonthly: basicmonthly,
        taxebasic:taxebasicannually,
        taxebusiness:taxebasicannually,
        htbusinessmonthly:businessmonthlyht,
        htbasicmonthly:basicmonthlyht,
        htbusinessannually:businessannuallyht,
        htbasicannually:basicannuallyht,
        taxebasicannually:taxebasicannually *12,
        taxebasicmonthly:taxebasicmonthly,
        taxebusinessannually:taxebusinessannually*12,
        taxebusinessmonthly:taxebusinessmonthly,
      });

      */}

      if(this.state.lng== 'FR'){

        document.title = "Achat de crédit";
  
      }else{
  
        document.title = "Purchase credit";
  
      }

  })

  .catch(err => {
  
    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  


  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idjargon: 0,
    wordjargon: '',
    textloading:''
  });
  
}


async updatepricing(){

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("startertime",this.state.startertime);
    formData.append("starterprice",this.state.starterprice);
    formData.append("businesstime",this.state.businesstime);
    formData.append("businessprice",this.state.businessprice);

    fetch(this.state.wbslink + `updatepricing`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData
      
      })

      .then(res => {

        errocode = res.status;

        if (!res.ok) {
            
            return res.json().then(json => { throw json; });

        }

        return res.json();

      })
    .then(data => {

      this.setState({

        feepaypal: parseFloat(JSON.stringify(data[0].feepaypal).replace(/\"/g, "")),
        feetransactionpaypal: parseFloat(JSON.stringify(data[0].feetransaction).replace(/\"/g, "")),
        feeoutsidefrance: parseFloat(JSON.stringify(data[0].feeoutsidefrance).replace(/\"/g, "")),

      });

      

      {/*}
      //BASIC 
      var percentbasic = parseFloat(JSON.stringify(data[0].basicannualy).replace(/\"/g, ""))/100
      var taxebasic  = percentbasic * this.state.taxe
      var basicannually  = (taxebasic + parseFloat(JSON.stringify(data[0].basicannualy).replace(/\"/g, ""))) *12

      var basicmonthly = parseFloat(JSON.stringify(data[0].basicmonthly).replace(/\"/g, ""))
      var taxebasicmonthly = (basicmonthly/100)*this.state.taxe
      var basicmonthly = basicmonthly + taxebasicmonthly


      //BUSINESS
      var percentbusiness = parseFloat(JSON.stringify(data[0].businessannually	).replace(/\"/g, ""))/100
      var taxebusiness  = percentbusiness * this.state.taxe
      var businessannually  = (taxebusiness + parseFloat(JSON.stringify(data[0].businessannually	).replace(/\"/g, ""))) *12

      var businessmonthly = parseFloat(JSON.stringify(data[0].businessmonthly).replace(/\"/g, ""))
      var taxebusinessmonthly = (businessmonthly/100)*this.state.taxe
      var businessmonthly = businessmonthly + taxebusinessmonthly

      this.setState({
        startertime:parseInt(JSON.stringify(data[0].startertime).replace(/\"/g, "")),
        starterprice:parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        ttcstarter:ttcstarteradd + parseFloat(JSON.stringify(data[0].starterprice).replace(/\"/g, "")),
        businesstime:parseInt(JSON.stringify(data[0].businesstime).replace(/\"/g, "")),
        basicprice:parseFloat(JSON.stringify(data[0].basicprice).replace(/\"/g, "")),
        basictime:parseInt(JSON.stringify(data[0].basictime).replace(/\"/g, "")),
        businessprice:parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
        ttcbusiness:ttcbusinessadd + parseFloat(JSON.stringify(data[0].businessprice).replace(/\"/g, "")),
        ttcbusinessmonthly: businessmonthly,
        ttcbusinessannually: businessannually,
        ttcbasic: ttcbasicadd + parseFloat(JSON.stringify(data[0].basicprice).replace(/\"/g, "")),
        ttcbasicannually: basicannually,
        ttcbasicmonthly: basicmonthly,
        taxebasicannually:taxebasic *12,
        taxebasicmonthly:taxebasicmonthly,
        taxebusinessannually:taxebusiness*12,
        taxebusinessmonthly:taxebusinessmonthly,
      });

      */}

      if(this.state.lng== 'FR'){

        document.title = "Achat de crédit";
  
      }else{
  
        document.title = "Purchase credit";
  
      }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  



}

entrepriseabonnement (typeabonnement) {


}

typeabonnement (typeabonnement) {

  if(typeabonnement == 'enterprise'){

    if(this.state.mail.length ==0){

      if(this.state.lng === "FR"){
  
        alert("Veuillez saisir votre mail.");
        return;
  
      }else{
  
        alert("Please enter your email.");
        return;
  
      }  
  
    }
  
    if (typeof this.state.mail !== "undefined") {
  
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      
        if (!pattern.test(this.state.mail)) {
  
          if(this.state.lng === "FR"){
  
            alert("Veuillez saisir un mail valide s'il vous plait.");
            return;
      
          }else{
      
            alert("Please enter a valid email.");
            return;
      
          }  
      
        }
          
      }

  } 

  var feepaypal = 0.00;
  var feeoutsidefrance= 0.00;
  var totalfeepaypal = 0.00;
  var ttc = 0.00;
  var totalht = 0.00;
  var tva = 0.00;

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

  var subscription = "";

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("offer",typeabonnement);
  if(typeabonnement == "basic"){

    formData.append("subscriptionoption",this.state.basicoption);

    subscription = this.state.basicoption;

    if(this.state.basicoption == "annually"){
      
      {/** 
      this.setState({
        priceht:this.state.htbasicannually,
        pricettc:this.state.ttcbasicannually,
        pricetaxe:this.state.taxebasicannually,
      })
      */}

      if(parseFloat(this.state.taxe) == 0){

        var totalbasicannualy = ((this.state.htbasicannually + parseFloat(this.state.feetransactionpaypal))/0.9511)*12;
        var fraispaypalbasicannualy = (totalbasicannualy*0.029) + (totalbasicannualy*0.0199) + parseFloat(this.state.feetransactionpaypal) ;
        tva = 0.00;
        totalht = totalbasicannualy;

        var totalnet = parseFloat(fraispaypalbasicannualy) + parseFloat(tva) + (parseFloat(this.state.htbasicannually)*12);
              
        this.setState({
          priceht:this.state.htbasicannually*12,
          feeservice:fraispaypalbasicannualy.toFixed(2),
          pricetaxe:tva,
          totalpaypal:totalnet.toFixed(2),
        })

    
      }else{

        var totalbasicannualy = (((this.state.htbasicannually*1.20) + parseFloat(this.state.feetransactionpaypal)) /0.971)*12;
        var fraispaypalbasicannualy = (totalbasicannualy*0.029) + parseFloat(this.state.feetransactionpaypal);
        tva = (this.state.htbasicannually*12) * (this.state.taxe/100)
        totalht = totalbasicannualy;
      
        totalfeepaypal = feeoutsidefrance + fraispaypalbasicannualy;

        var totalnet = parseFloat(fraispaypalbasicannualy) + parseFloat(tva) + (parseFloat(this.state.htbasicannually)*12);
      
        this.setState({
          priceht:this.state.htbasicannually*12,
          feeservice:totalfeepaypal.toFixed(2),
          pricetaxe:tva,
          totalpaypal:totalnet.toFixed(2),
        })

      }

      

      if(this.state.lng === "FR"){
  
        subscription = "annuelle";
  
      }
      else{

        subscription = "annual basic offer";
        
      }  

    }else{

      {/** 
      this.setState({
        priceht:this.state.htbasicmonthly,
        pricettc:this.state.ttcbasicmonthly,
        pricetaxe:this.state.taxebasicmonthly,
      })

    */}
      
    if(parseFloat(this.state.taxe) == 0){

      var totalbasicmonthly = (this.state.htbasicmonthly + parseFloat(this.state.feetransactionpaypal))/0.9511;
      var fraispaypalbasicmonthly = (totalbasicmonthly*0.029) + (totalbasicmonthly*0.0199) + parseFloat(this.state.feetransactionpaypal);
      totalht = totalbasicmonthly.toFixed(2);
      
      var totalnet = parseFloat(fraispaypalbasicmonthly) + parseFloat(tva) + parseFloat(this.state.htbasicmonthly);

      this.setState({
        priceht:this.state.htbasicmonthly,
        feeservice:fraispaypalbasicmonthly.toFixed(2),
        pricetaxe:tva,
        totalpaypal:totalnet.toFixed(2),
      })


    }else{
      
      var totalbasicmonthly = ((this.state.htbasicmonthly *1.2) + 0.42)/0.9652;
      var fraispaypalbasicmonthly = (totalbasicmonthly*0.029) + parseFloat(this.state.feetransactionpaypal) - 0.01;
      tva = (this.state.htbasicmonthly) * (this.state.taxe/100)
      totalht = totalbasicmonthly;
      
      
      totalfeepaypal = feeoutsidefrance + fraispaypalbasicmonthly;
      
      var totalnet = parseFloat(totalfeepaypal) + parseFloat(tva) + parseFloat(this.state.htbasicmonthly);
      this.setState({
        priceht:this.state.htbasicmonthly,
        feeservice:fraispaypalbasicmonthly.toFixed(2),
        pricetaxe:tva,
        totalpaypal:totalnet.toFixed(2),
      })

    }

      if(this.state.lng === "FR"){
  
        subscription = "mensuel";
  
      }
      else{

        subscription = "monthly basic offer";
        
      }

    }  

  }

  if(typeabonnement == "business"){

    formData.append("subscriptionoption",this.state.businessoption);

    subscription = this.state.businessoption;

    if(this.state.businessoption == "annually"){
      
      {/** 
      this.setState({
        priceht:this.state.htbusinessannually,
        pricettc:this.state.ttcbusinessannually,
        pricetaxe:this.state.taxebusinessannually,
      })
      */}
      
      if(parseFloat(this.state.taxe) == 0){

        var totalbusinessannually = ((this.state.htbusinessannually + parseFloat(this.state.feetransactionpaypal))/0.9511)*12;
        var fraispaypalbusinessannually = (totalbusinessannually*0.029) + (totalbusinessannually*0.0199) + parseFloat(this.state.feetransactionpaypal);
        totalht = totalbusinessannually.toFixed(2);
        tva = 0.00;
        var totalnet = parseFloat(fraispaypalbusinessannually) + parseFloat(tva) + (parseFloat(this.state.htbusinessannually)*12);
      
        this.setState({
          priceht:this.state.htbusinessannually*12,
          feeservice:fraispaypalbusinessannually.toFixed(2),
          pricetaxe:tva,
          totalpaypal:totalnet.toFixed(2),
        })


      }else{

        var totalbusinessannually = (((this.state.htbusinessannually *1.2) + 0.35)/0.971)*12;
        var fraispaypalbusinessannually = (totalbusinessannually*0.029) + parseFloat(this.state.feetransactionpaypal);
        tva = (this.state.htbusinessannually*12) * (this.state.taxe/100)
        totalht = totalbasicmonthly;
        
        
        totalfeepaypal = feeoutsidefrance + fraispaypalbusinessannually;
        
        var totalnet = parseFloat(fraispaypalbusinessannually) + parseFloat(tva) + (parseFloat(this.state.htbusinessannually)*12);
      
        this.setState({
          priceht:this.state.htbusinessannually*12,
          feeservice:fraispaypalbusinessannually.toFixed(2),
          pricetaxe:tva,
          totalpaypal:totalnet.toFixed(2),
        })

      }

      if(this.state.lng === "FR"){
  
        subscription = "annuelle";
  
      }
      else{

        subscription = "annually business offer";
        
      }

    }else{

      {/** 
      this.setState({
        priceht:this.state.htbusinessmonthly,
        pricettc:this.state.ttcbusinessmonthly,
        pricetaxe:this.state.taxebusinessmonthly,
      })
      */}
      
      if(parseFloat(this.state.taxe) == 0){

        var totalbusinessmonthly = (this.state.htbusinessmonthly + parseFloat(this.state.feetransactionpaypal))/0.9511;
        var fraispaypalbusinessmonthly = (totalbusinessmonthly*0.029) + (totalbusinessmonthly*0.0199) + parseFloat(this.state.feetransactionpaypal);
        totalht = totalbusinessmonthly.toFixed(2);
        
        var totalnet = parseFloat(fraispaypalbusinessmonthly) + parseFloat(tva) + parseFloat(this.state.htbusinessmonthly);
      
        this.setState({
          priceht:this.state.htbusinessmonthly,
          feeservice:fraispaypalbusinessmonthly.toFixed(2),
          pricetaxe:tva,
          totalpaypal:totalnet.toFixed(2),
        })

      }else{

        var totalbusinessmonthly = ((this.state.htbusinessmonthly *1.2) + 0.42)/0.9652;
        var fraispaypalbusinessmonthly = (totalbusinessmonthly*0.029) + parseFloat(this.state.feetransactionpaypal) - 0.01;
        tva = (this.state.htbusinessmonthly) * (this.state.taxe/100)
        totalht = totalbusinessmonthly;
        
        
        totalfeepaypal = feeoutsidefrance + fraispaypalbusinessmonthly;
        
        var totalnet = parseFloat(totalfeepaypal) + parseFloat(tva) + parseFloat(this.state.htbusinessmonthly);
        
        this.setState({
          priceht:this.state.htbusinessmonthly,
          feeservice:totalfeepaypal.toFixed(2),
          pricetaxe:tva.toFixed(2),
          totalpaypal:totalnet.toFixed(2),
        })

      }
      

      if(this.state.lng === "FR"){
  
        subscription = "mensuelle";
  
      }else{

        subscription = "monthly business offer";

      }

    }  

  }


  
  //feepaypal =  (ttc/100)*this.state.feepaypal;

  {/** 
  if(parseFloat(this.state.taxe) == 0){

    feeoutsidefrance = (totalht/100)*this.state.feeoutsidefrance

  }

  totalfeepaypal = feeoutsidefrance + feepaypal;

  this.setState({
    feeservice:totalfeepaypal,
    totalpaypal:totalfeepaypal + tva + totalht,
  })

*/}

  this.setState({
    typeabonnement:typeabonnement,
    subscriptionoption:subscription,
    loadershow:true,
    modalvalidate:true,
    //loadershow: false,
  })

  //return;

  fetch(this.state.wbslink + `payment`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData
    
    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
  .then(data => {

    this.setState({
      loadershow: false,
      linkpaypal:JSON.stringify(data).replace(/\"/g, ""),
    })

    //window.open(JSON.stringify(data).replace(/\"/g, ""), '_blank');

    //document.location.href = JSON.stringify(data).replace(/\"/g, "");

  })

  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    if(this.state.lng === "FR"){
  
      alert("Erreur :" + errocode + ". Veuillez, s'il vous plait, réessayer plus tard.")

    }else{

      alert("Error :" + errocode + ". Please, try agin later.")

    }

    //alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

}


openpaypallink(){

  window.open(this.state.linkpaypal, '_blank');

  this.setState({
    loadershow: false,
    modalvalidate:false,
    linkpaypal:"",
  })

}

openmodalenterprise(){

  this.setState({
    modalenterprise:true,
    subjectmail:"",
    bodymail:"",
    loadershow:false,
  })

  return;


  var subjectmail = "";

  if(this.state.lng === "FR"){
  
    subjectmail = "Demande d'information pour l'\offre entreprise";

  }else{

    subjectmail = "Request information for enterprise offer";

  }

  var bodymail = "";
  
  if(this.state.lng === "FR"){
  
    bodymail = "Bonjour.%0D%0ANous vous demandons plus d'information pour l'\offre entreprise.";

  }else{

    bodymail = "Hello.%0D%0A We need more information about enterprise offer.%0D%0A";

  }

  window.open(`mailto:contact@unsaid.fr?subject=${subjectmail}&body=${bodymail}`, '_blank');

}

sendmailenterprise(){

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

  var subscription = "";

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("subjectmail",this.state.subjectmail);
  formData.append("bodymail",this.state.bodymail);

  this.setState({

    loadershow:true,

  })

  fetch(this.state.wbslink + `mailenterprise`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData
    
    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
  .then(data => {

    this.setState({
      subjectmail:"",
      bodymail:"",
      loadershow:false,
      mailsent:true,
    })

  })

  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

}
    
  render() {

          return (
              
            <div id="tarification">

            

            <Zoom>
              <p style={{fontWeight:"bold", fontSize:"30px", textAlign:"center", color:"#2A83E8", marginTop:"60px"}}>{this.state.lng === "FR"?  "Tarif": "Pricing"}</p>
            </Zoom>

            {this.state.modalvalidate == true?

              <Modal

              show={this.state.modalvalidate}
              onHide={ () => this.setState({modalvalidate:false,loadershow:false,linkpaypal:""})}
              dialogClassName="modal-dialog radiusmodal"
              aria-labelledby="example-custom-modal-styling-title" centered

              >
                <Modal.Body className='cardpayment'>

                <div className="d-flex justify-content-end">
                  <button  className="btn btntransparent" onClick={() => this.setState({modalvalidate:false,loadershow:false,linkpaypal:""})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
                </div>
                            
                <div >

                      {this.state.loadershow == true ?
                          
                          <div className='d-flex flex-column align-items-center justify-content-center'>

                            <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />

                            <p style={{ marginTop:"30px", textAlign:"center", color:'#2A83E8', fontWeight:"bold", fontSize:"24px"}}>{this.state.lng ==='FR'? 'Chargement ...': 'Loading ...'}</p>

                          </div> 

                          :

                          <div className="d-flex flex-column" style={{margin:isBrowser?"10px":"0px",fontSize:isBrowser?"20px":"18px"}}>
                        
                            <div className="d-flex flex-column">

                            <p style={{fontWeight:"bold", textAlign:"center"}}>{this.state.lng ==="FR" ? 'Vous avez choisi offre ' + this.state.typeabonnement : 'You have chosen '} {this.state.subscriptionoption}</p>
                          
                            {isBrowser?

                              <div>

                                    {parseFloat(this.state.taxe) == 0.00?

                                      <div>

                                        <div className="row" >
                                        <div className="col-sm-1">
                                            <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"10px"}}> </p>
                                          </div>
                                          <div className="col-sm-5">
                                            <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"10px"}}>{this.state.lng ==="FR" ? 'Prix:' : 'Price:'}</p>
                                          </div>
                                          <div className="col-sm-5">
                                            <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginTop:"10px"}}>{parseFloat(this.state.priceht).toFixed(2)} €</p>
                                          </div>
                                        </div>

                                        <div className="row" >
                                            <div className="col-sm-1">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}> </p>
                                            </div>
                                            <div className="col-sm-6">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}>{this.state.lng ==="FR" ? 'Frais de service:' : 'Service fee:'}</p>
                                            </div>
                                            <div className="col-sm-4">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginTop:"0px"}}>{parseFloat(this.state.feeservice).toFixed(2)} €</p>
                                            </div>
                                          </div>

                                          <div className="row" >
                                            <div className="col-sm-1">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}> </p>
                                            </div>
                                            <div className="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}>{this.state.lng ==="FR" ? 'Net à payer:' : 'Net price:'}</p>
                                            </div>
                                            <div className="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginTop:"0px"}}>{parseFloat(this.state.totalpaypal).toFixed(2)} €</p>
                                            </div>
                                          </div>

                                      </div>

                                      :

                                      <div>

                                          <div className="row" >
                                            <div className="col-sm-1">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"10px"}}> </p>
                                            </div>
                                            <div className="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"10px"}}>{this.state.lng ==="FR" ? 'Tarif HT: ' : 'Base price: '} </p>
                                            </div>
                                          
                                            <div className="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginTop:"10px"}}>{parseFloat(this.state.priceht).toFixed(2)} €</p>
                                            </div>
                                          </div>

                                          <div className="row" >
                                            <div className="col-sm-1">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}> </p>
                                            </div>
                                            <div class="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}>{this.state.lng ==="FR" ? 'TVA ' : 'VAT '} {parseFloat(this.state.taxe).toFixed(2)}%:</p>
                                            </div>
                                            <div className="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginTop:"0px",marginLeft:"10px"}}> {parseFloat(this.state.pricetaxe).toFixed(2)} €</p>
                                            </div>
                                          </div>

                                          <div className="row" >
                                            <div className="col-sm-1">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}> </p>
                                            </div>
                                            <div className="col-sm-6">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}>{this.state.lng ==="FR" ? 'Frais de service:' : 'Service fee:'}</p>
                                            </div>
                                            <div className="col-sm-4">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginTop:"0px"}}>{parseFloat(this.state.feeservice).toFixed(2)} €</p>
                                            </div>
                                          </div>

                                          {/** 
                                          <div className="row" >
                                            <div className="col-sm-1">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}> </p>
                                            </div>
                                            <div className="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}>{this.state.lng ==="FR" ? 'TTC:' : 'Total Price:'}</p>
                                            </div>
                                            <div className="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginTop:"0px"}}>{parseFloat(this.state.pricettc).toFixed(2)} €</p>
                                            </div>
                                          </div>
                                          */}

                                          <div className="row" >
                                            <div className="col-sm-1">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}> </p>
                                            </div>
                                            <div className="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginTop:"0px"}}>{this.state.lng ==="FR" ? 'Net à payer:' : 'Net price:'}</p>
                                            </div>
                                            <div className="col-sm-5">
                                              <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginTop:"0px"}}>{parseFloat(this.state.totalpaypal).toFixed(2)} €</p>
                                            </div>
                                          </div>

                                      </div>

                                    }
                               
                              </div>

                              :


                              <div>


                                  {parseFloat(this.state.taxe)== 0.00?
                                  
                                    <div>

                                      
                                      <div className="row">
                                      
                                        <div className="col-sm-6">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginLeft:"40px"}}>{this.state.lng ==="FR" ? 'Prix:' : 'Price:'}</p>
                                        </div>
                                        <div className="col-sm-4">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginRight:"20px",marginTop:"-40px"}}>{parseFloat(this.state.priceht).toFixed(2)} €</p>
                                        </div>
                                        
                                      </div>  

                                      <div className="row">
                                      
                                        <div className="col-sm-6">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginLeft:"40px"}}>{this.state.lng ==="FR" ? 'Frais de service:' : 'Service fee:'}</p>
                                        </div>
                                        <div className="col-sm-4">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginRight:"20px",marginTop:"-40px"}}>{parseFloat(this.state.feeservice).toFixed(2)} €</p>
                                        </div>
                                        
                                      </div>  

                                      <div className="row">
                                      
                                        <div className="col-sm-6">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginLeft:"40px"}}>{this.state.lng ==="FR" ? 'Net à payer:' : 'Net price:'}</p>
                                        </div>
                                        <div className="col-sm-4">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginRight:"20px",marginTop:"-40px"}}>{parseFloat(this.state.totalpaypal).toFixed(2)} €</p>
                                        </div>
                                        
                                      </div>                                      

                                    </div>
                                
                                    :

                                    <div>

                                      <div className="row">
                                   
                                        <div className="col-sm-6">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginLeft:"40px"}}>{this.state.lng ==="FR" ? 'Tarif HT: ' : 'Base prise: '}</p>
                                        </div>
                                        <div className="col-sm-4">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginRight:"20px",marginTop:"-40px"}}>{parseFloat(this.state.priceht).toFixed(2)} €</p>
                                        </div>
                                        
                                      </div>
                                  

                                      <div className="row">
                                      
                                        <div className="col-sm-6">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginLeft:"40px"}}>{this.state.lng ==="FR" ? 'TVA ' : 'VAT '} {parseFloat(this.state.taxe).toFixed(2)}%:</p>
                                        </div>
                                        <div className="col-sm-4">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginRight:"20px", marginTop:"-40px"}}>{parseFloat(this.state.pricetaxe).toFixed(2)} €</p>
                                        </div>
                                        
                                      </div>

                                      {/* 
                                      <div className="row">
                                      
                                        <div className="col-sm-6">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginLeft:"40px"}}>{this.state.lng ==="FR" ? 'TTC:' : 'Total Price:'}</p>
                                        </div>
                                        <div className="col-sm-4">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginRight:"20px",marginTop:"-40px"}}>{parseFloat(this.state.pricettc).toFixed(2)} €</p>
                                        </div>
                                        
                                      </div>
                                      */}

                                      <div className="row">
                                      
                                        <div className="col-sm-6">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginLeft:"40px"}}>{this.state.lng ==="FR" ? 'Frais de service:' : 'Service fee:'}</p>
                                        </div>
                                        <div className="col-sm-4">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginRight:"20px",marginTop:"-40px"}}>{parseFloat(this.state.feeservice).toFixed(2)} €</p>
                                        </div>
                                        
                                      </div>

                                      <div className="row">
                                      
                                        <div className="col-sm-6">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"left", marginLeft:"40px"}}>{this.state.lng ==="FR" ? 'Net à payer:' : 'Net price:'}</p>
                                        </div>
                                        <div className="col-sm-4">
                                        <p style={{ fontWeight:"bold",color:this.state.typeabonnement == "basic"?"#CD7F32":"#2A83E8",textAlign:"right", marginRight:"20px",marginTop:"-40px"}}>{parseFloat(this.state.totalpaypal).toFixed(2)} €</p>
                                        </div>
                                        
                                      </div>

                                    </div>

                                  }
                                                              
                              </div>
                            
                          
                            }
                                          
                            {this.state.linkpaypal != ""?

                              <div className="d-flex flex-row justify-content-center">
                                <Button className="boutonpay" style={{ margin:"10px",width:'150px'}} onClick={() => this.openpaypallink()} variant="primary">{this.state.lng ==="FR" ? 'Payer' : 'Pay'} &nbsp;&nbsp;&nbsp;<span ><PaymentsIcon /></span></Button>
                              </div>

                              
                              :

                              null

                            }   
                                
                            </div>
                            
                        </div>

                      }

                    
                    
                </div>    

              </Modal.Body>
              </Modal>
              
              :

              null

            }

            {this.state.modalenterprise == true?

            <Modal

            show={this.state.modalenterprise}
            onHide={ () => this.setState({modalenterprise:false,loadershow:false,subjectmail:"",bodymail:"",mailsent:false})}
            dialogClassName="modal-dialog radiusmodal"
            aria-labelledby="example-custom-modal-styling-title" centered

            >
              <Modal.Body className='cardpayment'>

              <div className="d-flex justify-content-end">
                <button  className="btn btntransparent" onClick={() => this.setState({modalenterprise:false,loadershow:false,subjectmail:"",bodymail:"",mailsent:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '30px', height:'30px' }}/></span></button>
              </div>
                          
              <div >

                 {this.state.mailsent == true?

                  <div className="align-items-center justify-content-center">

                    <p style={{fontSize:isBrowser? '18px' : '14px',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Votre de mande a éé bien envoyé.' : 'Your request has been successfully sent.'}</p>
                    <p style={{fontSize:isBrowser? '18px' : '14px',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Nous vous répondrions dans le plus bref délai.' : 'We will respond to you as soon as possible.'}</p>

                    <div className="success-checkmark">
                      <div className="check-icon">
                        <span className="icon-line line-tip"></span>
                        <span className="icon-line line-long"></span>
                        <div className="icon-circle"></div>
                        <div className="icon-fix"></div>
                      </div>
                    </div>

                    <div className="d-flex flex-row justify-content-center">
                      <Button className="boutonpay" style={{ margin:"10px",width:'150px'}} onClick={() => this.setState({ modalenterprise: false})} variant="primary">Ok</Button>
                    </div>

                  </div>

                    :

                  <div>

                    {this.state.loadershow == true ?
                        
                        <div className='d-flex flex-column align-items-center justify-content-center'>

                          <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />

                          <p style={{ marginTop:"50px", textAlign:"center", color:'#2A83E8', fontWeight:"bold", fontSize:"24px"}}>{this.state.lng ==='FR'? 'Chargement ...': 'Loading ...'}</p>

                        </div> 

                        :

                        <div className="d-flex flex-column" style={{margin:isBrowser?"20px":"0px",fontSize:isBrowser?"20px":"18px"}}>
                      
                          <div className="d-flex flex-column">

                          <div className="form-group" style={{margin:'5px'}}>
                            <label>{this.state.lng ==="FR" ? 'Objet' : 'Subject'}</label>
                            <input type="email" className="form-control" value={this.state.subjectmail} onChange={(e) => this.setState({ subjectmail: e.target.value})} />
                          </div>

                          <div className="form-group" style={{margin:'5px'}}>
                            <label>{this.state.lng ==="FR" ? 'Message' : 'Message'}</label>
                            <textarea rows={7} type="text" className="form-control" value={this.state.bodymail} onChange={(e) => this.setState({ bodymail: e.target.value})} />
                          </div>
                                        
                          {this.state.bodymail != ""?

                            <div className="d-flex flex-row justify-content-center">
                              <Button className="boutonpay" style={{ margin:"10px",width:'150px', marginTop:"10px"}} onClick={() => this.sendmailenterprise()} variant="primary">{this.state.lng ==="FR" ? 'Envoyer' : 'Send'} &nbsp;&nbsp;&nbsp;<span ><EmailIcon /></span></Button>
                            </div>
             
                            :

                            null

                          }   
                              
                          </div>
                          
                      </div>

                    }

                  </div>
               
                }   

              </div>    

            </Modal.Body>
            </Modal>

            :

            null

            }

            <div className='listsolution' style={{marginTop:"-20px"}}>


              <Card style={{ width: '19rem', height: isBrowser ? "700px" : "700px", borderRadius:'25px', margin:'5px' }} >                                               
                                                                                      
                <Card.Body className="cardlist">
                                        
                    <Card.Title style={{fontSize:"16px", lineHeight:"26px", textAlign:"left", marginLeft:"4px"}} >
                      <p style={{fontSize:"26px"}}>Free</p>
                      <p style={{fontSize:"26px"}}>First Month</p>
                    </Card.Title>

                    <div className="row" style={{marginTop:"20px"}}>
                        
                        <div>
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8",  position: "absolute"}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "60 minutes par mois": "60 minutes per month"}</p>
                        </div>
                    
                    </div>

                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "2 Interlocuteurs ": "2 speakers"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "15 minutes par conversation": "15 minutes per conversation"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "6 langues": "6 languages"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "(Français, Anglais, Allemand, Arabe, Portugais, Espagnol)": "(French, English, German, Arabic, Portuguese, Spanish)"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Envoyer des fichiers audio et vidéo (100 Mo maximum)": "Upload audio & Video files (100 Mbytes Max)"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px',position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Transcription complète et rapport automatique": "Full transcription & automatic report"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Éléments d'action et de décision de l'IA dans tous les rapports": "AI action & decision items in all reports"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Téléchargez le rapport en fichier DOC": "Download the report in DOC file"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "téléchargez les enregistrements audio des réunions": "Download audio metting recordings"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Mises à jour des balises (nom du fichier, nom d'interlocuteur, commentaires)": "Tag updates (file name, speakers, comments)"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Lecture du fichier audio": "Playback"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px',position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Profils d'équipes : Managers, utilisateurs": "Team profiles: Managers, users"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Stockage de fichiers audio dans notre plateforme en France": "Audio file Storage in our platform in France"}</p>
                        </div>
                    
                    </div>
                    
                  </Card.Body>

                </Card>   

                <Card style={{ width: '19rem', height: "600px", borderRadius:'25px', margin:'5px' , marginTop:isBrowser?"-94px":"20px"}} >                                               
                                                                                      
                <Card.Body className="cardlist">
                                        
                    <Card.Title style={{color:"#CD7F32",fontSize:"16px", lineHeight:"26px", textAlign:"left", marginLeft:"4px"}} >
                      <p style={{fontSize:"26px"}}>Basic </p>
                      <p style={{marginTop:"0px",fontSize:"26px"}}>{parseFloat(this.state.basicprice).toFixed(2)} € {parseFloat(this.state.taxe) == 0.00? "":"HT"} {this.state.lng === "FR"?  "/mois": "/month"} </p>
                      <p style={{marginTop:"-0px"}}>{this.state.lng === "FR"?  "TVA non incluse": "VAT not included"}   </p>
                      {/*<p style={{marginTop:"-20px"}}>{parseFloat(this.state.ttcbasic).toFixed(2)} {this.state.lng === "FR"?  "€ TTC/mois": "€ TTC/month"} </p>*/}
                      {/*<p style={{marginTop:"-20px"}}>{this.state.lng === "FR"?  "TVA ": "VAT "} {parseFloat(this.state.taxe).toFixed(2)}% : {parseFloat(this.state.taxebasic).toFixed(2)} €</p>*/}
                      <p style={{marginTop:"20px"}}>{this.state.lng === "FR"?  "Facturé annuellement": "Billed Annually"}</p>
                      <p style={{marginTop:"20px",fontSize:"22px"}}>{this.state.lng === "FR"?  "Économisez 51 % (Payez en seule fois)": "Saved 51% (Pay in one go)"}</p>
                      </Card.Title>

                    
                    <Card.Text style={{fontSize:"32px", color:"#ff4e00", textAlign:"left",margin:'10px'}}></Card.Text>

                    
                    <div className="row" style={{marginTop:"20px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute"}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949", fontWeight:"bold"}}>{this.state.lng === "FR"?  "Tout sur le Free et": "Everything on Free and"}</p>
                        </div>
                    
                    </div>

                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "600 minutes par mois": "600 minutes per month"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "8 Interlocuteurs ": "8 speakers"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "60 minutes par conversation": "60 minutes per conversation"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "En option : analyse de sentiments": "Optional: Sentiment analysis"}</p>
                        </div>
                    
                    </div>
                    <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                      <Form.Select style={{height:'40px', width:'250px', marginBottom:"40px" }} value={this.state.basicoption} onChange={this.selecttypebasic}>
                                                    
                        <option value="monthly">{this.state.lng ==='FR'? "Mensuelle": "Monthly"} {parseFloat(this.state.htbasicmonthly).toFixed(2)} € {parseFloat(this.state.taxe) == 0.00? "":"HT"}</option>
                        <option value="annually">{this.state.lng ==='FR'? "Annuelle": "Annually"} {parseFloat(this.state.htbasicannually).toFixed(2)} € {parseFloat(this.state.taxe) == 0.00? "":"HT"}{this.state.lng === "FR"?  "/mois": "/month"}</option>
                        
                      </Form.Select>
                    </div>

                    <div className='boutonsubscribe' onClick={() => this.typeabonnement("basic")}>
                      <p  style={{color:"white", fontSize:"30px", textAlign:"center"}}>{this.state.lng === "FR"?  "Acheter": "Purchase"}</p>
                    </div>
                    
                  </Card.Body>

                </Card>   

                <Card style={{ width: '19rem', height: "600px", borderRadius:'25px', margin:'5px' , marginTop:isBrowser?"-94px":"20px"}} >                                               
                                                                                      
                <Card.Body className="cardlist">
                                        
                    <Card.Title style={{color:"#2A83E8",fontSize:"16px", lineHeight:"26px", textAlign:"left", marginLeft:"4px"}} >
                      <p style={{fontSize:"26px"}}>Business </p>
                      <p style={{marginTop:"-0px",fontSize:"26px"}}>{parseFloat(this.state.businessprice).toFixed(2)} € {parseFloat(this.state.taxe) == 0.00? "":"HT"} {this.state.lng === "FR"?  "/mois": "/month"}</p>
                      <p style={{marginTop:"-0px"}}>{this.state.lng === "FR"?  "TVA non incluse": "VAT not included"}   </p>
                      {/*<p style={{marginTop:"-20px"}}>{parseFloat(this.state.ttcbusiness).toFixed(2)} {this.state.lng === "FR"?  "€ TTC/mois": "€ TTC/month"} </p>*/}
                      {/*<p style={{marginTop:"-20px"}}>{this.state.lng === "FR"?  "TVA ": "VAT "} {parseFloat(this.state.taxe).toFixed(2)}% : {parseFloat(this.state.taxebusiness).toFixed(2)} €</p>*/}
                      <p style={{marginTop:"20px"}}>{this.state.lng === "FR"?  "Facturé annuellement": "Billed Annually"}</p>
                      <p style={{marginTop:"20px",fontSize:"22px"}}>{this.state.lng === "FR"?  "Économisez 31 % (Payez en seule fois)": "Saved 31% (Pay in one go)"}</p>
                    </Card.Title>

                    <Card.Text style={{fontSize:"32px", color:"#ff4e00", textAlign:"left",margin:'10px'}}></Card.Text>

                    
                    <div className="row" style={{marginTop:"20px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute"}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949", fontWeight:"bold"}}>{this.state.lng === "FR"?  "Tout sur le Basic et": "Everything on Basic and"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "1 200 minutes par mois": "1 200 minutes per month"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "20 Interlocuteurs ": "20 speakers"}</p>
                        </div>
                    
                    </div>

                    <div style={{margin:'20px'}} className='d-flex justify-content-center'>
                      <Form.Select style={{height:'40px', width:'250px', marginTop:"44px" }} value={this.state.businessoption} onChange={this.selecttypebusiness}>
                                                    
                        <option value="monthly">{this.state.lng ==='FR'? "Mensuelle": "Monthly"} {parseFloat(this.state.htbusinessmonthly).toFixed(2)} € {parseFloat(this.state.taxe) == 0.00? "":"HT"}</option>
                        <option value="annually">{this.state.lng ==='FR'? "Annuelle": "Annually"} {parseFloat(this.state.htbusinessannually).toFixed(2)} € {parseFloat(this.state.taxe) == 0.00? "":"HT"}{this.state.lng === "FR"?  "/mois": "/month"}</option>
                        
                      </Form.Select>
                    </div>

                    <div className='boutonsubscribe' onClick={() => this.typeabonnement("business")}>
                      <p  style={{color:"white", fontSize:"30px", textAlign:"center"}}>{this.state.lng === "FR"?  "Acheter": "Purchase"}</p>
                    </div>
                    
                  </Card.Body>

                </Card>   

                <Card style={{ width: '19rem', height: "600px", borderRadius:'25px', margin:'5px' , marginTop:isBrowser?"-94px":"20px"}} >                                               
                                                                                      
                <Card.Body className="cardlist">
                                        
                    <Card.Title style={{color:"#00934c",fontSize:"16px", lineHeight:"26px", textAlign:"left", marginLeft:"4px"}} >{this.state.lng === "FR"?  <p>
                      <p style={{fontSize:"26px"}}>Entreprise</p>
                      <p style={{marginTop:"0px",fontSize:"26px"}}>API</p>
                      <p style={{marginTop:"20px",fontSize:"26px"}}>
                    Prix personnalisé</p>
                    </p>: <p>
                    <p style={{fontSize:"26px"}}>Enterprise</p>
                    <p style={{marginTop:"0px",fontSize:"26px"}}> API</p>
                    <p style={{marginTop:"20px",fontSize:"26px"}}>Custom price</p></p>}</Card.Title>

                    
                    <Card.Text style={{fontSize:"32px", color:"#ff4e00", textAlign:"left",margin:'10px'}}></Card.Text>

                    <div className="row" style={{marginTop:"20px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949", fontWeight:"bold"}}>{this.state.lng === "FR"?  "Tout sur le Business et": "Everything on Business and"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Nombre de minutes personnalisable par mois": "Personalized minutes per month"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Nombre d'interlocuteurs personnalisable": "Personalized speakers number"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Nombre de minutes par conversation personnalisable": "Personalized minutes per conversation"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Déploiement à l'échelle de l'organisation avec API": "Organization-wide deployment with API"}</p>
                        </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"-10px"}}>
                        
                        <div >
                          <span><CheckCircleOutlineIcon style={{color:"#2A83E8", marginRight:'20px', position: "absolute",}}/></span>
                        </div>
                
                        <div >
                        <p style={{fontWeight:"400", fontSize:"14px", paddingLeft:"30px", color:"#4d4949"}}>{this.state.lng === "FR"?  "Intégration CRM à la demande": "CRM Integration on demand"}</p>
                        </div>
                    
                    </div>

                    <div className='boutonsubscribe' onClick={() => this.openmodalenterprise()}>
                      <p  style={{color:"white", fontSize:"30px", textAlign:"center"}}>{this.state.lng === "FR"?  "Découvrir": "Discover"}</p>
                    </div>
                    
                    
                  </Card.Body>

                </Card>   


              </div>

              <div >
                <p style={{fontWeight:"bold", fontSize:"16px", paddingLeft:"30px", color:"#4d4949", textAlign:"center"}}>{this.state.lng === "FR"?  "Veuillez désactiver votre bloqueur de pop-ups pour accéder au paiement PayPal.": "Please disable your pop-up blocker to access PayPal payment."}</p>
              </div>

              {/** 

              {parseInt(this.state.idtypeuser) == 2 ?

                <div className='d-flex justify-content-center' style={{marginTop:"40px",marginBottom:"40px"}}>

                <Card style={{ width: '350px', height:"200px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px'}} >                                               
                                                                                  
                    <Card.Body >

                        <p style={{color: "#CD7F32",margin:'5px', textAlign:"center", fontSize:"25px"}}>Basic</p>

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Tarif' : 'Customer price'}</label>
                          <input type="number" className="form-control" value={this.state.starterprice} onChange={(e) => this.setState({ starterprice: e.target.value})} />
                        </div>
                        <div className='d-flex justify-content-center'>
                                              
                          <Button className="btn" style={{ background: "#CD7F32", border: 0,margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatepricing()} >{this.state.lng ==='FR'? 'Mettre à jour': 'Update'}</Button>

                        </div>

                    </Card.Body>

                </Card>   

                <Card style={{ width: '350px', height:"200px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px'}} >                                               
                                                                                  
                    <Card.Body >

                        <p style={{color: "#2A83E8",margin:'5px', textAlign:"center", fontSize:"25px"}}>Business</p>

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>{this.state.lng ==="FR" ? 'Tarif Business' : 'Business price'}</label>
                          <input type="number" className="form-control" value={this.state.businessprice} onChange={(e) => this.setState({ businessprice: e.target.value})} />
                        </div>
                        <div className='d-flex justify-content-center'>
                                              
                          <Button className="btn" style={{background: "#2A83E8", border: 0,margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatepricing()} >{this.state.lng ==='FR'? 'Mettre à jour': 'Update'}</Button>

                        </div>

                    </Card.Body>

                </Card>   

                <Card style={{ width: '350px', height:"200px", borderRadius:'25px', marginLeft:isBrowser?'25px' :'0px'}} >                                               
                                                                                  
                    <Card.Body >

                        <p style={{color: "#FFD700",margin:'5px', textAlign:"center", fontSize:"25px"}}>{this.state.lng === "FR"? "Entreprise" : "Enterprise"}</p>
                        
                        <div className="form-group" style={{margin:'5px'}}>
                          <label>Email *</label>
                          <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                        </div>

                        <div className='d-flex justify-content-center'>
                                              
                          <Button className="btn" style={{background: "#2A83E8", border: 0,margin:"10px",right: 0, width:'170px'}} onClick={() => this.updatepricing()} >{this.state.lng ==='FR'? 'Envoyer le paiement': 'Send paiement'}</Button>

                        </div>

                    </Card.Body>

                </Card>   
                
                </div>
              
                :

                null
            
              }

              */}


                      
          </div>
                
          );
        }
    
}

export default Purchasecredit;
import './moncss.css';
import React,  {Alert,Component} from 'react';
import { Nav,Navbar,Button,Form,Container,Row,Col,NavDropdown} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import Card from 'react-bootstrap/Card'
import logo from './logo.png';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import HouseIcon from '@material-ui/icons/House';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Redirect  } from 'react-router-dom';

import Menu from './Menu';

class Deconnexion extends Component{
    
    constructor(props) {
    super(props);

    this.state = {
  
      refreshfinish: '',

    };
        
  }


  componentWillMount() {

    
  }
    
    componentDidMount() {

      sessionStorage.setItem( 
        'tokenunsaid', '',
      );

      sessionStorage.setItem( 'tokenclear', 'yes');

      sessionStorage.setItem(
        'iduser', '', 
      );

      sessionStorage.setItem(
        'fullnameuser', '', 
      );

      sessionStorage.setItem(
        'typeuser', '', 
      );

      sessionStorage.setItem(
        'idsociety', '', 
      );

      sessionStorage.setItem(
        'reloaded', false
      );

      sessionStorage.setItem(
        'refreshdata', 'no'
      );


        this.setState({ tokenexist: false})
      
        this.refreshdata();


    };

    refreshdata() {

      var refreshfinish = sessionStorage.getItem('refreshdata');

      if(refreshfinish === 'no'){      
        this.setState({ refreshfinish: 'yes'})
        window.location.reload(false);

      }

    }
    
  render() {

          return (
              
                <div className="d-flex justify-content-center" style={{marginTop:'250px'}}>

                    
                   {this.state.refreshfinish === 'yes' ?
                            

                            <Redirect push to="/"/>
                              

                            :

                            <Redirect push to="/"/>

                        }

                </div>
          );
        }
    
}

export default Deconnexion;


import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { isBrowser } from 'react-device-detect';
import parse from 'html-react-parser';
import ReactToPrint from 'react-to-print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


class Telemarketing extends Component{
    
    constructor(props) {
    super(props);
      this.state = {

        modalupdate: false,
        modaldelete: false,
        idjargon:0,
        wordjargon:'',
        question:'',
        sentence:'',
        responseopenai:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        lng:'',
        printbutton:false,
        
      };

  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    if(lnguser === 'FR'){

      document.title = "FUNCTION";
    
    }else{

      document.title = "FUNCTION";

    }

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  componentDidMount() { 

    document.title = "FUNCTION";
    
  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idjargon: 0,
    wordjargon: '',
    textloading:''
  });
  
}

async senddata() {

  this.setState({
    loadershow:true,
    printbutton:false,
  })

  if(this.state.question.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la question.',loadershow:false});
    return
  
  }

  if(this.state.question.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add question.',loadershow:false});
    return
  
  }

  if(this.state.sentence.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la conversation.',loadershow:false});
    return
  
  }

  if(this.state.sentence.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add conversation.',loadershow:false});
    return
  
  }

  const response = await fetch(this.state.wbslink + `openaifunction.php`,{                  
    method:'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    body: queryString.stringify({
      question : this.state.question.replaceAll('\n', ' '),
      sentence : this.state.sentence.replaceAll('\n', ' '),
    })});

  const data = await response.json();

  if(JSON.stringify(data).replace(/\"/g, "") === 'ERROR'){

    this.setState({ errorform: true, errormessage: 'API ERROR.' });

    return;

  
  }else{

    this.setState({
      responseopenai:JSON.stringify(data).replace(/\"/g, ""),
      errorform: false,
      errormessage: '',
      loadershow:false,
      printbutton:true,
      question : this.state.question.replaceAll('\n', ' '),
      sentence : this.state.sentence.replaceAll('\n', ' '),
    })

  }

}

   
  render() {

          return (
              
                <div className="App" style={{margin:'10%',marginTop:'120px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }
                        <div className='d-flex justify-content-start'>
                          <label>{this.state.lng ==="FR" ? 'Fonction' : 'Function'}</label>
                        </div>
                        <div className='d-flex justify-content-start'>
                          <textarea type="textarea" style={{width:'90%'}} rows={3} className="form-control" value={this.state.question} onChange={(e) => this.setState({ question: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-start'>
                        <label style={{marginTop:isBrowser?"50px":"20px"}}>{this.state.lng ==="FR" ? 'Phrase' : 'Sentence'}</label>
                        </div>
                        <div className='d-flex justify-content-start'>
                          <textarea type="textarea" style={{width:'90%'}} rows={isBrowser?"10":"6"} className="form-control" value={this.state.sentence} onChange={(e) => this.setState({ sentence: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center'>

                            {this.state.errorform == true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                        </div>

                     

                      <div className='d-flex justify-content-center'>
     
                        <button style={{margin:'20px'}}onClick={() => this.senddata()} className="btn btn-primary">{this.state.lng ==="FR" ? 'SUBMIT' : 'SUBMIT'}</button> 
                                                             
                      </div> 

                      <div className='d-flex justify-content-center'>

                          {this.state.loadershow == true ?
                                

                                <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                                  

                                :

                                null

                            }   
                      </div>

                      {this.state.printbutton == true ?

                            <div >

                              

                                <p style={{marginTop:isBrowser?"20px":"20px", fontSize:"30px"}}>{this.state.lng ==="FR" ? 'Résultat:' : 'Result:'}</p>

                                <ReactToPrint
                                                                  
                                  trigger={() => (
                                    <img src={process.env.PUBLIC_URL + 'images/pdf-animated.gif'} style={{ width:'150px',height:'150px'}}/>
                                    
                                  )}

                                  documentTitle = "SOUND NAME"

                                  content={() => this.componentRef}

                                />

                                <div ref={el => (this.componentRef = el)} style={{width:'96%', marginLeft:'2%', marginRight:'2%'}}>

                                  <div >

                                    <div className="container" style={{marginTop:"20px"}}>

                                      <div className="row d-flex align-items-center justify-content-center">

                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                          <img src={process.env.PUBLIC_URL + 'images/logo.png'} style={{width:"150px",height:'150px'}}/>
                                        </div>

                                        <div className="col-4 d-flex align-items-center justify-content-center">
                                          <p style={{fontWeight:'bold', fontSize:"20px"}}>SOUND NAME</p>
                                        </div>

                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                          <img src={process.env.PUBLIC_URL + 'images/logoentreprise.png'} style={{ width:"150px",height:'150px'}}/>
                                        </div>

                                      </div>

                                    </div>
                  
                                  </div>

                                  <p style={{textAlign:'left'}} >{parse(this.state.responseopenai.replaceAll('\\n', '<br/><br/>'))!=="null"? parse(this.state.responseopenai.replaceAll('\\n', '<br/><br/>')):<p>{this.state.lng ==="FR" ? 'veuillez réessayer s\'il vous PlagiarismTwoTone.:' : 'Please retry again'}</p>}</p>     

                                </div>

                            </div>

                            :

                            null

                      }

                </div>
                
          );
        }
    
}

export default Telemarketing;

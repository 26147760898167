import React,  {useState,Alert,Component} from 'react';
import styled from 'styled-components'
import {Link} from 'react-router-dom';
import Sidebar from './Sidebar';

const SidebarLink = styled(Link)`
    display: flex;
    color:#5f7278;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
    &:hover{
        background:#333333;
        border-left: 4px solid #2A83E8;
        cursor:pointer;
        color:#fff;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const DropdownLink = styled(Link)`
    background:#414757;
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover{
        background:#2A83E8;
        cursor:pointer;
    }
`;
const SubMenu = ({item}) => {

    const [subnav,setSubnav] = useState(false)
    const showSubnav = () =>  setSubnav(!subnav)


    return (

        <>
        
            <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
                
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>

                <div>
                    {
                        item.subNav && subnav
                        ? item.iconOpened
                        : item.subNav
                        ? item.iconClosed
                        :   null
                    }
                </div>
                
            </SidebarLink>
            {subnav && item.subNav.map((item, index) => {
                return(
                    <DropdownLink to={item.path} key={index}>
                        {item.icon}
                        <SidebarLabel>{item.title}</SidebarLabel>
                    </DropdownLink>
                )

                })
            }
        </>
    )

} 

export default SubMenu;



import './moncss.css';
import React,  {useState,Alert,Component} from 'react';
import styled from 'styled-components'
import {Link} from 'react-router-dom';
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import SidebarData from  './SidebarData'
import SubMenu from  './SubMenu'
import {IconContext} from 'react-icons/lib'
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Modal from 'react-bootstrap/Modal'
import { Form,FormControl,Button} from 'react-bootstrap';

const Nav = styled.div`
  background: #3c8dbc;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color:#333333;
  }
  
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  right:0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #222d31;
  margin-top:50px;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top:0;
  left: ${({sidebar}) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap= styled.div`
  width: 100%;
`;

class Sidebar extends Component{

  constructor(props) {
    super(props);
 
    this.state = {
 
        isModalVisible: false,
        sidebar: true,
         
    };
        
  }

  render(){ 

    return(
        <>
          <IconContext.Provider value={{color:'#5f7278'}}>
            
          <Nav>
          <img src={process.env.PUBLIC_URL +'images/' + 'logo2.png'} style={{marginLeft:"85px", width:"50px", height:"50px"}}/>
            <NavIcon to="#">
               {/*<FaIcons.FaBars onClick={ () => this.setState({isModalVisible:true})}/> */}

              
            </NavIcon>
                <p onClick={ () => this.setState({isModalVisible:true})} style={{color:'white',position: 'absolute', right: 25, fontSize:"1rem"}}><img src={process.env.PUBLIC_URL +'images/' + 'userprofil.jpg'} style={{width:"40px", height:"40px", marginTop:"10px", borderRadius:'20px'}}/> Rova Christian</p>
          </Nav>
          
                        <div >

                        
                        
                                        
                            <Modal
                                dialogClassName="modal-dialog"
                                show={this.state.isModalVisible}
                                onHide={ () => this.setState({isModalVisible:false})}
                               
                            >
                            
                            <Modal.Body>
                        
                                
                                <div style={{fontSize:"1rem", alignItems:'center',justifContent: 'center',textAlign:'center'}}>
                                    <p style={{fontWeight:'bold'}}>Administrateur</p>
                                    <p>Crédit en minutes</p>
                                    <p>Acheté : 60, Consommé : 15, Restant : 45</p>

                                </div>  
                                <div className="alignrow">
                                <div style={{fontSize:"1rem", alignItems:'center',justifContent: 'center',textAlign:'left'}}>
                                <Button style={{ background: 'linear-gradient(45deg, #333333 30%, #222d31 90%)',
                                        border: 0,
                                        borderRadius: 3,
                                        boxShadow: '0 3px 5px 2px rgba(93, 101, 106, 0.3)',
                                        
                                        height: 30,
                                        padding: '0 15px'}} variant="primary" onClick={ () => this.setState({isModalVisible:false})}>
                                Mon profil
                              </Button>

                                </div>  

                                <div style={{fontSize:"1rem", alignItems:'center',justifContent: 'center',textAlign:'right'}}>
                                  <Button style={{ background: 'linear-gradient(45deg, #333333 30%, #222d31 90%)',
                                        border: 0,
                                        borderRadius: 3,
                                        boxShadow: '0 3px 5px 2px rgba(93, 101, 106, 0.3)',
                                        
                                        height: 30,
                                        padding: '0 15px'}} variant="primary" onClick={ () => this.setState({isModalVisible:false})}>
                                  Se déconneter
                                </Button>

                                </div> 

                                </div>
                            </Modal.Body>

                            </Modal>
                        </div>
          
          <SidebarNav sidebar={this.state.sidebar}>

          
            <SidebarWrap>
            
            
            <div style={{color:'white', fontSize:"1rem"}} className="alignrow">
              
              <img src={process.env.PUBLIC_URL +'images/' + 'userprofil.jpg'} style={{width:"50px", height:"50px", margin:"5px", borderRadius:'25px'}}/>
              <p style={{margin:"5px"}}>Rova Christian RATSIMBAZAFY</p>
            </div>  
            <div style={{padding:"5px", color:'white', width:'100%'}} >
              <Form className="d-flex">
                <FormControl
                  type="search"
                  placeholder="Rechercher"
                  className="me-2"
                  aria-label="Search"
                />
              </Form>
            </div>
            <div style={{padding:"25px", color:'white', boxShadow:'10px',backgroundColor:'#333333', width:'100%'}} >Menu navigation</div>
            {/*<NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </NavIcon>
            <img src={logo} style={{width:"200px", height:"100px", left: "25px"}}/>*/}
            {SidebarData.map((item, index) =>{
                return <SubMenu item={item} key={index}/>;
              })
            }
            </SidebarWrap>
          </SidebarNav>
          </IconContext.Provider>
        </>

    );
  }

};

export default Sidebar;


import React,  {Alert,Component} from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { isExpired, decodeToken }from "react-jwt";
import { Button,Form,Modal} from 'react-bootstrap';
import Loader from "react-js-loader";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

class Termsofuses extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      lng:'',
      serviceprovision:true,
      acceptexperience:false,
      textacceptexperience:"",
      acceptmarketing:false,
      textacceptmarketing:"",
      acceptshare:false,
      textacceptshare:"",
      acceptupload:true,
      loadingdata:true,
      };

        
  }
    
    componentDidMount() {
        
    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let token = sessionStorage.getItem('tokenunsaid');

    if(token === '' || token === 'Undefined' || token === null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        lng:'',
      })
      return;
    }

    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    if(lnguser == "FR"){

      document.title = "Conditions générales d'utilisation'";

    }else{

      document.title = "Terms of uses";

    }

      this.setState({
        loadingdata:false
      })

    window.scrollTo(0,0)
        
  };
    
  render() {

          return (
              
                <div style={{margin:"2%",marginTop:"80px"}} >

                {this.state.loadingdata === true ?
                      
                  <div className='centercontent'>

                    <Loader type="spinner-cub" bgColor={'#2A83E8'} color={'#2A83E8'} size={100} />

                  </div>
                  

                :

                  <div className="rgpdborder">

                    <p style={{marginTop:"0px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "1. Objet":"1. Purpose"}</p>
                    <p style={{color:"black",textAlign:"left"}}>{this.state.lng=== "FR"? "Ces Conditions d'Utilisation définissent les conditions d'accès et d'utilisation du site web d'Unsaid ainsi que des services SaaS proposés, incluant U-Note et U-Feel. En accédant au site ou en utilisant les services, vous acceptez sans réserve les présentes Conditions d'Utilisation.":"These Terms of Use define the conditions for accessing and using the Unsaid website and the SaaS services offered, including U-Note and U-Feel. By accessing the site or using the services, you unconditionally accept these Terms of Use."}</p>
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "2. Accès au site et aux services":"2. Access to the Site and Services"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Le site internet unsaidapps.com est accessible à tout utilisateur disposant d'une connexion internet. L'accès aux services SaaS, U-Note et U-Feel, est soumis à la création d'un compte utilisateur. Les utilisateurs sont responsables de la confidentialité de leurs identifiants et mots de passe. Unsaid ne peut être tenu responsable de l'utilisation frauduleuse de ces informations.":"The website www.unsaid.fr is accessible to any user with an internet connection. Access to the SaaS services, U-Note and U-Feel, is subject to the creation of a user account. Users are responsible for maintaining the confidentiality of their credentials and passwords. Unsaid cannot be held responsible for any fraudulent use of this information."}</p>

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "3. Utilisation des services SaaS":"3. Use of SaaS Services"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les services U-Note et U-Feel permettent la création de rapports automatiques à partir d'enregistrements d'appels audio ou vidéo, ainsi que l'analyse des sentiments des conversations. Ces services sont accessibles uniquement aux utilisateurs ayant souscrit à une offre commerciale.":"The U-Note and U-Feel services allow for the creation of automatic reports from audio or video call recordings, as well as sentiment analysis of conversations. These services are available only to users who have subscribed to a commercial offer."}</p>          
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "4. Propriété intellectuelle":"4. Intellectual Property"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Tout le contenu du site, incluant les logiciels, textes, images, graphiques, logos, icônes et tout autre élément, est la propriété exclusive d'Unsaid ou de ses partenaires et est protégé par les lois sur la propriété intellectuelle.":"All content on the site, including software, texts, images, graphics, logos, icons, and any other elements, is the exclusive property of Unsaid or its partners and is protected by intellectual property laws."}</p>          
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "5. Utilisation de l'Intelligence Artificielle (IA)":"5. Use of Artificial Intelligence (AI)"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid utilise des solutions d'intelligence artificielle pour analyser les enregistrements et générer des rapports. Ces solutions impliquent des technologies développées en interne ainsi que des outils spécialisés de partenaires tiers. Les données sont traitées soit sur les serveurs internes d'Unsaid, soit par des partenaires tiers, en conformité avec la législation applicable en matière de protection des données.":"Unsaid uses artificial intelligence solutions to analyze recordings and generate reports. These solutions involve technologies developed internally and third-party specialized AI tools. Data is processed either on Unsaid's internal servers or by third-party partners, in compliance with applicable data protection legislation."}</p>          
                 
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "6. Données personnelles":"6. Personal Data"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid s'engage à garantir la confidentialité des données personnelles collectées sur son site et via ses services SaaS, conformément aux réglementations en vigueur, y compris le RGPD. Les données collectées peuvent être traitées par les outils internes d'Unsaid ou par des partenaires tiers. Pour plus d'informations, veuillez consulter notre Politique de Confidentialité.":"Unsaid is committed to maintaining the confidentiality of personal data collected on its site and through its SaaS services, in accordance with applicable regulations, including GDPR. Collected data may be processed by both Unsaid's internal tools and third-party partners. For more information, please refer to our Privacy Policy."}</p>             
                   
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "7. Responsabilité":"7. Liability"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid s'efforce de fournir des services fiables et sécurisés. Cependant, la société ne peut être tenue responsable des dommages directs ou indirects liés à l'utilisation de ses services SaaS ou à l'accès au site. Les utilisateurs sont invités à signaler tout dysfonctionnement ou contenu illicite rencontré sur le site.":"Unsaid strives to provide reliable and secure services. However, the company cannot be held responsible for direct or indirect damages related to the use of its SaaS services or access to the site. Users are encouraged to report any malfunctions or illegal content encountered on the site."}</p>             
                  
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "8. Modifications des Conditions d'Utilisation":"8. Modifications to the Terms of Use"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid se réserve le droit de modifier les présentes Conditions d'Utilisation à tout moment. Les utilisateurs seront informés de toute modification par un message sur le site ou par email.":"Subscriptions to SaaS services can be terminated by the user or by Unsaid under the conditions set out in the subscription contract. In the event of non-compliance with these Terms of Service by the customer, Unsaid reserves the right to suspend or terminate access to services immediately."}</p>             
               
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "9. Droit applicable":"9. Applicable Law"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les présentes Conditions d'Utilisation sont régies par le droit français. Tout litige lié à l'utilisation du site ou des services sera soumis à la compétence exclusive des tribunaux de Paris.":"These Terms of Use are governed by French law. Any dispute related to the use of the site or services will be subject to the exclusive jurisdiction of the Paris courts."}</p>             

                  </div>

                }
                    
     
                </div>
          );
        }
    
}

export default Termsofuses;

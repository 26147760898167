
import React,  {Alert,Component} from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { isExpired, decodeToken }from "react-jwt";
import { Button,Form,Modal} from 'react-bootstrap';
import Loader from "react-js-loader";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

class Legalnotice extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      lng:'',
      serviceprovision:true,
      acceptexperience:false,
      textacceptexperience:"",
      acceptmarketing:false,
      textacceptmarketing:"",
      acceptshare:false,
      textacceptshare:"",
      acceptupload:true,
      loadingdata:true,
      };

        
  }
    
    componentDidMount() {
        
    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let token = sessionStorage.getItem('tokenunsaid');

    if(token === '' || token === 'Undefined' || token === null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        lng:'',
      })
      return;
    }

    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    if(lnguser == "FR"){

      document.title = "Informations légales";

    }else{

      document.title = "Legal notice";

    }

      this.setState({
        loadingdata:false
      })

    window.scrollTo(0,0)
        
  };
    
  render() {

          return (
              
                <div style={{margin:"2%",marginTop:"80px"}} >

                {this.state.loadingdata === true ?
                      
                  <div className='centercontent'>

                    <Loader type="spinner-cub" bgColor={'#2A83E8'} color={'#2A83E8'} size={100} />

                  </div>
                  

                :

                  <div className="rgpdborder">

                    <p style={{color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "1. Informations légales":"1. Legal Information"}</p>
                    <p style={{color:"black",textAlign:"left", marginLeft:"1.5%"}}>{this.state.lng=== "FR"? "• Nom de l'entreprise: Unsaid":"• Company Name: Unsaid"}</p>
                    <p style={{color:"black",textAlign:"left", marginLeft:"1.5%"}}>{this.state.lng=== "FR"? "• Forme juridique: SASU":"• Legal Form: SASU"}</p>
                    <p style={{color:"black",textAlign:"left", marginLeft:"1.5%"}}>{this.state.lng=== "FR"? "• Numéro SIRET: 892 467 614 00012":"• SIRET Number: 892 467 614 00012"}</p>
                    <p style={{color:"black",textAlign:"left", marginLeft:"1.5%"}}>{this.state.lng=== "FR"? "• Adresse du siège social : 229 Rue Saint-Honoré, 75001 Paris":"• Headquarters Address: 229 Rue Saint-Honoré, 75001 Paris"}</p>
                    <p style={{color:"black",textAlign:"left", marginLeft:"1.5%"}}>{this.state.lng=== "FR"? "• Capital social: 15 000 Euros":"• Share Capital: 15,000 Euros"}</p>
                    <p style={{color:"black",textAlign:"left", marginLeft:"1.5%"}}>{this.state.lng=== "FR"? "• Nom du président: Filipe TEODOSIO":"• President's Name: Filipe TEODOSIO"}</p>
                    <p style={{color:"black",textAlign:"left", marginLeft:"1.5%"}}>{this.state.lng=== "FR"? "• Contact: ":"• Contact: "} <a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:contact@unsaid.fr"}>contact@unsaid.fr</a></p>
                    <p style={{color:"black",textAlign:"left", marginLeft:"1.5%"}}>{this.state.lng=== "FR"? "• Numéro de téléphone: ":"• Phone Number: "} <a style={{textAlign:'left', textDecoration:'none'}} href={"tel:+33615923357"}>+33 6 15 92 33 57 </a></p>
                    <p style={{color:"black",textAlign:"left", marginLeft:"1.5%"}}>{this.state.lng=== "FR"? "• Site internet : ":"• Company Name: Unsaid"} <a style={{textAlign:'left', textDecoration:'none'}} href={"https://unsaid.fr"}>https://unsaid.fr</a></p>
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "2. Accès au site et aux services":"2. Company Activities"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid est spécialisé dans la fourniture de solutions SaaS, de services de conseil et de développement informatique. Le détail des services proposés est le suivant :":"Unsaid specializes in providing SaaS solutions, consulting services, and IT development. Details of the offered services are as follows:"}</p>

                      <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "2.1. Solutions SaaS:":"2.1. SaaS Solutions"}</p>
                      <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• U-Note : Une solution SaaS qui crée un rapport structuré et personnalisé à partir d'enregistrements d'appels audio ou vidéo.":"• U-Note: A SaaS solution that creates a structured and personalized report from recorded audio or video calls."}</p>
                      <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• U-Feel : Une solution SaaS qui analyse les sentiments des enregistrements d'appels audio ou vidéo, fournissant des rapports de données pour améliorer la satisfaction client et les performances opérationnelles.":"• U-Feel: A SaaS solution that analyzes sentiment from recorded audio or video calls, providing data reports to improve customer satisfaction and operational performance."}</p>

                      <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "2.1. Solutions SaaS:":"2.2. Consulting Services"}</p>
                      <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Innovation numérique, transformation et stratégie":"• Digital Innovation, Transformation & Strategy"}</p>
                      <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Gestion du changement":"• Change Management"}</p>
                      <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Spécifications informatiques et support":"• IT Specification & Support"}</p>

                      <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "2.3. Développement informatique:":"2.3. IT Development"}</p>
                      <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Mise en œuvre de l'intelligence artificielle":"• Artificial Intelligence Implementation"}</p>
                      <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Développement web, desktop, mobile":"• Web, Desktop, Mobile Development"}</p>
                      <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Optimisation pour les moteurs de recherche":"• Search Engine Optimizatio"}</p>

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "3. Utilisation des services SaaS":"3. Website Hosting"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Le site unsaid.fr est hébergé chez :":"The website unsaid.fr is hosted at:"}</p>          
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• OVH":"• OVH"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Adresse : 2 rue Kellermann, BP 80157, 59053 Roubaix Cedex 1, France":"• Address: 2 rue Kellermann, BP 80157, 59053 Roubaix Cedex 1, France"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• SAS au capital de : 10 174 560,00 €":"• SAS with a capital of 10,174,560.00 €"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• RCS Lille Métropole : 424 761 419 00045":"• RCS Lille Métropole 424 761 419 00045"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Code APE : 2620Z":"• APE Code: 2620Z"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Numéro de TVA : FR22424761419":"• VAT Number: FR22424761419"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• SIREN : 424 761 419":"• SIREN: 424 761 419"}</p>

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "4. Hébergement des solutions SaaS":"4. SaaS Solutions Hosting"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les solutions SaaS sont hébergées par : :":"The SaaS solutions are hosted at:"}</p>          
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• LIGNE WEB SERVICES":"• LIGNE WEB SERVICES"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Adresse : 10, Rue de Penthièvre, 75008 Paris, France":"• Address: 10, Rue de Penthièvre, 75008 Paris, France"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Capital : 500 000 Euros":"• Capital: 500,000 Euros"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• RCS Paris B : 851 993 683":"• RCS Lille Métropole 424 761 419 00045"}</p>
                    <p style={{marginTop:"20px", color:"black",textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Numéro de TVA : FR21 851 993 683":"• VAT Number: FR21 851 993 683"}</p>






                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "5. Propriété intellectuelle":"5. Intellectual Property"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Le contenu de ce site et les services fournis, incluant les textes, images, graphiques, logos, icônes et logiciels, sont la propriété exclusive d'Unsaid ou de ses partenaires et sont protégés par les lois françaises et internationales sur la propriété intellectuelle.":"The content of this website and the services provided, including texts, images, graphics, logos, icons, and software, are the exclusive property of Unsaid or its partners and are protected by French and international intellectual property laws."}</p>          
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "5. Utilisation de l'Intelligence Artificielle (IA)":"5. Use of Artificial Intelligence (AI)"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid utilise des solutions d'intelligence artificielle pour analyser les enregistrements et générer des rapports. Ces solutions impliquent des technologies développées en interne ainsi que des outils spécialisés de partenaires tiers. Les données sont traitées soit sur les serveurs internes d'Unsaid, soit par des partenaires tiers, en conformité avec la législation applicable en matière de protection des données.":"Unsaid uses artificial intelligence solutions to analyze recordings and generate reports. These solutions involve technologies developed internally and third-party specialized AI tools. Data is processed either on Unsaid's internal servers or by third-party partners, in compliance with applicable data protection legislation."}</p>          
                 
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "6. Données personnelles":"6. Personal Data"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les informations collectées via le formulaire de contact ou lors de l'utilisation des services sont traitées en conformité avec les réglementations en vigueur en matière de protection des données personnelles. Pour plus d'informations, veuillez consulter notre politique de confidentialité.":"Information collected through the contact form or during service use is processed in compliance with the regulations in force regarding personal data protection. For more information, please refer to our privacy policy."}</p>             
                   
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "7. Responsabilité":"7. Liability"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid met tout en œuvre pour fournir des informations précises et à jour sur son site internet. Cependant, la société ne peut être tenue responsable d'éventuelles erreurs ou omissions. Unsaid se réserve le droit de modifier le contenu à tout moment sans préavis.":"Unsaid makes every effort to provide accurate and up-to-date information on its website. However, the company cannot be held liable for any errors or omissions. Unsaid reserves the right to modify the content at any time without prior notice."}</p>             
                  
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "9. Droit applicable":"9. Applicable Law"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Ces mentions légales sont régies par le droit français. En cas de litige, seuls les tribunaux de Paris seront compétents.":"These legal notices are governed by French law. In case of disputes, only the courts of Paris will have jurisdiction."}</p>             


                  </div>

                }
                    
     
                </div>
          );
        }
    
}

export default Legalnotice;


import React,  {Alert,Component} from 'react';

class Fichiersaudio extends Component{
    
    constructor(props) {
    super(props);
        
  }
    
    componentDidMount() {
        
        
    };
   
    
  render() {

          return (
              
                <div className="App">
                    
                    <p style={{color:'black'}}>Fichiers audio</p>
                
                    

                </div>
          );
        }
    
}

export default Fichiersaudio;

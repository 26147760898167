
import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { isBrowser } from 'react-device-detect';
import parse from 'html-react-parser';
import ReactToPrint from 'react-to-print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReactAudioPlayer from 'react-audio-player';

import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;


class Report extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        isModaladd: false,
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        lng:'',
        printbutton:false,
        selectedFile: null,
        soundfilename:null,
        dureesound:'',
        selectedOption: null,
        listlng: [],
        soundlng:'',
        soundname:'',
        soundlink:'',
        compterendu:'',
        speaker:[],
        printbutton: false,
        
      };
      this.selectlng = this.selectlng.bind(this);

  }

  selectlng(e) {
    this.setState({ soundlng: e.target.value });
  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    if(lnguser === 'FR'){

      document.title = "Faire un compte rendu";
    
    }else{

      document.title = "Make a report";

    }

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, ""); 

    const responselngsnd = await fetch(this.state.wbslink + 'listsoundlanguage',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });

    const datalngsnd = await responselngsnd.json();
    this.setState({ listlng: datalngsnd, loadershow: false})

    let lnguser = sessionStorage.getItem('langueuser');

    if(lnguser === 'FR'){

      document.title = "Faire un compte rendu";
    
    }else{

      document.title = "Make a report";

    }
    
  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idjargon: 0,
    wordjargon: '',
    textloading:''
  });
  
}

async senddata() {

  this.setState({
    loadershow:true,
    printbutton:false,
  })

  if(this.state.question.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la question.',loadershow:false});
    return
  
  }

  if(this.state.question.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add question.',loadershow:false});
    return
  
  }

  if(this.state.sentence.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la conversation.',loadershow:false});
    return
  
  }

  if(this.state.sentence.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add conversation.',loadershow:false});
    return
  
  }

  const response = await fetch(this.state.wbslink + `openaifunction.php`,{                  
    method:'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    body: queryString.stringify({
      question : this.state.question.replaceAll('\n', ' '),
      sentence : this.state.sentence.replaceAll('\n', ' '),
    })});

  const data = await response.json();

  if(JSON.stringify(data).replace(/\"/g, "") === 'ERROR'){

    this.setState({ errorform: true, errormessage: 'API ERROR.' });

    return;

  
  }else{

    this.setState({
      responseopenai:JSON.stringify(data).replace(/\"/g, ""),
      errorform: false,
      errormessage: '',
      loadershow:false,
      printbutton:true,
      question : this.state.question.replaceAll('\n', ' '),
      sentence : this.state.sentence.replaceAll('\n', ' '),
    })

  }

}

handleChange = selectedOption => {
  this.setState({ selectedOption });
  //alert(`Option selected:`, selectedOption);
};

onFileChange = event => { 

  // Update the state 
  this.setState({ selectedFile: event.target.files[0] }); 
  if(event.target.files[0] !== null){
    this.setState({
      loadershow: false,
      soundfilename : event.target.files[0].name,
    });
  }

}; 
 
// On file upload (click the upload button) 
onFileUpload = () => { 

  if(this.state.selectedFile === null  && this.state.lng === "FR"){

      this.setState({
        loadershow: true,
        textloading:'Veuillez séléctionner un fichier audio.'
      });
    
    return;
  
  }

  if(this.state.selectedFile === null && this.state.lng !== "FR"){

    this.setState({
      loadershow: true,
      textloading:'Please select an audio file.'
    });
  
  return;

}

  if(this.state.soundlng ==='' && this.state.lng === "FR"){

      this.setState({
        loadershow: true,
        textloading:'Veuillez séléctionner la language de l\'audio.'
      });
    
    return;

  }

  if(this.state.soundlng ==='' && this.state.lng !== "FR"){

      this.setState({
        loadershow: true,
        textloading:'Please select audio language.'
      });
    
    return;

  }

  if(this.state.lng === "FR"){
    this.setState({
      loadershow: true,
      textloading:'Chargement du fichier en cours ...'
    });
  }else{
    this.setState({
      loadershow: true,
      textloading:'Loading data file ...'
    });
  }

  // Create an object of formData 
  const formData = new FormData(); 
 
  // Update the formData object 
  formData.append( 
    "sound", 
    this.state.selectedFile, 
    this.state.selectedFile.name,
  ); 

  formData.append("language",this.state.soundlng);

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

  fetch(this.state.wbslink + `v1.0/meetingreport`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      this.setState({
        soundname:JSON.stringify(data.Nom_audio).replace(/\"/g, ""),
        soundlink:this.state.wbslink + "sound/" + JSON.stringify(data.Lien_audio).replace(/\"/g, ""),
        compterendu:JSON.stringify(data.Compte_rendu).replace(/\"/g, ""),
        speaker:data.Speaker,
        loadershow: false,
        printbutton: true,
      })

      return;

    if(data.length > 0){
      

           

      

    }else{

      this.setState({ loadershow:false});

    }

  })

  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  


}



   
  render() {

          return (
              
                <div className="App" style={{margin:'10%',marginTop:'120px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    <div className="d-flex flex-column align-items-center" style={{margin:"20px"}}>

                      <label htmlFor="filePicker" style={{background:"#2A83E8", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}> {this.state.lng ==='FR'? 'Choisir fichier': 'Choose file'} </label>
                      <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept="audio/*,.gsm"/>

                      {this.state.soundfilename === null ?

                          <div style={{marginBottom:'20px', marginTop:"-20px"}}>
                              {this.state.lng ==='FR'? 'Aucun fichier selectionné': 'No file chosen'}                     
                          </div>

                        :

                          this.state.soundfilename

                      }

                      <p style={{marginTop:'20px'}}>{this.state.lng ==='FR'? 'Langue de l\'audio': 'Audio language'}  :</p>
                      <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                        <Form.Select style={{height:'40px', width:'200px' }} value={this.state.soundlng} onChange={this.selectlng}>
                          {this.state.listlng.map((lng) => (
                            <option value={lng.lng}>{this.state.lng ==='FR'? lng.languefr: lng.langueen}</option>
                          ))}
                        </Form.Select>
                      </div>

                      <div style={{margin:'10px'}}>

                      {this.state.soundlng !== '' ?

                        <button onClick={this.onFileUpload}  className="btn btn-success"> 
                          {this.state.lng ==='FR'? 'Envoyer le fichier ': 'Send file'} 
                        </button>

                        :

                        null

                      }

                      {this.state.loadershow === true ?

                        <div style={{marginTop:'20px'}}>

                          <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />

                        </div>

                        :

                        null

                      }
                        
                      </div>                            

                    </div>

                        

                        <div className='d-flex justify-content-center'>

                            {this.state.errorform == true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                        </div>

                      {this.state.printbutton == true ?

                            <div >

                                <p style={{marginTop:isBrowser?"20px":"20px", fontSize:"30px"}}>{this.state.lng ==="FR" ? 'Résultat:' : 'Result:'}</p>

                                <ReactToPrint
                                                                  
                                  trigger={() => (
                                    <img src={process.env.PUBLIC_URL + 'images/pdf-animated.gif'} style={{ width:'150px',height:'150px'}}/>
                                    
                                  )}

                                  documentTitle = "SOUND NAME"

                                  content={() => this.componentRef}

                                />

                                <div class="col-sm">
                                  <div className="audioplayermodal">
                                      <ReactAudioPlayer
                                        src={this.state.soundlink}
                                        controls
                                        volume
                                        
                                        />
                                  </div>
                                </div>

                                <div ref={el => (this.componentRef = el)} style={{width:'96%', marginLeft:'2%', marginRight:'2%'}}>

                                  <div >

                                    <div className="container" style={{marginTop:"20px"}}>

                                      <div className="row d-flex align-items-center justify-content-center">

                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                          <img src={process.env.PUBLIC_URL + 'images/logo.png'} style={{width:"150px",height:'150px'}}/>
                                        </div>

                                        <div className="col-4 d-flex align-items-center justify-content-center">
                                          <p style={{fontWeight:'bold', fontSize:"20px", textAlign:'center'}}>{this.state.lng ==="FR" ? 'Audio: ' : 'Audio: '}{this.state.soundname}</p>
                                        </div>

                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                          <img src={process.env.PUBLIC_URL + 'images/logoentreprise.png'} style={{ width:"150px",height:'150px'}}/>
                                        </div>

                                      </div>

                                        <div className="col-4 d-flex align-items-start justify-content-start">
                                          <p style={{fontWeight:'bold', fontSize:"20px"}}>{this.state.lng ==="FR" ? 'Compte rendu: ' : 'Meeting report: '}</p>
                                        </div>

                                        <p style={{textAlign:'left'}} >{parse(this.state.compterendu.replaceAll('\\n', '<br/><br/>'))!=="null"? parse(this.state.compterendu.replaceAll('\\n', '<br/><br/>')):<p>{this.state.lng ==="FR" ? 'veuillez réessayer s\'il vous PlagiarismTwoTone.:' : 'Please retry again'}</p>}</p>     

                                        

                                        <div className="col-4 d-flex align-items-start justify-content-start">
                                          <p style={{fontWeight:'bold', fontSize:"20px"}}>{this.state.lng ==="FR" ? 'Speaker: ' : 'Speaker: '}</p>
                                        </div>

                                        <div className="d-flex align-items-start justify-content-start">

                                        {this.state.speaker.length>0?

                                          <div>

                                            {this.state.speaker.map((row) => (

                                                <p key={row.id_sentence} style={{textAlign:'left'}} >Speaker {row.Speaker} ({row.Time}) : {row.Sentence}</p>

                                            ))}

                                          </div>

                                          :

                                          null

                                        }
                                          
                                        </div>

                                      </div>

                                  </div>

                                </div>

                            </div>

                            :

                            null

                      }

                </div>
                
          );
        }
    
}

export default Report;

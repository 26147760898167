
import React,  {Alert,Component} from 'react';
import {useEffect,useRef} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple,white, red } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
//import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import './moncss.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col,table,thead,tr,th,tbody,td,Container} from 'react-bootstrap';
import ReactAudioPlayer from 'react-audio-player';
import { LineChart, Line,Label, LabelList, PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from "recharts";
import ReactToPrint from 'react-to-print';
import Loader from "react-js-loader";

const data = [
  { name: 'Positif', value: 644 },
  { name: 'Neutre', value: 254 },
  { name: 'Négatif', value: 103 },
];

const COLORS = ['#198754', '#333333', '#dc3545'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

class Audio extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        selectedFile: null,
        soundfilename:null,
        dureesound:'',
        selectedOption: null,
        listsound: [],
        listlng: [],
        persons: [],
        speakers: [],
        speaker1: [],
        speaker1sentiment: [],
        speaker2: [],
        speaker2sentiment: [],
        speakernegatif: 0.00,
        speakerneutre: 0.00,
        speakerpositif: 0.00,
        selectedValue: "",
        diarizer: [],
        nom: '',
        prenoms: '',
        mail: '',
        tokenuser:[],
        isModalthematique: false,
        isModalspeaker: false,
        idaudioselect: 0,
        thematique:'',
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        username: '',
        soundlink:'',
        soundname:'',
        soundlng:'',
        speaker1name:'',
        speaker2name:'',
        loadershow: true,
        textloading:'',
        lng: '',
        typetranscription:'',

      };

      this.selectlng = this.selectlng.bind(this);

      this.selecttypetranscription = this.selecttypetranscription.bind(this);
        
  }

  componentWillMount() {

    let nomusercache = sessionStorage.getItem('fullnameuser');
    let tokenuser = sessionStorage.getItem('tokenunsaid');
    let lnguser = sessionStorage.getItem('langueuser');

    if(lnguser === "FR"){
      this.setState({

        textloading:'Chargement en cours ...',
      
      });
    }else{
      this.setState({

        textloading:'Loading data ...',
      
      });
    }

    if(tokenuser === '' || tokenuser === 'Undefined'){
      this.setState({ tokenexist: false})
      return
    }else{

      this.setState({ tokenvalue: tokenuser,username: nomusercache, lng:lnguser})

      this.reloaddata();
      
    }

    

  }

  reloaddata () {

    if(this.state.lng === "FR"){
      document.title = "Liste des audios";
    }else{
      document.title = "Audio list";
    }

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
  
  async componentDidMount() { 

    let idsc = parseInt(sessionStorage.getItem('idsociety'));
    let idtype = parseInt(sessionStorage.getItem('typeuser'));
    let iduserselect = parseInt(sessionStorage.getItem('iduser'));
    let lnguser = sessionStorage.getItem('langueuser');
    
      if(idsc>1 && idtype==3){

        const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudioid.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc>1 && idtype==4){

        const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudioid.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();
          
        if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc==1 && idtype==4){

        const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudioiduser.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idusers:iduserselect
          })});

        const data = await response.json();

        if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }

      }
      
      if(idtype==2){

        const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudio.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }
      
      }
    
    if(lnguser === "FR"){
      const responselng = await fetch('https://zandry-info.com/unsaidtest/listlanguefr.php');
      const datatypelng = await responselng.json();
      this.setState({ listlng: datatypelng, loadershow: false})
      document.title = "Liste des audios";
    }else{
      const responselng = await fetch('https://zandry-info.com/unsaidtest/listlangueen.php');
      const datatypelng = await responselng.json();
      this.setState({ listlng: datatypelng, loadershow: false})
      document.title = "Audio list";
    }
     
    this.loaderdesactive();
    //alert(this.state.speaker2sentiment.length)

    //this.sentimentspeaker(dataspeaker);

}

async refreshlistsound() { 

    let idsc = parseInt(sessionStorage.getItem('idsociety'));
    let idtype = parseInt(sessionStorage.getItem('typeuser'));
    let iduserselect = parseInt(sessionStorage.getItem('iduser'));
    
    if(idsc>1 && idtype==3){

      const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudioid.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idsoc:idsc
        })});

      const data = await response.json();

      if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
        this.setState({ listsound: data, loadershow: false})
      }else{
        this.setState({ loadershow: false})
      }

    }

    if(idsc>1 && idtype==4){

      const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudioid.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idsoc:idsc
        })});

      const data = await response.json();

      if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
        this.setState({ listsound: data, loadershow: false})
      }else{
        this.setState({ loadershow: false})
      }

    }

    if(idsc==1 && idtype==4){

      const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudioiduser.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idusers:iduserselect
        })});

      const data = await response.json();

      if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
        this.setState({ listsound: data, loadershow: false})
      }else{
        this.setState({ loadershow: false})
      }

    }
    
    if(idtype==2){

      const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudio.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idsoc:idsc
        })});

      const data = await response.json();

      if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
        this.setState({ listsound: data, loadershow: false})
      }else{
        this.setState({ loadershow: false})
      }
     
    }

    if(this.state.lng === "FR"){
      document.title = "Liste des audios";
      this.setState({ loadershow: false})
    }else{
      document.title = "Audio list";
      this.setState({ loadershow: false})
    }



    this.loaderdesactive();

}

    handleChange = selectedOption => {
      this.setState({ selectedOption });
      //alert(`Option selected:`, selectedOption);
    };

    onFileChange = event => { 

      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
      if(event.target.files[0] !== null){
        this.setState({
          loadershow: false,
          soundfilename : event.target.files[0].name,
        });
      }

    }; 
     
    // On file upload (click the upload button) 
    onFileUpload = () => { 

      if(this.state.selectedFile === null  && this.state.lng === "FR"){

          this.setState({
            loadershow: true,
            textloading:'Veuillez séléctionner un fichier audio.'
          });
        
        return;
      
      }

      if(this.state.selectedFile === null && this.state.lng !== "FR"){

        this.setState({
          loadershow: true,
          textloading:'Please select an audio file.'
        });
      
      return;
    
    }

      if(this.state.soundlng ==='' && this.state.lng === "FR"){

          this.setState({
            loadershow: true,
            textloading:'Veuillez séléctionner la language de l\'audio.'
          });
        
        return;

      }

      if(this.state.soundlng ==='' && this.state.lng !== "FR"){

          this.setState({
            loadershow: true,
            textloading:'Please select audio language.'
          });
        
        return;

      }

      if(this.state.lng === "FR"){
        this.setState({
          loadershow: true,
          textloading:'Chargement du fichier en cours ...'
        });
      }else{
        this.setState({
          loadershow: true,
          textloading:'Loading data file ...'
        });
      }

      let iduserupload = sessionStorage.getItem('iduser');
      let idusersociety = sessionStorage.getItem('idsociety');

      if(idusersociety === '' || idusersociety === 'undefined'){
        idusersociety = 0;
      }

      // Create an object of formData 
      const formData = new FormData(); 
     
      // Update the formData object 
      formData.append( 
        "sound", 
        this.state.selectedFile, 
        this.state.selectedFile.name,
      ); 

      formData.append("iduser",iduserupload);
      formData.append("idsoc",idusersociety);
      formData.append("lngsound",this.state.soundlng);
     
      // Details of the uploaded file 
     // console.log(this.state.selectedFile); 

     
      // Request made to the backend api 
      // Send formData object 
      axios.post("https://zandry-info.com/unsaidtest/file.php", formData)
      .then(res => {
    
        //alert(JSON.stringify(res.data));
        this.setState({ listsound : res.data});

        this.loaderdesactive();

    })

  }

  loaderdesactive(){

    this.setState({ loadershow: false, selectedFile:'', soundlng:''});

    if(this.state.lng === "FR"){
      document.title = "Liste des audios";
    }else{
      document.title = "Audio list";
    }
    
  }

  async refreshlist() {
    
      let idsc = parseInt(sessionStorage.getItem('idsociety'));
      let idtype = parseInt(sessionStorage.getItem('typeuser'));
      let iduserselect = parseInt(sessionStorage.getItem('iduser'));
      
      if(idsc>1 && idtype==3){

        const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudioid.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(data.lenght>0){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc>1 && idtype==4){

        const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudioid.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(data.lenght>0){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc==1 && idtype==4){

        const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudioiduser.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idusers:iduserselect
          })});

        const data = await response.json();

        if(data.lenght>0){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }

      }
      
      if(idtype==2){

        const response = await fetch('https://zandry-info.com/unsaidtest/audio/listaudio.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(data.lenght>0){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }
      
      }
      
    }; 

    fileData = () => { 
      
      if (this.state.selectedFile) { 

    var file = this.state.selectedFile;
    
    var reader = new FileReader();

    reader.onload = function (event) {

        var audioContext = new (window.AudioContext || window.webkitAudioContext)();

        audioContext.decodeAudioData(event.target.result, function(buffer) {
            var duration = parseInt(buffer.duration);

            var h = Math.floor(duration / 3600);
            var m = Math.floor(duration % 3600 / 60);
            var s = Math.floor(duration % 3600 % 60);
        
            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            var dureetotal = "La durée de l'audio est de " + hDisplay + mDisplay + sDisplay + " secondes.";
            //this.setState({ dureesound: dureetotal });
            
            //alert(dureetotal);

        });
    };

    reader.onerror = function (event) {
        console.error("An error ocurred reading the file: ", event);
    };

    reader.readAsArrayBuffer(file);
          
        return ( 
          <div> 
            <p>Nom du fichier: {this.state.selectedFile.name}</p> 
          </div> 
        ); 
      } else { 
        return ( 
          <div> 
            <br /> 
            <h4>Veuillez choisir un fichier audio.</h4> 
          </div> 
        ); 
      } 
    }; 

    audiosend (nomaudio,nomsound,timesound,lgnsound) {
 
      let minuterestant = sessionStorage.getItem('creditrestant'); 
      let iduserdiarizeur = sessionStorage.getItem('iduser');  
     
      if(parseInt(timesound)>parseInt(minuterestant)){
        if(this.state.lng === "FR"){
          alert('Crédit insuffisant.');
          return;
        }else{
          alert('Credit not enough.');
          return;
        }
        
      }

      if(this.state.lng === "FR"){
        this.setState({

          textloading:'Transcription en cours ...',
        
        });
      }else{
        this.setState({

          textloading:'Loading transcription ...',
        
        });
      }

      this.setState({
        
        loadershow: true,
        soundlink: "http://zandry-info.com/unsaidtest/audio/" + nomaudio + ".wav",
        soundname : nomsound,
        idaudioselect : nomaudio,
      
      });

      let idsc = parseInt(sessionStorage.getItem('idsociety'));

      fetch(`https://zandry-info.com/unsaidtest/diarizer.php`,{                  

                method:'post',
                headers: {'Content-Type':'application/x-www-form-urlencoded'},
                body: queryString.stringify({nomaudio : nomaudio + '.wav', iduserdiari: iduserdiarizeur, dureeaudio : timesound,langueaudio:lgnsound, idsociete:idsc })

        })

        .then(response => response.json())
        .then(data => {

          if(data.length>0){

            let newcredit = minuterestant - timesound;

            sessionStorage.setItem(
              'creditrestant', newcredit 
            );

            this.loadpseakerafterdiarize(data)

            if(this.state.lng === "FR"){
              document.title = "Liste des audios";
            }else{
              document.title = "Audio list";
            }

          }else{

            this.setState({ diarizer: [], loadershow: false, });


            //alert(JSON.stringify(data));

          }
          //alert(data)
          //const jsonfile = JSON.parse(data)
          //alert(JSON.stringify(jsonfile.recognizedPhrases[0].nbest[3].display))
            //this.setState({ diarizer: data});

        })
        .catch(error => {
              this.setState({ diarizer: [], loadershow: false});
        });   
        
  };

  loadpseakerafterdiarize(data){

    if(data.length>0){

        this.state.speaker1.length = 0;
        this.state.speaker2.length = 0;  

        var Positif1 = 0;
        var Neutre1 = 0;
        var Negatif1 = 0;
        var Speaker1speak = 0;

        var Positif2 = 0;
        var Neutre2 = 0;
        var Negatif2 = 0;
        var Speaker2speak = 0;

        var Positif = 0;
        var Neutre = 0;
        var Negatif = 0;
        var Speaktotal = data.length;

        var SentimentGlobalePositif = 0.00;
        var SentimentGlobaleNegatif = 0.00;
        var SentimentGlobaleNeutre = 0.00;

        data.forEach((item)=>{

          if(item.sentimentspeaker === 'positive'){
            Positif++
          }

          if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
            Neutre++
          }

          if(item.sentimentspeaker === 'negative'){
            Negatif++
          }


          {/*if(parseFloat(item.sentiment)>=0.05){
            SentimentGlobalePositif+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)>-0.05 && parseFloat(item.sentiment)<0.05){
            SentimentGlobaleNeutre+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)<= -0.05){
            SentimentGlobaleNegatif+= parseFloat(item.sentiment)
          }*/}

          if(item.speaker === '1'){

            Speaker1speak++

            if(item.sentimentspeaker === 'positive'){
              Positif1++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker == 'mixed'){
              Neutre1++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif1++
            }

            this.state.speaker1.push({ idspeak: item.iddiarization, starttime:item.starttime.replace('PT','').replace('M',':').split(".", 1), sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:item.positif, Negatif:item.negatif, Neutre:item.neutre});

          }else{

            Speaker2speak++

            if(item.sentimentspeaker === 'positive'){
              Positif2++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre2++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif2++
            }

            this.state.speaker2.push({ idspeak: item.iddiarization, starttime:item.starttime.replace('PT','').replace('M',':').split(".", 1), sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:item.positif, Negatif:item.negatif, Neutre:item.neutre});

          }

        })

      }

      this.state.speaker1sentiment.push({ 
        name: 'Positif', value:  (Positif1/Speaker1speak)*100,
      });
      this.state.speaker1sentiment.push({ 
          name: 'Neutre', value: (Neutre1/Speaker1speak)*100 ,
      });
      this.state.speaker1sentiment.push({ 
        name: 'Négatif', value: (Negatif1/Speaker1speak)*100,
      });

      this.state.speaker2sentiment.push({ 
        name: 'Positif', value:  (Positif2/Speaker2speak)*100,
      });
      this.state.speaker2sentiment.push({ 
          name: 'Neutre', value: (Neutre2/Speaker2speak)*100 ,
      });
      this.state.speaker2sentiment.push({ 
        name: 'Négatif', value: (Negatif2/Speaker2speak)*100,
      });

      SentimentGlobalePositif = ((Positif/Speaktotal)*100);
      SentimentGlobaleNeutre = ((Neutre/Speaktotal)*100);
      SentimentGlobaleNegatif = ((Negatif/Speaktotal)*100);

      this.setState({
        speakers: data,
        speakerpositif: SentimentGlobalePositif.toFixed(2),
        speakerneutre: SentimentGlobaleNeutre.toFixed(2),
        speakernegatif: SentimentGlobaleNegatif.toFixed(2),
        loadershow: false,
      })

      if(this.state.lng === "FR"){
        document.title = "Liste des audios";
      }else{
        document.title = "Audio list";
      }

      this.refreshlistsound();   
       
  }
  
  sentimentvalue(idaudiospeak) {

    if(this.state.lng === "FR"){
      this.setState({

        textloading:'Chargement en cours ...',
      
      });
    }else{
      this.setState({

        textloading:'Loading data ...',
      
      });
    }

    this.setState({ 

      loadershow: true,
      soundlink: "http://zandry-info.com/unsaidtest/audio/" + idaudiospeak + ".wav",
      idaudioselect : idaudiospeak,
      

    });

    fetch(`https://zandry-info.com/unsaidtest/speaker.php`,{                  
            
            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({
              idaudio : idaudiospeak
            })

      })

      .then(response => response.json())
      .then(data => {
    
          if(data.length>0){

            this.loadpseaker(data)

          }

      })
      .catch(error => {
          this.setState({ speakers: []});
      });  
      
};   


diarizationeffectue(){

  if(this.state.lng === "FR"){
    alert('Transcription déjà effectué.')
  }else{
    alert('Transcription finish.')
  }  

}

diarizationnoneffectue(){

  if(this.state.lng === "FR"){
    alert('Transcription non effectué.')
  }else{
    alert('Transcription not finish.')
  }  

}

  loadpseaker(data){

    if(data.length>0){

        this.state.speaker1.length = 0;
        this.state.speaker2.length = 0;  

        var Positif1 = 0;
        var Neutre1 = 0;
        var Negatif1 = 0;
        var Speaker1speak = 0;

        var Positif2 = 0;
        var Neutre2 = 0;
        var Negatif2 = 0;
        var Speaker2speak = 0;

        var Positif = 0.00;
        var Neutre = 0.00;
        var Negatif = 0.00;
        var Speaktotal = data.length;

        var SentimentGlobalePositif = 0.00;
        var SentimentGlobaleNegatif = 0.00;
        var SentimentGlobaleNeutre = 0.00;

        data.forEach((item)=>{

          {/*if(parseFloat(item.sentiment)>=0.05){
            SentimentGlobalePositif+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)>-0.05 && parseFloat(item.sentiment)<0.05){
            SentimentGlobaleNeutre+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)<= -0.05){
            SentimentGlobaleNegatif+= parseFloat(item.sentiment)
          }*/}

          if(item.speaker === '1'){

            Speaker1speak++

            if(item.sentimentspeaker === 'positive'){
              Positif1++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre1++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif1++
            }

            this.state.speaker1.push({ idspeak: item.iddiarization, starttime:item.starttime.replace('PT','').replace('M',':').split(".", 1), sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:item.positif, Negatif:item.negatif, Neutre:item.neutre});

          }else{

            Speaker2speak++

            if(item.sentimentspeaker === 'positive'){
              Positif2++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre2++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif2++
            }

            this.state.speaker2.push({ idspeak: item.iddiarization, starttime:item.starttime.replace('PT','').replace('M',':').split(".", 1), sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:item.positif, Negatif:item.negatif, Neutre:item.neutre});

          }

        })

      }

      this.state.speaker1sentiment.push({ 
        name: 'Positif', value:  (Positif1/Speaker1speak)*100,
      });
      this.state.speaker1sentiment.push({ 
          name: 'Neutre', value: (Neutre1/Speaker1speak)*100 ,
      });
      this.state.speaker1sentiment.push({ 
        name: 'Négatif', value: (Negatif1/Speaker1speak)*100,
      });

      this.state.speaker2sentiment.push({ 
        name: 'Positif', value:  (Positif2/Speaker2speak)*100,
      });
      this.state.speaker2sentiment.push({ 
          name: 'Neutre', value: (Neutre2/Speaker2speak)*100 ,
      });
      this.state.speaker2sentiment.push({ 
        name: 'Négatif', value: (Negatif2/Speaker2speak)*100,
      });

      //SentimentGlobalePositif = ((Positif/Speaktotal)*100);
      ///SentimentGlobaleNeutre = ((Neutre/Speaktotal)*100);
      //SentimentGlobaleNegatif = ((Negatif/Speaktotal)*100);
      {/*speakerpositif: SentimentGlobalePositif.toFixed(2),
        speakerneutre: SentimentGlobaleNeutre.toFixed(2),
      speakernegatif: SentimentGlobaleNegatif.toFixed(2),*/}

      this.setState({
        speakers: data,
        loadershow: false,
      })

      if(this.state.lng === "FR"){
        document.title = "Liste des audios";
      }else{
        document.title = "Audio list";
      }
       
  }

  themeatiquemodal(idaudio,thematiqueupdate) {
    this.setState({ isModalthematique: true, idaudioselect : idaudio, thematique: thematiqueupdate, speakers: []});
    //this.themeatiquesound(idaudio);      
};   

  themeatiquesound () {

    fetch(`https://zandry-info.com/unsaidtest/thematique.php`,{                  

              method:'post',
              headers: {'Content-Type':'application/x-www-form-urlencoded'},
              body: queryString.stringify({idaudio : this.state.idaudioselect, thematique: this.state.thematique})

      })
      .then(response => response.json())
      .then(data => {
        //alert(JSON.stringify(data))
        const jsonfile = JSON.parse(data)
        //alert(JSON.stringify(jsonfile.recognizedPhrases[0].nbest[3].display))
          this.setState({ listsound: data, isModalthematique: false, thematique :'', idaudioselect :0 });

          this.refreshlist();

      })
      .catch(error => {
            this.setState({ listsound: [], isModalthematique: false, thematique :'', idaudioselect :0 });
      });   
      
};   

speakermodal(idaudio,speaker1update,speaker2update) {
  this.setState({ isModalspeaker: true, idaudioselect : idaudio, speaker1: speaker1update, speaker2: speaker2update});
  //this.themeatiquesound(idaudio);      
};   

speakermodalsound () {

  fetch(`https://zandry-info.com/unsaidtest/speakerupdate.php`,{                  

            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({idaudio : this.state.idaudioselect, speaker1: this.state.speaker1, speaker2: this.state.speaker2})

    })
    .then(response => response.json())
    .then(data => {
      //alert(JSON.stringify(data))
      const jsonfile = JSON.parse(data)
      //alert(JSON.stringify(jsonfile.recognizedPhrases[0].nbest[3].display))
        this.setState({ listsound: data, isModalspeaker: false, thematique :'', idaudioselect :0, speaker1: '', speaker2: '' });

        //this.refreshlist();

    })
    .catch(error => {
          this.setState({ listsound: [], isModalspeaker: false, thematique :'', idaudioselect :0, speaker1: '', speaker2: '' });
    });   
    
};   

  
ecouteraudio (soundid,soundnameshow,positifvalue,neutrevalue,negatifvalue,nomspeaker1,nomspeaker2) {

  this.setState({
    soundlink: "http://zandry-info.com/unsaidtest/audio/" + soundid + ".wav",
    soundname : soundnameshow,
    idaudioselect : soundid,
    speaker1: [],
    speaker2: [],
    speaker1name: nomspeaker1,
    speaker2name: nomspeaker2,
    speaker1sentiment: [],
    speaker2sentiment: [],
    speakerpositif: (positifvalue*100).toFixed(2),
    speakerneutre: (neutrevalue*100).toFixed(2),
    speakernegatif: (negatifvalue*100).toFixed(2),
  });

  this.sentimentvalue(soundid)

};   


scrolltospeech=(payload)=>{

  /*
  var idline = JSON.stringify(payload.payload.idspeak).replace(/\"/g, "")

  var element = document.getElementById(idline);

  if(element !== 'null' && parseInt(idline)>0){
    element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
  }

  idline = 0;

  element = 'null';

  text.slice(0, text.lastIndexOf(':'))
  
  */
     
};

selectlng(e) {
  this.setState({ soundlng: e.target.value });
}

selecttypetranscription(e) {
  this.setState({ typetranscription: e.target.value });
}



  render() {

    const handleChange = (e) => {
      this.setState({selectedValue: e.target.value})
    }

          return (
              
                <div className="App" style={{marginTop:'100px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                  <div> 

                      <div className='centerdata' > 

                        <label htmlFor="filePicker" style={{background:"#2A83E8", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}> {this.state.lng ==='FR'? 'Choisir fichier': 'Choose file'} </label>
                        <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept=".wav"/>
                    
                        {/*<input type="file" onChange={this.onFileChange} accept=".wav" />*/}
                        
                      </div>

                      {this.state.soundfilename === null ?

                          <div >
                              {this.state.lng ==='FR'? 'Aucun fichier selectionné': 'No file chosen'}                     
                          </div>

                        :

                          this.state.soundfilename

                      }

                      <p>{this.state.lng ==='FR'? 'Langue de l\'audio': 'Audio language'}  :</p>
                      <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                        <Form.Select style={{height:'40px', width:'200px' }} value={this.state.soundlng} onChange={this.selectlng}>
                          {this.state.listlng.map((lng) => (
                            <option value={lng.lng}>{this.state.lng ==='FR'? lng.languefr: lng.langueen}</option>
                          ))}
                        </Form.Select>
                      </div>

                      <p>{this.state.lng ==='FR'? 'Type de transcription': 'Transcription type'}  :</p>
                      <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                        <Form.Select style={{height:'40px', width:'200px' }} value={this.state.typetranscription} onChange={this.selecttypetranscription}>
                          <option value=""></option>
                          <option value="sentiment">{this.state.lng ==='FR'? "Analayse de sentiment": "Sentiment analysis"}</option>
                          <option value="compterendu">{this.state.lng ==='FR'? "Compte rendu de réunion": "Meeting report"}</option>
                        </Form.Select>
                      </div>

                      <div style={{margin:'10px'}}>

                      {this.state.soundlng !== '' ?

                        <button onClick={this.onFileUpload} style={{marginTop:'70px'}} className="btn btn-primary"> 
                          {this.state.lng ==='FR'? 'TELEGARGER DANS LE CLOUD ': 'UPLOADED TO THE CLOUD'} 
                        </button>

                        :

                        null
                      
                      }
                         
                      </div>

                  </div>           

                  {this.state.loadershow === true ?
                            

                      <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                        

                      :

                        <div>

                          {this.state.listsound.length === 0 ?

                          <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Pas d\'audio eregistré.' : 'No sound saved.'}&nbsp;</p>

                          :

                          null

                          }
                          
                        </div>
                      
                  }

                  {this.state.isModalthematique === true ?

                      <Modal
                      
                      show={this.state.isModalthematique}
                      onHide={ () => this.setState({isModalthematique:false})}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >
                        <Modal.Body>
                                    
                        <div className="d-flex flex-row">
                                          
                            <div className="d-flex flex-column" style={{margin:"20px"}}>
                                
                                <div className="d-flex flex-column">
                                    <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Thématique :' : 'Thematique'}&nbsp;</p>
                                    <form>
                                    <label>
                                      <textarea type="textarea" name="thematique" rows={5} style={{width:'400px'}} value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>
                                    </label>
                                    
                                    <div className='row text-white d-flex align-items-center'>
                                                
                                        <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({isModalthematique:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.themeatiquesound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                                    </div>
                                    
                                  </form>
                                </div>
                                
                            </div>

                        </div>    

                      </Modal.Body>
                      </Modal>

                      :

                      null

                  }

                  {this.state.isModalspeaker === true ?

                  <Modal
                  
                  show={this.state.isModalspeaker}
                  onHide={ () => this.setState({isModalspeaker:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>SPEAKER :&nbsp;</p>
                                <form>
                                
                                <p style={{fontWeight:"bold"}}>Speaker 1  :&nbsp;</p>
                                <input  type="text" placeholder="Speaker 1" ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                                <p>&nbsp;</p>
                                <p style={{fontWeight:"bold"}}>Speaker 2  :&nbsp;</p>
                                <input  type="text" placeholder="Speaker 2" ref="speaker2" value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>
                                
                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({isModalspeaker:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.speakermodalsound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                {this.state.speaker1.length>1000000 ?

                    <ReactToPrint
                    trigger={() => (
                      <a className="ant-btn ant-btn-primary" href="#">
                        {this.state.lng ==='FR'? 'Imprimer': 'Print'}
                      </a>
                    )}
                    content={() => this.componentRef}
                    />

                    :

                    null

                }

                <div>

                <div style={{marginTop:"20px"}}>

                  <div className="row" >
                    <div className="col" >
                        <div className="table">
                          <table className="table-responsive">
                            <thead>
                              <tr>
                              <th align="center">{this.state.lng ==="FR" ? 'Transcrire' : 'Transcribe Call'}</th>
                                <th >{this.state.lng ==="FR" ? 'ID de l\'appel' : 'ID Call'}</th>
                                <th align="center">{this.state.lng ==="FR" ? 'Mettre à jour Thématique' : 'Update Thematic'}</th>
                                <th align="center">{this.state.lng ==="FR" ? 'Thématique' : 'Thematic'}</th>
                                <th align="center">{this.state.lng ==="FR" ? 'Mettre à jour Speaker' : 'Update Speaker'}</th>
                                <th align="center">Speaker</th>
                                <th align="center">{this.state.lng ==="FR" ? 'Détails' : 'Call Details'}</th>

                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listsound.map((row) => (

                                  <tr key={row.idaudio} style={{backgroundColor: row.idaudio === this.state.idaudioselect ? "#333333": "white"}}>
                                    <td>

                                      {row.iddiarizeur === '0' ?

                                          <button type="submit" className="btn-primary btn-circle btn-xl" onClick={() => this.audiosend(row.idaudio,row.nomaudio.slice(row.nomaudio.lenght, -4),row.duree,row.langue)}><span><ReadMoreIcon /></span></button>

                                          :

                                          <button type="submit" className="btn-gris btn-circle btn-xl" onClick={() => this.diarizationeffectue()}><span><CheckBoxIcon /></span></button>

                                      }
                                      
                                    </td>
                                    <td>{row.nomaudio.slice(row.nomaudio.lenght, -4)}</td>
                                    <td ><button type="submit" className="btn-success btn-circle btn-xl" onClick={() => this.themeatiquemodal(row.idaudio,row.thematique)}><span><AssignmentIcon  style={{color:"white"}}/></span></button></td>
                                    <td >{row.thematique}</td>
                                    <td><button type="submit" className="btn-warning btn-circle btn-xl" style={{color:'white'}}onClick={() => this.speakermodal(row.idaudio,row.speaker1,row.speaker2)}><span><RecordVoiceOverIcon /></span></button></td>
                                    <td>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</td>

                                    <td>

                                      {/*
                                      {parseFloat(row.sentimentpositif)> sentimentnegatif ?

                                      <button type="submit" className="btn btn-dark btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif)}><span><VolumeUpIcon /></span></button>

                                      :

                                      null

                                      }*/}

                                      {parseFloat(row.sentimentpositif)>parseFloat(row.sentimentnegatif) ?

                                        <button type="submit" className="btn-success btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2)}><span><VolumeUpIcon /></span></button>

                                        :

                                        null

                                      }


                                      {parseFloat(row.sentimentpositif)<parseFloat(row.sentimentnegatif) ?

                                        <button type="submit" className="btn-danger btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif)}><span><VolumeUpIcon /></span></button>

                                        :

                                        null

                                      }

                                      {parseFloat(row.sentimentpositif)===parseFloat(row.sentimentnegatif) && row.iddiarizeur !== '0' ?

                                        <button type="submit" className="btn-gris btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif)}><span><VolumeUpIcon /></span></button>

                                        :

                                        null

                                      }

                                      {row.iddiarizeur === '0' ?

                                      <button type="submit" className="btn-gris btn-circle btn-xl" onClick={() => this.diarizationnoneffectue()}><span><VolumeUpIcon /></span></button>

                                      :

                                      null

                                      }
                                      
                                      {/*
                                      {row.sentimentglobale === '2' ?

                                      <button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.ecouteraudio(row.idaudio,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif)}><span><VolumeUpIcon /></span></button>

                                      :

                                      null

                                      }
                                      */}

                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>                            
                    </div>
                    
                    <div className="col" >
                        <div className="table">
                   
                    {this.state.speaker1.length>0 ?

                      <div className='listedatasentiment'>

                        <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em'}}>{this.state.soundname}</p>

                        <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em'}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Sentiment of the call'}</p>
                        
                        <div  className="audioplayer" >
                            <ReactAudioPlayer
                            src={this.state.soundlink}
                            controls
                            volume
                            />
                        </div>
                                          
                        <div >

                                <div className='listesentiment' style={{marginLeft:'5px',marginRight:'5px',marginTop:'50px'}}>

                                  <div className="champpositif">
                                    <div className="centerunsaid">
                                    <p>{this.state.lng ==='FR'? 'Positif': 'Positive'} {this.state.speakerpositif}%</p>
                                    </div>
                                  </div>
                                  
                                  <div className="champnegatif">
                                    <div className="centerunsaid">
                                      <p> {this.state.lng ==='FR'? 'Négatif': 'Negative'} {this.state.speakernegatif}%</p>
                                    </div>
                                  </div>

                                  <div className="champneutre">
                                    <div className="centerunsaid">
                                      <p>{this.state.lng ==='FR'? 'Neutre': 'Neutral'} {this.state.speakerneutre}%</p>
                                    </div>
                                  </div>

                                </div>
                                                        
                              </div>

                        <div className="container" style={{marginBottom:"70px"}}>

                            <div className="row">
                              <div className="col">
                              <p style={{color:'#333333', marginTop:'50px',fontWeight:'bold', fontSize:'1.5em',}} >Speaker 1 : {this.state.speaker1name}</p>                           
                              
                                <div className='listesentiment'>
                                  <PieChart width={300} height={300}> 
                                    <Pie  data={Object.values(this.state.speaker1sentiment)}  cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} outerRadius={80} fill="#8884d8" dataKey="value" >
                                      {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        
                                      ))}
                                      {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                    </Pie>
                                    <Tooltip/>
                                    <Legend />
                                  </PieChart>
                                </div>

                                {/*<div className='listesentiment'>            
                                    <AreaChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="starttime" />
                                      <YAxis domain={[-1.00, 1.00]}/>
                                      <Tooltip />
                                      <Area type="monotone" dataKey="sentiment" stroke="#333333" fill="#333333" activeDot={{ r: 8 }} />
                                    </AreaChart>
                                  </div>*/}
                                  <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em'}}>{this.state.lng ==="FR" ? 'Evolution du sentiment de l\'appel' : 'Evolution of the call sentiment'}</p>

                                  <div className='listesentiment' style={{marginTop:"20px"}}> 

                                    <LineChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="starttime" />
                                      <YAxis domain={[0.00, 1.00]}/>
                                      <Tooltip />
                                      <Line type="monotone" dataKey="Positif" stroke="#198754" fill="#198754" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                      <Line type="monotone" dataKey="Neutre" stroke="#333333"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                      <Line type="monotone" dataKey="Negatif" stroke="#dc3545"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                    </LineChart>
                                  </div>
                                  
                              </div>


                              <div className="col" style={{marginTop:"20px"}}>
                              <p style={{color:'#2A83E8', textAlign:'center', fontWeight:'bold', fontSize:'1.5em',marginTop:'50px'}} >Speaker 2 : {this.state.speaker2name}</p>

                                  <div className='listesentiment'>
                                    <PieChart width={400} height={300}> 
                                      <Pie  data={Object.values(this.state.speaker2sentiment)}  cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} outerRadius={80} fill="#8884d8" dataKey="value" >
                                        {data.map((entry, index) => (
                                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                          
                                        ))}
                                        {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                      </Pie>
                                      <Tooltip/>
                                      <Legend />
                                    </PieChart>
                                  </div>

                                  {/*<div className='listesentiment'>
                                    <AreaChart width={350} height={250} data={Object.values(this.state.speaker2)}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="starttime" />
                                      <YAxis domain={[-1.00, 1.00]}/>
                                      <Tooltip />
                                      <Area type="monotone" dataKey="sentiment" stroke="#2A83E8" fill="#2A83E8" activeDot={{ r: 8 }} />
                                    </AreaChart>
                                    /div>*/}
                                  <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em'}}>{this.state.lng ==="FR" ? 'Evolution du sentiment de l\'appel' : 'Evolution of the call sentiment'}</p>

                                  <div className='listesentiment' style={{marginTop:"20px"}}>            
                                    <LineChart width={350} height={250} data={Object.values(this.state.speaker2)} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="starttime" />
                                      <YAxis domain={[0.00, 1.00]}/>
                                      <Tooltip />
                                      <Line type="monotone" dataKey="Positif" stroke="#198754" fill="#198754" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                      <Line type="monotone" dataKey="Neutre" stroke="#333333" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                      <Line type="monotone" dataKey="Negatif" stroke="#dc3545" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload) }} />
                                    </LineChart>
                                  </div>

                              </div>

                              <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em', marginTop:'50px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>

                              <div className='reponsediarization' ref={el => (this.componentRef = el)}>

                                  {this.state.speakers.map((row) => (

                                            <div key={row.iddiarization}>

                                                <div> {row.speaker === '1' ?
                                                                        
                                                <div className='textleft' id={parseInt(row.iddiarization)}>

                                                  <div style={{width:'80%', textAlign:'left', paddingRight:'20px'}}>
                                                    <p >{row.speaker1} ({row.starttime.replace('PT','').replace('M',':').split(".", 1)})</p>
                                                    <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                    <p>{this.state.lng ==='FR'? 'Pos.': 'Pos.'}: {parseFloat(row.positif).toFixed(2)*100} % , {this.state.lng ==='FR'? 'Neu.': 'Neu.'}: {parseFloat(row.neutre).toFixed(2)*100} % , {this.state.lng ==='FR'? 'Nég.': 'Neg.'}: {parseFloat(row.negatif).toFixed(2)*100} %</p>
                                                  </div>
                                                
                                                  <div style={{ width:'20%', margin:'auto'}}>

                                                    {row.sentimentspeaker ==='positive' ?

                                                    <button type="button" className="btnpositif btn-sentiment">{this.state.lng ==='FR'? 'Positif': 'Positive'}  ({parseFloat(row.positif)*100} %)</button>
                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='neutral' ?

                                                    <button type="button" className="btnneutre btn-sentiment">{this.state.lng ==='FR'? 'Neutre': 'Neutral'}  ({parseFloat(row.neutre)*100} %)</button>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='mixed' ?

                                                    <button type="button" className="btnmixed btn-sentiment">{this.state.lng ==='FR'? 'Mixte': 'Mixed'}</button>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='negative' ?

                                                    <button type="button" className="btnnegatif btn-sentiment">{this.state.lng ==='FR'? 'Négatif': 'Negative'}  ({parseFloat(row.negatif)*100} %)</button>

                                                    :

                                                    null

                                                    }

                                                  </div>

                                                      
                                                  
                                                </div>
                        
                                                  :
                        
                                                <div className='textright' id={parseInt(row.iddiarization)} >

                                                  <div style={{width:'80%', textAlign:'left', paddingRight:'20px'}}>
                                                    <p >{row.speaker2} ({row.starttime.replace('PT','').replace('M',':').split(".", 1)})</p>
                                                    <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                    <p>{this.state.lng ==='FR'? 'Pos.': 'Pos.'}: {parseFloat(row.positif).toFixed(2)*100} % , {this.state.lng ==='FR'? 'Neu.': 'Neu.'}: {parseFloat(row.neutre).toFixed(2)*100} % , {this.state.lng ==='FR'? 'Nég.': 'Neg.'}: {parseFloat(row.negatif).toFixed(2)*100} %</p>
                                                  </div>
                                                  
                                                      <div style={{ width:'20%', margin:'auto'}}>
                                                      
                                                      {row.sentimentspeaker ==='positive' ?

                                                        <button type="button" className="btnpositif btn-sentiment">{this.state.lng ==='FR'? 'Positif': 'Positive'} ({parseFloat(row.positif)*100} %)</button>
                                                        :

                                                        null

                                                        }

                                                        {row.sentimentspeaker ==='neutral' ?

                                                        <button type="button" className="btnneutre btn-sentiment">{this.state.lng ==='FR'? 'Neutre': 'Neutral'} ({parseFloat(row.neutre)*100} %)</button>

                                                        :

                                                        null

                                                        }

                                                        {row.sentimentspeaker ==='mixed' ?

                                                        <button type="button" className="btnmixed btn-sentiment">{this.state.lng ==='FR'? 'Mixte': 'Mixed'}</button>

                                                        :

                                                        null

                                                        }

                                                        {row.sentimentspeaker ==='negative' ?

                                                        <button type="button" className="btnnegatif btn-sentiment">{this.state.lng ==='FR'? 'Négatif': 'Negative'} ({parseFloat(row.negatif)*100} %)</button>

                                                        :

                                                        null

                                                        }

                                                      </div>
                                                                                                        
                                                </div>
                        
                                                                      
                                              }
                                              
                                              </div>

                                              
                                            </div>

                                        
                                  ))}

                                </div>

                            </div>

                        </div>
                                                
                      </div>

                      :
                      null

                      }
                        
                      
                    </div>
                    </div>

                  </div>

              </div>

                </div>  
                
                </div>
          );
        }
    
}

export default Audio;

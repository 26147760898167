import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {  BarChart, Bar,LineChart, Line,Label, LabelList, PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from "recharts";
import { isBrowser } from 'react-device-detect';
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");


const COLORS = ['#00934c', '#e89900', '#e40001'];

const COLORSCHART = ['#00934c', '#e89900', '#e40001'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

var datapie= [];
var datapieload= [];
var datalinechart= [];
var datalinechartload= [];
var datalinechartreport= [];
var datalinechartloadtreport= [];

var errocode = 0;


class Dashboard extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        listjargon: [],
        modalupdate: false,
        modaldelete: false,
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        nbreuser:0,
        nbrecenter:0,
        nbreaudio:0,
        audionotdiarized:0,
        credit:0.00,
        loadingdata: true,

      };

  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
    
    fetch(wbslinkload + `getdatauser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {

        if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

          document.location.href = "/Buycredit";

        }else{

          this.setState({
            loadingdata:false
          })
          
        }
        
    })

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    if(this.state.lng== 'FR'){

      document.title = "Tableau de bord";

    }else{

      document.title = "Dashboard";

    }

    datalinechart = [];
    datalinechartload = [];
    datalinechartreport = [];
    datalinechartloadtreport = [];
    datapieload = [];

    let token = sessionStorage.getItem('tokenunsaid');
  
    const myDecodedToken = decodeToken(token);

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("datemargin",'all');

    fetch(this.state.wbslink + `dashboard`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
    .then(data => {

      this.setState({
        nbreuser:parseInt(JSON.stringify(data.nbreuser).replace(/\"/g, "")),
        nbrecenter:parseInt(JSON.stringify(data.nbrecenter).replace(/\"/g, "")),
        nbreaudio:parseInt(JSON.stringify(data.audio).replace(/\"/g, "")),
        audionotdiarized:parseInt(JSON.stringify(data.audionotdiarized).replace(/\"/g, "")),
        credit:parseFloat(JSON.stringify(data.credit).replace(/\"/g, "")),
      });

      datalinechart = [];
      datalinechartload = [];
    
      if(data.linechart.length>0){

       
        data.linechart.forEach((item)=>{

          if(parseFloat(item.negatif) != 0 && parseFloat(item.neutre) != 0 && parseFloat(item.neutre) != 0) {

            let dategraphe = "";

            let negatifper = (parseFloat(item.negatif)/(parseFloat(item.negatif) + parseFloat(item.neutre) + parseFloat(item.neutre)))*100;
            let neutrefper = (parseFloat(item.neutre)/(parseFloat(item.negatif) + parseFloat(item.neutre) + parseFloat(item.positif)))*100;
            let positifper = (parseFloat(item.positif)/(parseFloat(item.negatif) + parseFloat(item.neutre) + parseFloat(item.positif)))*100;

            let totalper = 0.00;
            totalper = parseFloat(negatifper.toFixed(2)) + parseFloat(neutrefper.toFixed(2)) + parseFloat(positifper.toFixed(2));

          if(totalper >100.00){

            let differentper = totalper - 100.00;

            if(positifper>neutrefper && positifper>negatifper){

              positifper -= differentper

            }

            if(neutrefper>positifper && neutrefper>negatifper){

              neutrefper -= differentper

            }

            if(negatifper>neutrefper && negatifper>negatifper){

              negatifper -= differentper

            }

          }

          if(totalper <100.00){

            let differentper = 100.00 - totalper;

            if(positifper>neutrefper && positifper>negatifper){

              positifper += differentper

            }

            if(neutrefper>positifper && neutrefper>negatifper){

              neutrefper += differentper

            }

            if(negatifper>neutrefper && negatifper>negatifper){

              negatifper += differentper

            }

          }
            
            datalinechartload.push({
                Datetranscription:dategraphe,
                neg:negatifper.toFixed(2),
                neu:neutrefper.toFixed(2),
                pos:positifper.toFixed(2),
              })
  
          }
  
        })
  
        datalinechart = datalinechartload;

        datalinechart = datalinechartload;

        datapie = datapieload;
        datapieload = [];
  
      }else{
  
        datapie = [];
        datapieload = [];
        datalinechart = [];
        
      }

      datalinechartreport = [];
      datalinechartloadtreport = [];

      if(data.linechartreport.length>0){

        data.linechartreport.forEach((item)=>{

          let dategraphe = "";
  
          dategraphe = item.Datetranscription;
          
          datalinechartloadtreport.push({
              Datetranscription: dategraphe,
              Report: parseInt(item.count)
            })
  
        })
  
        datalinechartreport = datalinechartloadtreport;

        datalinechartreport = datalinechartloadtreport;
  
      }else{

        datalinechartreport = [];
        datalinechartloadtreport = [];
        
      }
      
      
      if(JSON.stringify(data).replace(/"/g, "") !== '0'){

        this.setState({ listdata:data,idsociete: 0, question:'', modaladd: false})
  
      }else{
        this.setState({ loadershow: false})
      }

  })

  .catch(err => {
  
    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  


  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idjargon: 0,
    wordjargon: '',
    textloading:''
  });
  
}


async reloaddata(datemargin){

  if(this.state.lng== 'FR'){

    document.title = "Tableau de bord";

  }else{

    document.title = "Dashboard";

  }

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("datemargin",datemargin);

  fetch(this.state.wbslink + `dashboard`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData
    
    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
  .then(data => {

    datalinechart = [];
    datalinechartload = [];

    datalinechartreport = [];
    datalinechartloadtreport = [];

    var d=new Date();
    var weekday=new Array(7);
    weekday[0]= this.state.lng == 'FR'? "Dimanche": "Sunday";
    weekday[1]=this.state.lng == 'FR'? "Lundi": "Monday";
    weekday[2]=this.state.lng == 'FR'? "Mardi": "Tuesday";
    weekday[3]=this.state.lng == 'FR'? "Mecredi": "Wednesday";
    weekday[4]=this.state.lng == 'FR'? "Jeudi": "Thursday";
    weekday[5]=this.state.lng == 'FR'? "Vendredi": "Friday";
    weekday[6]=this.state.lng == 'FR'? "Samedi": "Saturday";

    var n = weekday[d.getDay()];

    this.setState({
      nbreuser:parseInt(JSON.stringify(data.nbreuser).replace(/\"/g, "")),
      nbrecenter:parseInt(JSON.stringify(data.nbrecenter).replace(/\"/g, "")),
      nbreaudio:parseInt(JSON.stringify(data.audio).replace(/\"/g, "")),
      audionotdiarized:parseInt(JSON.stringify(data.audionotdiarized).replace(/\"/g, "")),
      credit:parseFloat(JSON.stringify(data.credit).replace(/\"/g, "")),
    });

    {/*
    datapieload.push({ name: 'neg', value: parseFloat(JSON.stringify(data.sentiment[0].negatif).replace(/\"/g, ""))});
    datapieload.push({ name: 'neu', value: parseFloat(JSON.stringify(data.sentiment[0].neutre).replace(/\"/g, "")) });
    datapieload.push({name: 'pos', value: parseFloat(JSON.stringify(data.sentiment[0].positif).replace(/\"/g, "")) });
    */}

    if(datapieload.length>0){

      datapie = datapieload;
      datapieload = [];

    }else{

      datapie = [];
      datapieload = [];
      
    }
    
    if(data.linechart.length>0){
      
      data.linechart.forEach((item)=>{

        if(parseFloat(item.negatif) != 0 && parseFloat(item.neutre) != 0 && parseFloat(item.neutre) != 0) {

          let dategraphe = "";

          if(datemargin == "week"){
            dategraphe = weekday[new Date(item.Datetranscription).getDay()];
          }

          if(datemargin == "month"){
            dategraphe = item.Datetranscription;
          }
    
          if(datemargin == "year"){
            dategraphe = item.Datetranscription;
          }     

          if(datemargin == "all"){
            dategraphe = item.Datetranscription;
          }

          let negatifper = (parseFloat(item.negatif)/(parseFloat(item.negatif) + parseFloat(item.neutre) + parseFloat(item.neutre)))*100;
          let neutrefper = (parseFloat(item.neutre)/(parseFloat(item.negatif) + parseFloat(item.neutre) + parseFloat(item.positif)))*100;
          let positifper = (parseFloat(item.positif)/(parseFloat(item.negatif) + parseFloat(item.neutre) + parseFloat(item.positif)))*100;

          let totalper = 0.00;
          totalper = parseFloat(negatifper.toFixed(2)) + parseFloat(neutrefper.toFixed(2)) + parseFloat(positifper.toFixed(2));

          if(totalper >100){

            let differentper = totalper - 100;

            if(positifper>neutrefper && positifper>negatifper){

              positifper -= differentper

            }

            if(neutrefper>positifper && neutrefper>negatifper){

              neutrefper -= differentper

            }

            if(negatifper>neutrefper && negatifper>negatifper){

              negatifper -= differentper

            }

          }

          if(totalper <100){

            let differentper = 100 - totalper;

            if(positifper>neutrefper && positifper>negatifper){

              positifper += differentper

            }

            if(neutrefper>positifper && neutrefper>negatifper){

              neutrefper += differentper

            }

            if(negatifper>neutrefper && negatifper>negatifper){

              negatifper += differentper

            }

          }

          
          datalinechartload.push({
              Datetranscription:dategraphe,
              neg:negatifper.toFixed(2),
              neu:neutrefper.toFixed(2),
              pos:positifper.toFixed(2),
            })

        }
        
      })

      datalinechart = datalinechartload;
      datalinechart = datalinechartload;

    }else{

      datalinechart = [];

      if(this.state.lng== 'FR'){

        alert("Pas d'audio transcrit.")
    
      }else{
    
        alert("No sound transcript.")
    
      }

    }

    if(data.linechartreport.length>0){

  
      data.linechartreport.forEach((item)=>{

        let dategraphe = "";

        dategraphe = item.Datetranscription;
        
        datalinechartloadtreport.push({
            Datetranscription: dategraphe,
            Report: parseInt(item.data)

          })

      })

      datalinechartreport = datalinechartloadtreport;

      //datalinechartreport = datalinechartloadtreport;

    }else{

      if(this.state.lng== 'FR'){

        alert("Pas d'audio transcrit.")
    
      }else{
    
        alert("No sound transcript.")
    
      }

      datalinechartreport = [];
      datalinechartloadtreport = [];
      
    }
    
    if(JSON.stringify(data).replace(/"/g, "") !== '0'){

      this.setState({ listdata:data,idsociete: 0, question:'', modaladd: false})

    }else{
      this.setState({ loadershow: false})
    }

})

.catch(err => {

  if(errocode == 401 || errocode == 0){
    
    sessionStorage.removeItem("tokenunsaid");
    document.location.href = "/login";

    return

  }

  alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

  this.setState({
    loadershow: false
  })

});  

}

    
  render() {

          return (

              <div>

                {this.state.loadingdata == true?

                  null
                
                  :

                  <div className="dashboard">
                  
                    <div className='d-flex flex-row justify-content-center' >

                      <div className='datauser'>
                        <p style={{textAlign:isBrowser?"left":"center",color:"grey",fontSize:"20px"}}>{this.state.lng ==="FR" ? 'Utilisateurs' : 'Users'}<p style={{textAlign:isBrowser?"left":"center",color:"white",fontSize:isBrowser?"28px":"24px",paddingBottom:"20px"}}>{this.state.nbreuser}</p></p>
                      </div>

                      <div className='datauser'>
                        <p style={{textAlign:isBrowser?"left":"center",color:"grey"}}>{this.state.lng ==="FR" ? 'Centres' : 'Centers'}<p style={{textAlign:isBrowser?"left":"center",color:"white",fontSize:isBrowser?"28px":"24px"}}>{this.state.nbrecenter}</p></p>        
                      </div>

                      <div className='datauser' >
                        {/*<p style={{textAlign:isBrowser?"left":"center",color:"grey"}}>{this.state.lng ==="FR" ? 'Crédit' : 'Credit'}<p style={{textAlign:isBrowser?"left":"center",color:"white",fontSize:isBrowser?"28px":"24px"}}>{Math.floor(this.state.credit / (3600 * 24))}d {Math.floor((this.state.credit % (3600 * 24)) / 3600)}h {Math.floor((this.state.credit % 3600) / 60)}min {Math.round(((this.state.credit / 60)-Math.floor(this.state.credit / 60))*60)}s</p></p>  */}
                        <p style={{textAlign:isBrowser?"left":"center",color:"grey"}}>{this.state.lng ==="FR" ? 'Crédit' : 'Credit'}<p style={{textAlign:isBrowser?"left":"center",color:"white",fontSize:isBrowser?"28px":"24px"}}> {(parseFloat(this.state.credit).toFixed(2))} €</p></p>  
                      </div>
                    
                    </div>
                    
                    {isBrowser?

                        <div className='d-flex flex-row justify-content-center' >

                        <div className='datastat' style={{borderColor:"white"}}>
                          <p style={{textAlign:"center",color:"grey"}}>{this.state.lng ==="FR" ? 'Total fichiers' : 'Total files'}</p>
                          <p style={{textAlign:"center",color:"white",fontSize:"28px", lineHeight:"0px"}}>{this.state.nbreaudio}</p>
                        </div>

                        <div className='datastat' style={{borderColor:"#2A83E8"}}>
                          <p style={{textAlign:"center",color:"grey"}}>{this.state.lng ==="FR" ? 'Fichiers complets' : 'Complete files'}</p>
                          <p style={{textAlign:"center",color:"white",fontSize:"28px", lineHeight:"0px"}}>{parseInt(this.state.nbreaudio)-parseInt(this.state.audionotdiarized)}</p>
                        </div>

                        <div className='datastat' style={{borderColor: parseInt(this.state.audionotdiarized)>0?"#dc3545":"#2A83E8"}}>
                          <p style={{textAlign:"center",color:"grey"}}>{this.state.lng ==="FR" ? 'Votre action requise' : 'User action required'}</p>
                          <p style={{textAlign:"center",color:"white",fontSize:"28px", lineHeight:"0px"}}>{this.state.audionotdiarized}</p>
                        </div>
                        
                        </div>

                      
                      :

                        <div className='d-flex flex-column justify-content-center align-items-center' style={{marginTop:"30px"}}>

                        <div className='datastat' style={{borderColor:"white"}}>
                          <p style={{textAlign:"center",color:"grey"}}>{this.state.lng ==="FR" ? 'Total fichiers' : 'Total files'}</p>
                          <p style={{textAlign:"center",color:"white",fontSize:"28px", lineHeight:"0px"}}>{this.state.nbreaudio}</p>
                        </div>

                        <div className='datastat' style={{borderColor:"#2A83E8"}}>
                          <p style={{textAlign:"center",color:"grey"}}>{this.state.lng ==="FR" ? 'Fichiers complets' : 'Complete files'}</p>
                          <p style={{textAlign:"center",color:"white",fontSize:"28px", lineHeight:"0px"}}>{parseInt(this.state.nbreaudio)-parseInt(this.state.audionotdiarized)}</p>
                        </div>

                        <div className='datastat' style={{borderColor: parseInt(this.state.audionotdiarized)>0?"#dc3545":"#2A83E8"}}>
                          <p style={{textAlign:"center",color:"grey"}}>{this.state.lng ==="FR" ? 'Votre action requise' : 'User action required'}</p>
                          <p style={{textAlign:"center",color:"white",fontSize:"28px", lineHeight:"0px"}}>{this.state.audionotdiarized}</p>
                        </div>
                        
                        </div>


                    }

                    
                    {isBrowser?

                    <div className=' d-flex flex-row justify-content-center' >
                      <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('all')}>{this.state.lng ==="FR" ? 'Tous les audios' : 'All audios'}</Button>
                      <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('year')}>{this.state.lng ==="FR" ? 'J-365' : 'D-365'}</Button>
                      <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('month')}>{this.state.lng ==="FR" ? 'J-31' : 'D-31'}</Button>
                      <Button style={{width:"160px", margin:"20px"}} onClick={() => this.reloaddata('week')}>{this.state.lng ==="FR" ? 'J-7' : 'D-7'}</Button>
                    </div>

                    :

                    <div className=' d-flex flex-row justify-content-center'style={{marginTop:"10px"}}>
                      <Button style={{width:"70px",margin:"5px",padding:"0px"}} onClick={() => this.reloaddata('all')}>{this.state.lng ==="FR" ? 'Tous' : 'All'}</Button>
                      <Button style={{width:"70px",margin:"5px",padding:"0px"}} onClick={() => this.reloaddata('year')}>{this.state.lng ==="FR" ? 'J-365' : 'D-365'}</Button>
                      <Button style={{width:"70px",margin:"5px",padding:"0px"}} onClick={() => this.reloaddata('month')}>{this.state.lng ==="FR" ? 'J-31' : 'D-31'}</Button>
                      <Button style={{width:"70px",margin:"5px",padding:"0px"}} onClick={() => this.reloaddata('week')}>{this.state.lng ==="FR" ? 'J-7' : 'D-7'}</Button>
                    </div>

                    }

                    {isBrowser?
                    
                          <div className=' d-flex justify-content-center linechart' style={{marginTop:"20px"}}>

                          {/*
                        
                        <PieChart width={350} height={350} style={{marginTop:"-50px",marginBottom:"-50px"}}>                                     
                            <Pie data={datapie}  innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#e89900" dataKey="value" >
                            
                              {datapie.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Label value="Sentiment" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em', color:"#e40001"}}/>
                          <Tooltip/>
                          <Legend />
                        </PieChart>
                          */}

                        {datalinechart.length>0?

                            <ResponsiveContainer width="40%" height={500}>
                            {/** 
                             <LineChart
                              data={datalinechart}
                            style={{marginTop:"0px",marginBottom:"0px"}}

                            >
                              
                              <XAxis dataKey="Datetranscription"  angle={0}  />
                              <YAxis />
                              <Tooltip />
                              <Legend layout="horizontal" verticalAlign="top" align="center" />
                              <Line type="monotone" dataKey="neg" stroke="#e40001" activeDot={{ r: 8 }}  />
                              <Line type="monotone" dataKey="neu" stroke="#e89900" />
                              <Line type="monotone" dataKey="pos" stroke="#198754" />
                            </LineChart>
                            */}
                            

                              <BarChart width={350} margin={{ top: 10, right: 60, left: 20, bottom: 5}}  data={Object.values(datalinechart)}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Datetranscription" angle={-45} textAnchor="end" height={80}/>
                                <YAxis  allowDecimals={false} domain={[0, 100]} tickFormatter={(tick) => {return `${tick} %`;}} />
                                <Tooltip />
                                <Legend layout="horizontal" verticalAlign="top" align="center" />
                                <Bar type="monotone" dataKey="neg" stackId="1" stroke="#e40001" fill="#e40001" />
                                <Bar type="monotone" dataKey="neu" stackId="1" stroke="#e89900" fill="#e89900" />
                                <Bar type="monotone" dataKey="pos" stackId="1" stroke="#198754" fill="#198754" />
                              </BarChart>
                            </ResponsiveContainer>

                            :

                            null

                        }          

                        {datalinechartreport.length>0?

                        <ResponsiveContainer width="40%" height={500}>
                        {/** 
                         <LineChart
                          data={datalinechart}
                        style={{marginTop:"0px",marginBottom:"0px"}}

                        >
                          
                          <XAxis dataKey="Datetranscription"  angle={0}  />
                          <YAxis />
                          <Tooltip />
                          <Legend layout="horizontal" verticalAlign="top" align="center" />
                          <Line type="monotone" dataKey="neg" stroke="#e40001" activeDot={{ r: 8 }}  />
                          <Line type="monotone" dataKey="neu" stroke="#e89900" />
                          <Line type="monotone" dataKey="pos" stroke="#198754" />
                        </LineChart>
                        */}

                          <BarChart width={350} margin={{ top: 10, right: 60, left: 20, bottom: 5}}  data={Object.values(datalinechartreport)}>
                            
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Datetranscription" angle={-45} textAnchor="end" height={80} />
                                <YAxis allowDecimals={false}/>
                                <Tooltip />
                                <Legend layout="horizontal" verticalAlign="top" align="center" />
                                <Bar dataKey="Report" stackId="1" stroke="#2A83E8" fill="#2A83E8"/>
                                                
                          </BarChart>

                      
                        </ResponsiveContainer>

                        :

                        null

                        }          
      
                      </div>

                      
                      :

                        <div className=' d-flex justify-content-center linechartmobile' style={{marginTop:"20px"}}>

                          {/*
                        
                        <PieChart width={350} height={350} style={{marginTop:"-50px",marginBottom:"-50px"}}>                                     
                            <Pie data={datapie}  innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#e89900" dataKey="value" >
                            
                              {datapie.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Label value="Sentiment" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em', color:"#e40001"}}/>
                          <Tooltip/>
                          <Legend />
                        </PieChart>
                          */}

                        {datalinechart.length>0?

                            <ResponsiveContainer width="90%" height={400}>
                            {/** 
                             <LineChart
                              data={datalinechart}
                            style={{marginTop:"0px",marginBottom:"0px"}}

                            >
                              
                              <XAxis dataKey="Datetranscription"  angle={0}  />
                              <YAxis />
                              <Tooltip />
                              <Legend layout="horizontal" verticalAlign="top" align="center" />
                              <Line type="monotone" dataKey="neg" stroke="#e40001" activeDot={{ r: 8 }}  />
                              <Line type="monotone" dataKey="neu" stroke="#e89900" />
                              <Line type="monotone" dataKey="pos" stroke="#198754" />
                            </LineChart>

                            <AreaChart
                              data={datalinechart}
                              style={{marginTop:"0px",marginBottom:"0px"}}
                              >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Datetranscription" angle={-45} textAnchor="end" height={80}/>
                            <YAxis  domain={[0, 100]} tickFormatter={(tick) => {return `${tick} %`;}} />
                            <Tooltip />
                            <Legend layout="horizontal" verticalAlign="top" align="center" />
                            <Area type="monotone" dataKey="neg" stackId="1" stroke="#e40001" fill="#e40001" />
                            <Area type="monotone" dataKey="neu" stackId="1" stroke="#e89900" fill="#e89900" />
                            <Area type="monotone" dataKey="pos" stackId="1" stroke="#198754" fill="#198754" />
                            </AreaChart>

                            */}

                            
                              <BarChart width={350} margin={{ top: 10, right: 60, left: 20, bottom: 5}}  data={Object.values(datalinechart)}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis  dataKey="Datetranscription" angle={-45} textAnchor="end" height={80}/>
                                <YAxis  allowDecimals={false} domain={[0, 100]} tickFormatter={(tick) => {return `${tick} %`;}} />
                                <Tooltip />
                                <Legend layout="horizontal" verticalAlign="top" align="center" />
                                <Bar type="monotone" dataKey="neg" stackId="1" stroke="#e40001" fill="#e40001" />
                                <Bar type="monotone" dataKey="neu" stackId="1" stroke="#e89900" fill="#e89900" />
                                <Bar type="monotone" dataKey="pos" stackId="1" stroke="#198754" fill="#198754" />
                              </BarChart>

                            
                            </ResponsiveContainer>

                            :

                            null

                        }          

                        {datalinechartreport.length>0?

                        <ResponsiveContainer width="90%" height={400}>
                        {/** 
                         <LineChart
                          data={datalinechart}
                        style={{marginTop:"0px",marginBottom:"0px"}}

                        >
                          
                          <XAxis dataKey="Datetranscription"  angle={0}  />
                          <YAxis />
                          <Tooltip />
                          <Legend layout="horizontal" verticalAlign="top" align="center" />
                          <Line type="monotone" dataKey="neg" stroke="#e40001" activeDot={{ r: 8 }}  />
                          <Line type="monotone" dataKey="neu" stroke="#e89900" />
                          <Line type="monotone" dataKey="pos" stroke="#198754" />
                        </LineChart>
                        */}

                          
                        
                          <BarChart width={350} height={200} margin={{ top: 10, right: 60, left: 20, bottom: 5}}  data={Object.values(datalinechartreport)}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Datetranscription" angle={-45} textAnchor="end" height={80}/>
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                                <Legend layout="horizontal" verticalAlign="top" align="center" />
                                <Bar dataKey="Report" stackId="1" stroke="#2A83E8" fill="#2A83E8"/>
                                                
                          </BarChart>

                      
                        </ResponsiveContainer>

                        :

                        null

                        }          
      
                      </div>
                  
                    }
                   
                </div>

              
                }

              </div>           
                
          );
        }
    
}

export default Dashboard;

import React,  {Alert,Component} from 'react';
import './moncss.css';
import {MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Map from "./Map";


class KPIGlobale extends Component{
    
    constructor(props) {
    super(props);

    this.state = {
 
      lat: '',
      lng: '',
      keyMAP: Math.random(),
       
    };

        
  }
    
  componentDidMount() {
    
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function(position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
      });
    }
          
  };
   
    
  render() {

    const position = [this.state.lat, this.state.lng];

          return (
              
                <div className="App">
                  
                  <div className="cartemap">
                                    
                  <Map />
                  </div>
                               
                    

                </div>
          );
        }
    
}

export default KPIGlobale;


import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactPaginate from 'react-paginate';
import Card from 'react-bootstrap/Card'
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

var listdata=[];

class Society extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        listcenter: [],
        modaladd:false,
        modalupdate: false,
        modaldelete: false,
        idsocietycenter:0,
        idcenter:0,
        namecenter:'',
        latcenter:'',
        longcenter:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        listuserinit: [],
        filterdata:'',
        offset: 0,
        listuserpage: [],
        perPage: 5,
        currentPage: 0,
        loadingdata: true,
        
      };

      this.handlePageClick = this.handlePageClick.bind(this);

  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listcenterpage)
    });

};

  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listcenter:slice
  })

  window.scrollTo(0, 0)

  }

  filterlist(e){

  
    this.setState({filterdata: e})
  
    if(e.lenght == 0){
      this.setState({listcenter: this.state.listcenterinit})
      return;
    }
  
    const filteredItems = this.state.listcenterinit.filter((centerlist) => {
      return (centerlist.name).toLowerCase().includes(e.toLowerCase()) || (centerlist.nomsociete).toLowerCase().includes(e.toLowerCase()) || (centerlist.latitude).toLowerCase().includes(e.toLowerCase()) || (centerlist.longitude).toLowerCase().includes(e.toLowerCase());
    });
  
    
    if(filteredItems.length>0){
  
      this.setState({listcenter: filteredItems})
  
      var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            this.setState({
                pageCount: Math.ceil(filteredItems.length / this.state.perPage),
                listcenter:slice,
                listcenterpage:filteredItems,
            })
  
    }else{
  
      this.setState({listcenter: [], listcenterpage:[]})
  
    }
   
    
  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    
    this.setState({
      wbslink:wbslinkload,
    
    })

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(tokenuser);

      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(tokenuser);

      if(isMyTokenExpired == true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

  let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
      
      fetch(wbslinkload + `getdatauser`,{                  
            
        mode: 'cors',     
        method:'post',
        headers: {'Authorization': 'Bearer '+ tokencredit},

        })

        .then(res => {

          errocode = res.status;
    
          if (!res.ok) {
              
              return res.json().then(json => { throw json; });
    
          }
    
          return res.json();
    
        })
        .then(data => {
          
          if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

            document.location.href = "/Buycredit";

          }else{

            this.setState({
              loadingdata:false
            })
            
          }
          
      })
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let lnguser = sessionStorage.getItem('langueuser');

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    const response = await fetch(this.state.wbslink + 'center',{                       
      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") !== '0'){

      this.setState({ listcenter: data, listcenterinit: data,listcenterpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ 	idsociete :item.idsociete ,nomsociete:item.nomsociete});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listcenterinit :data,
          listcenter:slice
      })
    
    }

    if(lnguser === "FR"){
      document.title = "Liste des sociétes";
    }else{
      document.title = "Society list";
    }

    return;

    let idtype = parseInt(sessionStorage.getItem('typeuser'));

      if(idtype>2){
  
        let idsc = sessionStorage.getItem('idsociety');
  
        const response = await fetch(this.state.wbslink + 'societycenter/listcenterbysociety.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});
  
        const data = await response.json();
  
        if(JSON.stringify(data).replace(/"/g, "") !== '0'){

          this.setState({ listcenter: data, listcenterinit: data,listcenterpage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ 	idsociete :item.idsociete ,nomsociete:item.nomsociete});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              listcenterinit :data,
              listcenter:slice
          })

        }
    
        }else{
          
          const responsesocietecentre = await fetch(this.state.wbslink + 'societycenter/listsocietycenter.php');
          const data = await responsesocietecentre.json();

          this.setState({ listcenter: data, listcenterinit: data,listcenterpage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ 	idsociete :item.idsociete ,nomsociete:item.nomsociete});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              listcenterinit :data,
              listcenter:slice
          })

        }

  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idcenter: 0,
    namecenter: '',
    latcenter:'',
    longcenter:'',
    textloading:''
  });
  
}

addcenter(){


  if(this.state.namecenter === '' && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter le nom du centre.' });
    return
  
  }

  if(this.state.namecenter === '' && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add center name.' });
    return
  
  }

  if(this.state.latcenter === '' && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la latitude du centre.' });
    return
  
  }

  if(this.state.latcenter === '' && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add center latitude.' });
    return
  
  }

  if(this.state.longcenter === '' && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la longitude du centre.' });
    return
  
  }

  if(this.state.longcenter === '' && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add center longitude.' });
    return
  
  }

  if(parseFloat(this.state.latcenter)>90 || parseFloat(this.state.latcenter)<-90){

    if(this.state.lng === 'FR'){

      this.setState({ errorform: true, errormessage: 'Veuillez saisir une latitude valide du centre.' });
      return
    
    }
  
    if(this.state.lng === 'EN'){
  
      this.setState({ errorform: true, errormessage: 'Please add valid center latitude.' });
      return
    
    }

  }

  if(parseFloat(this.state.longcenter)>180 || parseFloat(this.state.longcenter)<-180){

    if(this.state.lng === 'FR'){

      this.setState({ errorform: true, errormessage: 'Veuillez saisir une longitude valide du centre.' });
      return
    
    }
  
    if(this.state.lng === 'EN'){
  
      this.setState({ errorform: true, errormessage: 'Please add valid center longitude.' });
      return
    
    }

  }

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("idsoccenter",this.state.idsocietycenter);
  formData.append("name",this.state.namecenter);
  formData.append("latitude",this.state.latcenter);
  formData.append("longitude",this.state.longcenter);

  fetch(this.state.wbslink + `center`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === '0'){

      this.setState({ errorform: true, errormessage: 'Error add name.' });

    }else{

      this.setState({ listcenter: data, listcenterinit: data,listcenterpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ 	idsociete :item.idsociete ,nomsociete:item.nomsociete});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listcenterinit :data,
          listcenter:slice
      })

      this.setState({
        idcenter: 0,
        namecenter:'',
        latcenter: '',
        longcenter: '',
        errorform: false,
        errormessage: '',
        modaladd:false,
      })
    }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  


}

showupdatesocietycenter(idsocietycenter,idcenter,name,latitude,longitude){

  this.setState({
    idsoccenter: idsocietycenter,
    namecenter: name,
    latcenter: latitude,
    longcenter: longitude,
    modalupdate: true
  })

}

updatesocietycenter(){

  if(this.state.namecenter.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter le nom du centre.' });
    return
  
  }

  if(this.state.namecenter.length=== 0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add name of site.' });
    return
  
  }

  if(this.state.latcenter.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la latitude du centre.' });
    return
  
  }

  if(this.state.latcenter.length=== 0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add latitude of site.' });
    return
  
  }

  if(this.state.longcenter.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter la longitude du centre.' });
    return
  
  }

  if(this.state.longcenter.length=== 0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add longitude of site.' });
    return
  
  }

  if(parseFloat(this.state.latcenter)>90 || parseFloat(this.state.latcenter)<-90){

    if(this.state.lng === 'FR'){

      this.setState({ errorform: true, errormessage: 'Veuillez saisir une latitude valide du centre.' });
      return
    
    }
  
    if(this.state.lng === 'EN'){
  
      this.setState({ errorform: true, errormessage: 'Please add valid center latitude.' });
      return
    
    }

  }

  if(parseFloat(this.state.longcenter)>180 || parseFloat(this.state.longcenter)<-180){

    if(this.state.lng === 'FR'){

      this.setState({ errorform: true, errormessage: 'Veuillez saisir une longitude valide du centre.' });
      return
    
    }
  
    if(this.state.lng === 'EN'){
  
      this.setState({ errorform: true, errormessage: 'Please add valid center longitude.' });
      return
    
    }

  }

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("name",this.state.namecenter);
  formData.append("latitude",this.state.latcenter);
  formData.append("longitude",this.state.longcenter);

  formData.append('_method', 'PUT');

  fetch(this.state.wbslink + `center/`+this.state.idsoccenter,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length == 0){

      this.setState({ errorform: true, errormessage: 'Error add name.' });

    }else{

      this.setState({ listcenter: data, listcenterinit: data,listcenterpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ 	idsociete :item.idsociete ,nomsociete:item.nomsociete});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          //pageCount: Math.ceil(data.length / this.state.perPage),
          listcenterinit :data,
          listcenter:slice
      })

      this.setState({
        namecenter:'',
        latcenter: '',
        longcenter: '',
        errorform: false,
        errormessage: '',
        modalupdate:false,
      })
    }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  

}

showdeletesocietycenter(idsoccenter,name){
  
  this.setState({
    idsoccenter: idsoccenter,
    name: name,
    modaldelete: true
  })

}

deletesocietycenter(){


  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append('_method', 'delete');

  fetch(this.state.wbslink + `center/`+this.state.idsoccenter,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length == 0){

      this.setState({ errorform: true, errormessage: 'Error add name.' });

    }else{

      this.setState({ listcenter: data, listcenterinit: data,listcenterpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ 	idsociete :item.idsociete ,nomsociete:item.nomsociete});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          //pageCount: Math.ceil(data.length / this.state.perPage),
          listcenterinit :data,
          listcenter:slice
      })

      this.setState({
        idcenter: 0,
        namecenter:'',
        latcenter: '',
        longcenter: '',
        errorform: false,
        errormessage: '',
        modaldelete:false,
      })
    }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  

  return;

  fetch(this.state.wbslink + `societycenter/deletesocietycenter.php`,{                  
        
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        idsocietycenter: this.state.idsoccenter,
        idsoccenterlist:this.state.idsocietycenter
      })

    })

    .then(response => response.json())
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){

      this.setState({ errorform: true, errormessage: 'Error delete file.' });
      return;

    }

    if(JSON.stringify(data).replace(/\"/g, "") !== '0'){

      listdata.length = 0;

      this.setState({ listcenter: data, listcenterinit: data,listcenterpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ 	idsociete :item.idsociete ,nomsociete:item.nomsociete});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listcenterinit :data,
          listcenter:slice
      })

      this.setState({
        idcenter: 0,
        latcenter: '',
        longcenter: '',
        errorform: false,
        errormessage: '',
        modaldelete: false
      })
    
    }else{

      this.setState({
        listcenter: [],
        idcenter: 0,
        latcenter: '',
        longcenter: '',
        errorform: false,
        errormessage: '',
        modaldelete: false
      })

    }

    

    })
    .catch(error => {
      this.setState({ tokenuser: error});
    });  

}
    
  render() {

          return (

              <div>

                {this.state.loadingdata == true?

                  null
                
                  :
                  
                  <div className="App" style={{marginTop:'80px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    {/* 
                        <div className='d-flex justify-content-center'>
                          <label>{this.state.lng ==="FR" ? 'Nom' : 'Name'}</label>
                        </div>

                        <div className='d-flex justify-content-center'>
                          <input style={{width:'400px'}} type="text" className="form-control" value={this.state.namecenter} onChange={(e) => this.setState({ namecenter: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center'>
                          <label>{this.state.lng ==="FR" ? 'Latitude' : 'Latitude'}</label>
                        </div>

                        <div className='d-flex justify-content-center'>
                          <input style={{width:'400px'}} type="text" className="form-control" value={this.state.latcenter} onChange={(e) => this.setState({ latcenter: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center'>
                          <label>{this.state.lng ==="FR" ? 'Longitude' : 'Longitude'}</label>
                        </div>

                        <div className='d-flex justify-content-center'>
                          <input style={{width:'400px'}} type="text" className="form-control" value={this.state.longcenter} onChange={(e) => this.setState({ longcenter: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center'>

                            {this.state.errorform == true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                        </div>

                      */}

                      <div className='d-flex justify-content-center'>
     
                        <button style={{margin:'20px'}} onClick={ () => this.setState({modaladd:true})} className="btn btn-success">{this.state.lng ==="FR" ? 'AJOUTER' : 'ADD'}</button> 
                                                             
                      </div> 
                  
                      {this.state.modaladd === true ?

                        <Modal

                        show={this.state.modaladd}
                        onHide={ () => this.setState({modaladd:false})}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                        >
                          <Modal.Body>
                            <div className="d-flex flex-column justify-content-center">

                            <div className='d-flex justify-content-center' style={{textAlign:"center"}}>
                                <label>{this.state.lng ==="FR" ? 'Nom' : 'Name'}</label>
                              </div>

                              <div className='d-flex justify-content-center'>
                                <input style={{width:'300px',textAlign:"center"}} type="text" className="form-control" value={this.state.namecenter} onChange={(e) => this.setState({ namecenter: e.target.value})} />                    
                              </div>

                              <div className='d-flex justify-content-center' style={{textAlign:"center", marginTop:"40px"}}>
                                <label>{this.state.lng ==="FR" ? 'Latitude' : 'Latitude'}</label>
                              </div>

                              <div className='d-flex justify-content-center'>
                                <input style={{width:'300px',textAlign:"center"}} type="text" className="form-control" value={this.state.latcenter} onChange={(e) => this.setState({ latcenter: e.target.value})} />                    
                              </div>

                              <div className='d-flex justify-content-center' style={{textAlign:"center", marginTop:"40px"}}>
                                <label>{this.state.lng ==="FR" ? 'Longitude' : 'Longitude'}</label>
                              </div>

                              <div className='d-flex justify-content-center'>
                                <input style={{width:'300px',textAlign:"center"}} type="text" className="form-control" value={this.state.longcenter} onChange={(e) => this.setState({ longcenter: e.target.value})} />                    
                              </div>

                              <div className='d-flex justify-content-center'>

                                  {this.state.errorform == true ? 

                                  <Alert severity="error">
                                    <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                                    {this.state.errormessage}
                                  </Alert>

                                  :

                                  null

                                  }

                              </div>
                                      
                                <div className='d-flex flex-row justify-content-center' style={{textAlign:"center", marginTop:"40px"}}>
                                            
                                      <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modaladd:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.addcenter()} variant="success">{this.state.lng ==="FR" ? 'AJOUTER' : 'ADD'}</Button>

                                </div>

                            </div>          
                          
                        </Modal.Body>
                        </Modal>

                        :

                        null

                        }
                  
                  {this.state.modalupdate === true ?

                  <Modal
                  
                  show={this.state.modalupdate}
                  onHide={ () => this.setState({modalupdate:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                      <div className="d-flex flex-column justify-content-center">

                      <div className='d-flex justify-content-center' style={{textAlign:"center"}}>
                          <label>{this.state.lng ==="FR" ? 'Nom' : 'Name'}</label>
                        </div>

                        <div className='d-flex justify-content-center'>
                          <input style={{width:'300px',textAlign:"center"}} type="text" className="form-control" value={this.state.namecenter} onChange={(e) => this.setState({ namecenter: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center' style={{textAlign:"center", marginTop:"40px"}}>
                          <label>{this.state.lng ==="FR" ? 'Latitude' : 'Latitude'}</label>
                        </div>

                        <div className='d-flex justify-content-center'>
                          <input style={{width:'300px',textAlign:"center"}} type="text" className="form-control" value={this.state.latcenter} onChange={(e) => this.setState({ latcenter: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center' style={{textAlign:"center", marginTop:"40px"}}>
                          <label>{this.state.lng ==="FR" ? 'Longitude' : 'Longitude'}</label>
                        </div>

                        <div className='d-flex justify-content-center'>
                          <input style={{width:'300px',textAlign:"center"}} type="text" className="form-control" value={this.state.longcenter} onChange={(e) => this.setState({ longcenter: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center'>

                            {this.state.errorform == true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                        </div>
                                
                          <div className='d-flex flex-row justify-content-center' style={{textAlign:"center", marginTop:"40px"}}>
                                      
                                <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatesocietycenter()} variant="success">{this.state.lng ==="FR" ? 'Modifier' : 'Update'}</Button>

                          </div>

                      </div>          
                    
                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Voulez-vous supprimer le site' : 'Would you like to delete site'}</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.name} ? &nbsp;</p>
                               
                                <form>

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deletesocietycenter()} variant="danger">{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  <div className='centrervue' >

                  <input className="inputfind" style={{marginTop: isBrowser?"0px":"20px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>

                  <BrowserView>
               
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>

                          <TableCell >{this.state.lng ==="FR" ? 'Entreprise' : 'Company'}</TableCell>
                          <TableCell >{this.state.lng ==="FR" ? 'Nom' : 'Name'}</TableCell>
                          <TableCell style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Latitude' : 'Latitude'}</TableCell>
                          <TableCell style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Longitude' : 'Longitude'}</TableCell>
                          <TableCell style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</TableCell>
                          <TableCell style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listcenter.map((row) => (
                          <TableRow key={row.idsocietycenter}>

                            <TableCell >{row.nomsociete}</TableCell>
                            <TableCell >{row.name}</TableCell>
                            <TableCell style={{textAlign:"center"}}>{row.	latitude}</TableCell>
                            <TableCell style={{textAlign:"center"}}>{row.longitude}</TableCell>
                            <TableCell style={{textAlign:"center"}}><button type="submit" className="btn btn-primary btn-circle btn-xl" onClick={() => this.showupdatesocietycenter(row.idsocietycenter,row.idcenter,row.name,row.latitude,row.longitude)}><span><EditIcon /></span></button></TableCell>
                            <TableCell style={{textAlign:"center"}}><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeletesocietycenter(row.idsocietycenter,row.name)}><span><DeleteIcon /></span></button></TableCell>


                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {this.state.listcenter.length>0?

                  <ReactPaginate
                  previousLabel={"<< prev"}
                  previousClassName={"prevClass"}
                  previousLinkClassName={"prevClass"}
                  nextLabel={"next >>"}
                  nextClassName={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>

                  :

                  null
                  }

                  </BrowserView>

                  <MobileView>

                  <div className='paginationmobile' style={{marginTop:"-5%"}}>

                    {this.state.listcenter.length>0?

                      <ReactPaginate
                      previousLabel={"<<"}
                      previousClassName={"prevClass"}
                      previousLinkClassName={"prevClass"}
                      nextLabel={">>"}
                      nextClassName={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>

                      :

                      null
                      
                    }

                    </div>

                    <div  style={{marginTop:"5%"}}>
                      <p style={{color:"transparent"}}>.</p>
                    </div>

                    <div style={{marginTop:"40px"}}>

                      {this.state.listcenter.map((row) => (

                      <Card style={{ width: '90%', margin:"5%"}}>

                      <Card.Body  key={row.idsocietycenter} style={{backgroundColor: "#f5fffa"}}>
        
                        <Card.Text>

                            <p style={{color:"black", marginTop:"10px"}}>{row.nomsociete}</p>
                            <p style={{color:"black", marginTop:"10px"}}>{row.name}</p>
                            <p style={{color:"black", marginTop:"10px"}}>Latitude : {row.latitude}</p>
                            <p style={{color:"black", marginTop:"10px"}}>Longitude : {row.longitude}</p>
                      
                        </Card.Text>
                          <div className='d-flex flex-row align-items-center justify-content-center'>
                                          
                            <button type="submit" className="btn btn-primary btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showupdatesocietycenter(row.idsocietycenter,row.idcenter,row.name,row.latitude,row.longitude)}><span><EditIcon /></span></button>
                            <button type="submit" className="btn btn-danger btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showdeletesocietycenter(row.idsocietycenter,row.name)}><span><DeleteIcon/></span></button>

                          </div>
                      </Card.Body>
                      </Card>

                      ))}

                    </div>

                  </MobileView>

                  </div> 
               
                </div>

                }

              </div>
        
          );
        }
    
}

export default Society;


import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactPaginate from 'react-paginate';
import Card from 'react-bootstrap/Card'
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var listdata=[];

var errocode = 0;

class Entreprise extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        listentreprise: [],
        modalupdate: false,
        modaldelete: false,
        modaladd:false,
        identreprise:0,
        nameentreprise:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        listentrepriseinit: [],
        filterdata:'',
        offset: 0,
        listentreprisepage: [],
        perPage: 5,
        currentPage: 0,
        
      };

      this.handlePageClick = this.handlePageClick.bind(this);

  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listentreprisepage)
    });

};

  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listentreprise:slice
  })

  window.scrollTo(0, 0)

  }

  filterlist(e){

  
    this.setState({filterdata: e})
  
    if(e.lenght == 0){
      this.setState({listentreprise: this.state.listentrepriseinit})
      return;
    }
  
    const filteredItems = this.state.listentrepriseinit.filter((entrepriselist) => {
      return (entrepriselist.nomsociete).toLowerCase().includes(e.toLowerCase());
    });
  
    
    if(filteredItems.length>0){
  
      this.setState({listentreprise: filteredItems})
  
      var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            this.setState({
                pageCount: Math.ceil(filteredItems.length / this.state.perPage),
                listentreprise:slice,
                listentreprisepage:filteredItems,
            })
  
    }else{
  
      this.setState({listentreprise: [], listentreprisepage:[]})
  
    }
   
    
  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    const response = await fetch(this.state.wbslink + 'society',{                       
      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") !== '0'){

      this.setState({ listentreprise: data, listentrepriseinit: data,listentreprisepage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ 	idsociete :item.idsociete ,nomsociete:item.nomsociete});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              listentrepriseinit :data,
              listentreprise:slice
          })
    
    }

    document.title = "Entreprise";
    
  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    identreprise: 0,
    nameentreprise: '',
    textloading:''
  });
  
}

showaddentreprise(){

  this.setState({
    identreprise: 0,
    nameentreprise: "",
    modaladd: true
  })

}

addentreprise(){


  if(this.state.nameentreprise === '' && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter un nom.' });
    return
  
  }

  if(this.state.nameentreprise === '' && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add name.' });
    return
  
  }

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("entreprisename",this.state.nameentreprise);

  fetch(this.state.wbslink + `society`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === '0'){

      this.setState({ errorform: true, errormessage: 'Error add name.' });

    }else{

      listdata.length= 0;

      this.setState({ listentreprise: data, listentrepriseinit: data,listentreprisepage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ 	idsociete :item.	idsociete ,nomsociete:item.nomsociete});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              listentrepriseinit :data,
              listentreprise:slice
          })

      this.setState({
        identreprise: 0,
        nameentreprise: '',
        errorform: false,
        errormessage: '',
        modaladd:false,
      })
    }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  


}

showupdateentreprise(identreprise,textupadte){

  this.setState({
    identreprise: identreprise,
    nameentreprise: textupadte,
    modalupdate: true
  })

}

updateentreprise(){

  if(this.state.nameentreprise.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter un nom.' });
    return
  
  }

  if(this.state.nameentreprise.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add name.' });
    return
  
  }

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("entreprisename",this.state.nameentreprise);

  formData.append('_method', 'PUT');

  fetch(this.state.wbslink + `society/`+this.state.identreprise,{                  
        
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === '0'){

      this.setState({ errorform: true, errormessage: 'Error add word.' });

    
    }else{

      listdata.length= 0;

      this.setState({ listentreprise: data, listentrepriseinit: data,listentreprisepage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ 	idsociete :item.	idsociete ,nomsociete:item.nomsociete});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              //pageCount: Math.ceil(data.length / this.state.perPage),
              listentrepriseinit :data,
              listentreprise:slice
          })

      this.setState({
        nameentreprise: '',
        errorform: false,
        errormessage: '',
        modalupdate: false,
      })
    }
      
    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });    


}

showdeleteentreprise(identreprise,text){
  
  this.setState({
    identreprise: identreprise,
    nameentreprise: text,
    modaldelete: true
  })

}

deleteentreprise(){

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append('_method', 'delete');

  fetch(this.state.wbslink + `society/`+this.state.identreprise,{                  
        
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'Used'){

      if(this.state.lng === 'FR'){

        this.setState({ errorform: true, errormessage: 'Vous ne pouvez pas le supprimer. Il est relié à un site.' });
        return
      
      }
    
      if(this.state.lng === 'EN'){
    
        this.setState({ errorform: true, errormessage: 'You can\'t  delete that. It\'s link to a site.' });
        return
      
      }

    }

    if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){

      if(this.state.lng === 'FR'){

        this.setState({ errorform: true, errormessage: 'Erreur de suppression' });
        return
      
      }
    
      if(this.state.lng === 'EN'){
    
        this.setState({ errorform: true, errormessage: 'Error for deleting.' });
        return
      
      }

    }

    if(JSON.stringify(data).replace(/\"/g, "") !== '0'){

      listdata.length= 0;

      this.setState({ listentreprise: data, listentrepriseinit: data,listentreprisepage:data,loadershow: false})

          data.forEach((item)=>{
            listdata.push({ 	idsociete :item.	idsociete ,nomsociete:item.nomsociete});
          })
				
          var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              //pageCount: Math.ceil(data.length / this.state.perPage),
              listentrepriseinit :data,
              listentreprise:slice
          })

      this.setState({
        identreprise: 0,
        nameentreprise: '',
        errorform: false,
        errormessage: '',
        modaldelete: false
      })
    
    }else{

      this.setState({
        listentreprise: [],
        identreprise: 0,
        nameentreprise: '',
        errorform: false,
        errormessage: '',
        modaldelete: false
      })

    }

    

    })
    
    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });    

}
    
  render() {

          return (
              
                <div className="App" style={{marginTop:'120px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    {/* 
                        <div className='d-flex justify-content-center'>
                          <label>{this.state.lng ==="FR" ? 'Nom' : 'Name'}</label>
                        </div>

                        <div className='d-flex justify-content-center'>
                          <input style={{width:'400px'}} type="text" className="form-control" value={this.state.nameentreprise} onChange={(e) => this.setState({ nameentreprise: e.target.value})} />                    
                        </div>

                        <div className='d-flex justify-content-center'>

                            {this.state.errorform == true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                        </div>
                      
                        */}

                      <div className='d-flex justify-content-center'>
     
                        <button style={{margin:'20px'}}onClick={() => this.showaddentreprise()} className="btn btn-success">{this.state.lng ==="FR" ? 'AJOUTER' : 'ADD'}</button> 
                                                             
                      </div> 

                      {this.state.modaladd=== true ?

                      <Modal

                      show={this.state.modaladd}
                      onHide={ () => this.setState({modaladd:false})}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >
                        <Modal.Body>
                          <div className="d-flex flex-column justify-content-center">

                          <div className='d-flex justify-content-center'>
                            <label>{this.state.lng ==="FR" ? 'Nom' : 'Name'}</label>
                          </div>

                          <div className='d-flex justify-content-center'>
                            <input style={{width:'400px'}} type="text" className="form-control" value={this.state.nameentreprise} onChange={(e) => this.setState({ nameentreprise: e.target.value})} />                    
                          </div>

                          {this.state.errorform == true ? 

                          <Alert severity="error">
                            <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                            {this.state.errormessage}
                          </Alert>

                          :

                          null

                          }
                                    
                              <div className='d-flex flex-row justify-content-center'>
                                          
                                    <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modaladd:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                    <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.addentreprise()} variant="success">{this.state.lng ==="FR" ? 'AJOUTER' : 'ADD'}</Button>

                              </div>

                          </div>          
                        
                      </Modal.Body>
                      </Modal>

                      :

                      null

                      }

                  {this.state.modalupdate === true ?

                  <Modal
                  
                  show={this.state.modalupdate}
                  onHide={ () => this.setState({modalupdate:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                      <div className="d-flex flex-column justify-content-center">

                          <label style={{marginLeft:'5%'}}>Name :</label>
                          <input style={{width:'90%', margin:'5%'}} type="text" className="form-control" value={this.state.nameentreprise} onChange={(e) => this.setState({ nameentreprise: e.target.value})} />

                          {this.state.errorform == true ? 

                            <Alert severity="error">
                              <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                              {this.state.errormessage}
                            </Alert>

                            :

                            null

                            }

                          <div className='d-flex flex-row justify-content-center'>
                                      
                                <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updateentreprise()} variant="success">{this.state.lng ==="FR" ? 'Modifier' : 'Update'}</Button>

                          </div>

                      </div>          
                    
                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">

                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Voulez-vous supprimer l\'entreprise' : 'Would you like to delete entreprise'}</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.nameentreprise}? &nbsp;</p>
                               
                                <form>

                                {this.state.errorform == true ? 

                                <Alert severity="error">
                                  <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                                  {this.state.errormessage}
                                </Alert>

                                :

                                null

                                }

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deleteentreprise()} variant="danger">{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  <div className='centrervue' >

                  <input className="inputfind" style={{marginTop: isBrowser?"0px":"20px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>

                  <BrowserView>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          
                          <TableCell >{this.state.lng ==="FR" ? 'Nom' : 'Name'}</TableCell>
                          <TableCell >{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</TableCell>
                          <TableCell >{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listentreprise.map((row) => (
                          <TableRow key={row.idsociete}>

                
                            <TableCell >{row.nomsociete}</TableCell>
                            <TableCell ><button type="submit" className="btn btn-primary btn-circle btn-xl" onClick={() => this.showupdateentreprise(row.idsociete,row.nomsociete)}><span><EditIcon /></span></button></TableCell>
                            <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeleteentreprise(row.idsociete,row.nomsociete)}><span><DeleteIcon /></span></button></TableCell>


                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {this.state.listentreprise.length>0?

                  <ReactPaginate
                  previousLabel={"<< prev"}
                  previousClassName={"prevClass"}
                  previousLinkClassName={"prevClass"}
                  nextLabel={"next >>"}
                  nextClassName={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>

                  :

                  null
                  }

                  </BrowserView>

                  <MobileView>

                  <div className='paginationmobile' style={{marginTop:"-5%"}}>

                    {this.state.listentreprise.length>0?

                      <ReactPaginate
                      previousLabel={"<<"}
                      previousClassName={"prevClass"}
                      previousLinkClassName={"prevClass"}
                      nextLabel={">>"}
                      nextClassName={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>

                      :

                      null
                      
                    }

                    </div>

                    <div  style={{marginTop:"5%"}}>
                      <p style={{color:"transparent"}}>.</p>
                    </div>

                    <div style={{marginTop:"40px"}}>

                      {this.state.listentreprise.map((row) => (

                      <Card style={{ width: '90%', margin:"5%"}}>

                      <Card.Body  key={row.idsociete} style={{backgroundColor: "#f5fffa"}}>
        
                        <Card.Text>

                            <p style={{color:"black", marginTop:"10px"}}>{row.nomsociete}</p>
                      
                        </Card.Text>
                          <div className='d-flex flex-row align-items-center justify-content-center'>
                                          
                            <button type="submit" className="btn btn-primary btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showupdateentreprise(row.idsociete,row.nomsociete)}><span><EditIcon /></span></button>
                            <button type="submit" className="btn btn-danger btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showdeleteentreprise(row.idsociete,row.nomsociete)}><span><DeleteIcon/></span></button>

                          </div>
                      </Card.Body>
                      </Card>

                      ))}

                    </div>

                  </MobileView>

                  </div> 
               
                </div>
                
          );
        }
    
}

export default Entreprise;

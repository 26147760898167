
import React,  {Alert,Component} from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { isExpired, decodeToken }from "react-jwt";
import { Button,Form,Modal} from 'react-bootstrap';
import Loader from "react-js-loader";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

class Confidentiality extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      lng:'',
      serviceprovision:true,
      acceptexperience:false,
      textacceptexperience:"",
      acceptmarketing:false,
      textacceptmarketing:"",
      acceptshare:false,
      textacceptshare:"",
      acceptupload:true,
      loadingdata:true,
      };

        
  }
    
    componentDidMount() {
        
    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let token = sessionStorage.getItem('tokenunsaid');

    if(token === '' || token === 'Undefined' || token === null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        lng:'',
      })
      return;
    }

    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    if(lnguser == "FR"){

        document.title = "Politique de confidentialité";

    }else{

      document.title = "Privacy policy";

    }

    this.setState({
      loadingdata:false
    })

    window.scrollTo(0,0)
        
  };

  render() {

          return (
              
                <div style={{margin:"2%",marginTop:"80px"}} >

                {this.state.loadingdata === true ?
                      
                  <div className='centercontent'>

                    <Loader type="spinner-cub" bgColor={'#2A83E8'} color={'#2A83E8'} size={100} />

                  </div>
                  

                :

                  <div className="rgpdborder">

                    <p style={{color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "1. Introduction":"1. Introduction"}</p>
                    <p style={{color:"black",textAlign:"left"}}>{this.state.lng=== "FR"? "Cette Politique de Confidentialité décrit comment Unsaid, une société enregistrée sous le numéro SIRET 892 467 614 00012, dont le siège social est situé au 229 Rue Saint-Honoré, 75001 Paris, collecte, utilise et protège les informations personnelles que vous fournissez lors de l'utilisation de notre site web unsaidapps.com et de nos services SaaS (U-Note et U-Feel). En utilisant notre site et nos services, vous consentez à la collecte et à l'utilisation de vos données conformément à cette Politique de Confidentialité.":"This Privacy Policy describes how Unsaid, a company registered under SIRET number 892 467 614 00012, with its headquarters located at 229 Rue Saint-Honoré, 75001 Paris, collects, uses, and protects the personal information you provide when using our website unsaidapps.com and our SaaS services (U-Note and U-Feel). By using our website and services, you consent to the collection and use of your data in accordance with this Privacy Policy."}</p>
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "2. Données Collectées":"2. Data Collected"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid collecte des informations personnelles lorsque vous visitez notre site, utilisez nos services ou interagissez avec nous. Ces données incluent :":"Unsaid collects personal information when you visit our site, use our services, or interact with us. This data includes:"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Données d'identification : Nom, prénom, adresse email, numéro de téléphone, informations sur l'entreprise.":"• Identification Data: Name, surname, email address, phone number, company information."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Données d'utilisation : Historique d'interactions avec le site, adresses IP, identifiants de session.":"• Usage Data: Interaction history with the site, IP addresses, session identifiers."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Données des services SaaS : Enregistrements audio ou vidéo pour U-Note et U-Feel, ainsi que les résultats d'analyse et les rapports générés.":"• SaaS Service Data: Audio or video recordings for U-Note and U-Feel, as well as analysis results and report generation."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Données de paiement : Détails de facturation via Paypal.":"• Payment Data: Invoice details from Paypal."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}> {this.state.lng=== "FR"? "Ces données sont collectées via des formulaires d'inscription, des interactions avec le site ou les services SaaS, ou via des cookies (voir Section 7).":"  This data is collected through registration forms, interactions with the site or SaaS services, or via cookies (see Section 7)."}</p>
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "3. Finalité de la Collecte des Données":"3. Purpose of Data Collection"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Les données collectées servent aux finalités suivantes:":"The data we collect serves the following purposes:"}</p>          
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Fournir et gérer les services SaaS U-Note et U-Feel.":"• To provide and manage the SaaS services U-Note and U-Feel."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Personnaliser et améliorer l'expérience utilisateur.":"• To personalize and improve user experience."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Générer des rapports personnalisés à partir des enregistrements des services U-Note et U-Feel.":"• To generate customized reports from recordings for U-Note and U-Feel services."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Réaliser des analyses d'efficacité et de satisfaction client à l'aide de l'intelligence artificielle.":"• To conduct effectiveness and customer satisfaction analysis using artificial intelligence."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Gérer la facturation et les transactions financières.":"• To manage billing and financial transactions."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Communiquer avec vous sur les mises à jour, services et offres commerciales.":"• To communicate with you about updates, services, and commercial offers."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Se conformer aux obligations légales et réglementaires.":"• To comply with legal and regulatory obligations."}</p>
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "4. Partage et Traitement des Données":"4. Data Sharing and Processing"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid utilise des technologies d'intelligence artificielle internes et tierces pour traiter les données collectées, notamment pour analyser les enregistrements et générer des rapports via U-Note et U-Feel.":"Unsaid uses both internal and third-party artificial intelligence technologies to process collected data, including analyzing recordings and generating reports via U-Note and U-Feel."}</p>          
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Vos données peuvent être partagées avec:":"Your data may be shared with:"}</p>          
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Partenaires IA tiers : Pour le traitement et l'analyse des enregistrements (traitement externalisé avec garanties de confidentialité).":"• AI Third-Party Partners: For processing and analyzing recordings (outsourced processing with confidentiality guarantees)."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Fournisseurs de services techniques : Pour l'hébergement et la maintenance des services (ex. : OVH pour le site, Ligne Web Services pour les solutions SaaS).":"• Technical Service Providers: For hosting and maintaining services (e.g., OVH for the site and Ligne Web Services for SaaS solutions)."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Autorités légales : En cas d'obligations légales ou pour protéger nos droits.":"• Legal Authorities: In case of legal obligations or to protect our rights."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}> {this.state.lng=== "FR"? "Nous veillons à ce que tout partenaire tiers respecte les exigences légales et réglementaires relatives à la protection des données personnelles.":" We ensure that any third-party partner complies with legal and regulatory requirements regarding personal data protection."}</p>

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "5. Protection des Données":"5. Data Protection"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Unsaid met en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos données contre tout accès, divulgation, altération ou destruction non autorisés. Les données traitées par nos outils d'intelligence artificielle, qu'ils soient internes ou externes, sont soumises à des protocoles de sécurité stricts, incluant des techniques de chiffrement lors du stockage et du transfert.":"Unsaid implements appropriate technical and organizational security measures to protect your data against unauthorized access, disclosure, alteration, or destruction. Data processed by our artificial intelligence tools, whether internal or external, is subject to strict security protocols, including encryption techniques during storage and transfer."}</p>          
                 
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "6. Conservation des Données":"6. Data Retention"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Vos données personnelles sont conservées pendant la durée nécessaire pour atteindre les finalités pour lesquelles elles ont été collectées. Par exemple :":"Your personal data is retained for the time necessary to fulfill the purposes for which it was collected. For example:"}</p>             
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Les données d'utilisation des services SaaS sont conservées pendant la durée de l'abonnement et jusqu'à 3 ans après la résiliation.":"• Usage data for SaaS services is retained for the duration of the subscription and for 3 years after termination."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Les données de facturation sont conservées conformément aux obligations légales (généralement 10 ans).":"• Billing data is retained in accordance with legal obligations (generally, 10 years)."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Les enregistrements utilisés pour U-Note et U-Feel sont conservés aussi longtemps que nécessaire pour générer des rapports, puis supprimés ou anonymisés conformément à notre politique interne.":"• Recordings used for U-Note and U-Feel are retained as long as necessary to generate reports and then deleted or anonymized according to our internal policy."}</p>
                    
                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "7. Utilisation des Cookies":"7. Use of Cookies"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Notre site utilise des cookies pour améliorer votre expérience utilisateur, analyser l'utilisation du site et offrir des services adaptés. Les cookies sont de petits fichiers texte stockés sur votre appareil. Vous pouvez configurer votre navigateur pour refuser ou limiter l'utilisation des cookies. Les types de cookies utilisés incluent :":"Our website uses cookies to enhance your user experience, analyze site usage, and offer tailored services. Cookies are small text files stored on your device. You can configure your browser to refuse or limit the use of cookies. Types of cookies used include:"}</p>             
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Fonctionnels : Nécessaires au bon fonctionnement du site.":"• Functional: Necessary for the proper functioning of the site."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Analytiques : Pour collecter des statistiques sur l'utilisation du site.":"• Analytical: To collect statistics on site usage."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Tiers : Pour faciliter l'intégration de services externes (ex. : outils d'analyse).":"• Third-Party: To facilitate the integration of external services (e.g., analytics tools)."}</p>

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "8. Droits des Utilisateurs":"8. User Rights"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Conformément à la réglementation applicable, notamment le Règlement Général sur la Protection des Données (RGPD), vous disposez des droits suivants concernant vos données personnelles :":"In accordance with applicable regulations, including the General Data Protection Regulation (GDPR), you have the following rights regarding your personal data:"}</p>             
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Droit d'accès : Vous pouvez demander à consulter les données que nous détenons sur vous.":"• Right of Access: You can request to view the data we hold about you."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Droit de rectification : Vous pouvez demander la correction de vos données si elles sont inexactes ou incomplètes.":"• Right to Rectification: You can request correction of your data if it is inaccurate or incomplete."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Droit à l'effacement : Vous pouvez demander la suppression de vos données sous certaines conditions.":"• Right to Erasure: You can request deletion of your data under certain conditions."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Droit à la limitation du traitement : Vous pouvez demander la limitation du traitement de vos données dans certaines circonstances.":"• Right to Restrict Processing: You can request restriction of the processing of your data in certain circumstances."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Droit à la portabilité des données : Vous pouvez demander à recevoir une copie de vos données dans un format structuré et couramment utilisé.":"• Right to Data Portability: You can request to receive a copy of your data in a structured and commonly used format."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Droit d'opposition : Vous pouvez vous opposer à l'utilisation de vos données à des fins de marketing direct ou à certains traitements fondés sur nos intérêts légitimes.":"• Right to Object: You can object to the use of your data for direct marketing purposes or certain processing based on our legitimate interests."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Pour exercer vos droits, vous pouvez nous contacter à: ":"• To exercise your rights, you can contact us at: "}<a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:contact@unsaid.fr"}>contact@unsaid.fr</a></p>

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "9. Transfert de Données Hors de l'Union Européenne":"9. Data Transfer Outside the European Union"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Dans le cadre de nos services SaaS et de nos partenariats avec des tiers, vos données peuvent être transférées hors de l'Union Européenne. Dans ces cas, Unsaid s'engage à prendre les mesures nécessaires pour garantir que vos données bénéficient du même niveau de protection exigé par la législation européenne.":"As part of our SaaS services and partnerships with third parties, your data may be transferred outside the European Union. In such cases, Unsaid is committed to taking necessary measures to ensure that your data receives the same level of protection required by European legislation."}</p>             

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "10. Modifications de la Politique de Confidentialité":"10. Changes to the Privacy Policy"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment. Toute modification sera publiée sur cette page, et nous vous informerons des changements significatifs par email ou via un avis sur notre site.":"We reserve the right to modify this Privacy Policy at any time. Any changes will be posted on this page, and we will inform you of significant changes by email or via a notice on our site."}</p>             

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "11. Contact":"11. Contact"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Pour toute question concernant cette Politique de Confidentialité ou pour exercer vos droits, veuillez nous contacter à: ":"For any questions regarding this Privacy Policy or to exercise your rights, please contact us at: "}<a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:contact@unsaid.fr"}>contact@unsaid.fr</a></p>             

                    <p style={{marginTop:"20px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "12. Loi Applicable et Juridiction":"12. Governing Law and Jurisdiction"}</p>
                    <p style={{color:"black",textAlign:"left",marginTop:"-10px", marginBottom:"80px"}}>{this.state.lng=== "FR"? "Cette Politique de Confidentialité est régie par le droit français. Tout litige relatif à son application sera soumis à la compétence exclusive des tribunaux de Paris.":"This Privacy Policy is governed by French law. Any disputes related to its application will be subject to the exclusive jurisdiction of the Paris courts."}</p>             
                  
                    
                  </div>

                }
                    
     
                </div>
          );
        }
    
}

export default Confidentiality;
